import { setCookie } from 'nookies';

import {
	CLARITY_PLAYER_URL,
	CLARITY_PROJECT_ID_1,
	CLARITY_PROJECT_ID_2,
	COOKIE,
} from 'Constants/constants';

import { getCookie } from './cookieUtils';
import { isServer } from './envUtils';

export const getClarityUrl = () => {
	if (isServer()) {
		return null;
	}
	const userIdCookie = getCookie('_clck');
	const userId = userIdCookie?.split('|')[0];
	const sessionIdCookie = getCookie('_clsk');
	const sessionId = sessionIdCookie?.split('|')[0];
	const clarityProjectId = getCookie(COOKIE.CLARITY_PROJECT_ID);

	return userIdCookie === null ||
		sessionIdCookie === null ||
		clarityProjectId === null
		? null
		: `${CLARITY_PLAYER_URL}${clarityProjectId}/${userId}/${sessionId}`;
};

export const initClarityProjectId = (res?: any) => {
	const clarityProjectId =
		Math.floor(Math.random() * 10) < 5
			? CLARITY_PROJECT_ID_1
			: CLARITY_PROJECT_ID_2;
	setCookie({ res }, COOKIE.CLARITY_PROJECT_ID, clarityProjectId);
	return clarityProjectId;
};
