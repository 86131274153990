const PortalLoaderFilled = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='100'
			height='117'
			viewBox='0 0 100 117'
			fill='none'
			{...props}
		>
			<path
				d='M48.933 117C68.1552 117 81.1757 107.065 87.0396 100.102C88.5255 98.3376 90.6411 97.1331 92.9479 97.1331C96.6636 97.1331 99.6758 94.1209 99.6758 90.4052L99.6758 28.2525C99.6758 24.2811 96.4289 21.0615 92.4574 21.0615C90.396 21.0615 88.4904 20.1617 87.1157 18.6255C81.727 12.6036 68.8884 -1.03209e-05 48.933 -1.20655e-05C32.3274 -1.35172e-05 18.7766 11.0391 12.3773 18.4351C10.9937 20.0342 9.03058 21.0615 6.91594 21.0615C3.27521 21.0615 0.323807 24.0129 0.323807 27.6537L0.323801 90.8766C0.323801 94.332 3.12493 97.1331 6.58031 97.1331C8.86368 97.1331 10.9337 98.3991 12.3218 100.212C17.6486 107.169 29.8119 117 48.933 117Z'
				fill='#F5ECFF'
			/>
		</svg>
	);
};

export default PortalLoaderFilled;
