import {
	DEFAULT_LANG,
	DOWNLOAD_APP_SECTION_ASSETS_BY_LANG,
} from 'Constants/constants';

interface DownloadAppAssets {
	PLAY_STORE_IMAGE_URL: string;
	APP_STORE_IMAGE_URL: string;
	PLAY_STORE_LINK: string;
	APP_STORE_LINK: string;
	IPHONE_MOCKUP_IMAGE_URL: string;
}

export const getDownloadAppAssets = (
	languageCode: string = DEFAULT_LANG,
): DownloadAppAssets => {
	const langAssets =
		DOWNLOAD_APP_SECTION_ASSETS_BY_LANG[
			languageCode as keyof typeof DOWNLOAD_APP_SECTION_ASSETS_BY_LANG
		] || DOWNLOAD_APP_SECTION_ASSETS_BY_LANG[DEFAULT_LANG];

	return {
		...langAssets,
	};
};
