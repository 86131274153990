'use client';

import React from 'react';

import { css, cx } from '@headout/pixie/css';

import { CloseIcon } from 'Assets/svg/checkout/CloseIcon';
import { WarningSvg } from 'Assets/svg/checkout/WarningSvg';
import { InfoIcon } from 'Assets/svg/infoIcon';

import { trackEvent } from 'Utils/analytics';
import { isServer } from 'Utils/envUtils';
import { UNKNOWN } from 'Utils/resolveResponse';

import LSpan from './localizedTags/localizedSpan';

const defaultTimeout = 8000; // ms

let toast: any = null;

const toastNotification = css({
	display: 'flex',
	alignItems: 'center',
	position: 'fixed',
	top: '-50px',
	left: '50%',
	zIndex: 99999,
	pointerEvents: 'none',
	transition: 'all 300ms ease',
	transform: 'translate(-50%, 0)',
	borderRadius: '0.75rem',
	background: 'semantic.surface.light.white',
	padding: '1rem',
	textAlign: 'center',

	'&.confirmation': {
		width: '100%',
		opacity: 0,
		borderRadius: '0',
		padding: '15px 1rem',
		top: '54px',
		transform: 'translate(-50%, 0)',
		transition: 'opacity 350ms linear',
		background: '#34a853',

		'&.show': {
			opacity: 0.9,
			transform: 'translate(-50%, 0)',
		},

		'&.hide': {
			opacity: 0,
			transform: 'translate(-50%, 0)',
		},
	},

	'&.hide': {
		transform: 'translate(-50%, -125px)',
	},

	'&.success': {
		background: '#55ca92',
	},

	'&.error': {
		background: '#ffe6e6',
		'& span': {
			color: '#444444',
		},
	},

	'&.warning': {
		background: '#FFF8EF',

		'& span': {
			color: '#444444',
		},
	},

	'& .warning-icon': {
		marginRight: '0.5rem',
	},

	'& .close-icon': {
		marginLeft: '1.25rem',
		cursor: 'pointer',
		pointerEvents: 'all',
	},

	'& span': {
		whiteSpace: 'pre-wrap',
		color: '#fff',
	},

	'& .header': {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	'& .msg-container': {
		display: 'flex',
	},

	'& .divider': {
		color: 'rgba(214, 4, 4, 0.08)',
		margin: '0.75rem 0',
	},

	'& .info-text': {
		textStyle: 'Semantics/Heading/Small',
		color: 'semantic.text.grey.2',
	},

	'&.info': {
		textStyle: 'Semantics/Subheading/Small',
		background: 'semantic.surface.light.white',
		boxShadow:
			'0px 4px 8px 0px rgba(0, 0, 0, 0.12), 0px -1px 2px 0px rgba(0, 0, 0, 0.08)',

		'& span': {
			color: 'semantic.text.grey.2',
		},
	},

	'& .description': {
		textStyle: 'Semantics/Para/Regular',
		marginTop: '0.5rem',
		marginBottom: '0.75rem',
		textAlign: 'left',
	},

	'@media (max-width: 768px)': {
		width: 'calc(100% - 3rem)',
	},
});

type ToastProps = {
	isDesktop: boolean;
	style?: any | boolean;
};

type ToastState = any;

/* React Notification Component */
/* eslint-disable react/no-multi-comp */
class Toast extends React.Component<ToastProps, ToastState> {
	state = {
		className: '',
		text: '',
		type: '',
		showErrorIcon: false,
		topPosition: null,
	};

	displayToast = (
		text: string,
		type: 'error' | 'warning' | 'info',
		timeout = defaultTimeout,
		showErrorIcon = false,
		topPosition: string | null,
	) => {
		this.setState({ text, type, showErrorIcon, topPosition });
		setTimeout(this.show, 100); // wait 100ms after the component is called to animate toast.
		setTimeout(this.hide, timeout);
	};

	hide = () => {
		this.setState({ className: 'hide' });
	};

	show = () => {
		this.setState({ className: 'show' });
	};

	render() {
		const { isDesktop } = this.props;
		const { text, type, className, showErrorIcon, topPosition } =
			this.state;
		return (
			<div
				id='toast'
				className={cx(
					toastNotification,
					'toast-notification',
					type,
					className,
				)}
				style={
					className === 'show'
						? {
								transform: `translate(-50%, ${
									topPosition ?? '110px'
								})`,
						  }
						: {}
				}
			>
				{type === 'error' && (isDesktop || showErrorIcon) && (
					<WarningSvg className='warning-icon' />
				)}
				{type === 'warning' && <InfoIcon className='warning-icon' />}
				<LSpan>{text}</LSpan>
				{(type === 'error' || type === 'warning') && isDesktop && (
					<CloseIcon className='close-icon' onClick={this.hide} />
				)}
			</div>
		);
	}
}

/* Public functions */

const sendAnalyticsEvent = (err: any) => {
	const { message, code } = err;
	trackEvent({
		eventName: 'Error Viewed',
		'Error Code': code,
		'Error Message': message,
	});
};

/* Show Animated Toast Message */
const show = (
	text: string,
	type: string,
	timeout = defaultTimeout,
	trackProps = {},
	showErrorIcon = false,
	topPosition: string | null = null,
) => {
	if (isServer()) return;
	// Render Component with Props.
	if (toast) {
		if (type === 'error') {
			trackEvent({
				eventName: 'Error Viewed',
				'Error Message': text,
				...trackProps,
			});
		} else if (type === 'warning') {
			trackEvent({
				eventName: 'Warning Viewed',
				'Warn Message': text,
				...trackProps,
			});
		}
		toast.displayToast(text, type, timeout, showErrorIcon, topPosition);
	}
};

const showNetworkError = (err: any) => {
	if (isServer() || !err) return;
	// Send Analytics Events in case of API Failure
	sendAnalyticsEvent(err);
	if (err?.status && (err.localisedMessage || err.message))
		show(err.localisedMessage || err.message, 'error');
	if (!(err.message || err.localisedMessage)) show(UNKNOWN, 'error');
};

const showConfirmation = (msg: any, timeout: any, topPosition?: string) => {
	if (isServer() || !msg) return;
	show(msg, 'confirmation', timeout, {}, false, topPosition);
};

/* Export notification container */
// eslint-disable-next-line react/display-name
export default function ToastFunc({ isDesktop }: { isDesktop: boolean }) {
	return <Toast ref={node => (toast = node) as any} isDesktop={isDesktop} />;
}

/* Export notification functions */
export const notify = {
	show,
	showNetworkError,
	showConfirmation,
};
/* eslint-enable react/no-multi-comp */
