/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import colors from 'Static/typography/colors';
import fonts from 'Static/typography/fonts';
import TYPE_LABELS from 'Static/typography/labels';

export const PageLoaderContainer = styled.div`
	width: 100vw;
	height: 100vh;
	text-align: center;
	position: fixed;
	z-index: 1010000; // select screen bottom bar is 1000
	top: 0;
	left: 0;
	background-color: ${colors.WHITE};
	overflow: hidden;
	::after {
		content: '';
		position: absolute;
		inset: 0;
		z-index: 2;
		background: radial-gradient(
			50% 57% at 50% 50%,
			rgba(255, 255, 255, 0) 0%,
			#fff 100%
		);
		@media (max-width: 768px) {
			::after {
				background: radial-gradient(
					50% 50% at 50% 50%,
					rgba(255, 255, 255, 0) 0%,
					#fff 100%
				);
				pointer-events: none;
			}
		}
	}
`;

export const PageLoaderWrapper = styled.div`
	@keyframes fadeOutLoaderAnimation {
		from {
			opacity: 1;
		}
		to {
			opacity: 0;
		}
	}

	@keyframes loaderRippleAnimationDweb {
		0% {
			transform: translate(-50%, -50%) scale(1);
		}

		100% {
			transform: translate(-50%, -50%) scale(7);
		}
	}

	@keyframes loaderRippleAnimationMweb {
		0% {
			transform: translate(-50%, -50%) scale(1);
		}

		100% {
			transform: translate(-50%, -50%) scale(8);
		}
	}

	@keyframes loaderCenterContentCollapseAnimation {
		0% {
			transform: translate(-50%, -50%);
			opacity: 1;
		}

		100% {
			transform: translate(-50%, -50%) scale(0);
			opacity: 0;
		}
	}

	@keyframes centerSvgCollapseAnimationDweb {
		0% {
			transform: translate(-50%, -50%);
			opacity: 1;
		}
		100% {
			transform: translate(-50%, -50%) scale(7);
			opacity: 0;
		}
	}
	@keyframes centerSvgCollapseAnimationMweb {
		0% {
			transform: translate(-50%, -50%);
			opacity: 1;
		}
		100% {
			transform: translate(-50%, -50%) scale(8);
			opacity: 0;
		}
	}

	width: 100%;
	height: 100%;
	.container {
		position: fixed;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		align-items: stretch;
		background: radial-gradient(
			50% 50% at 50% 50%,
			rgba(255, 255, 255, 0) 24.1%,
			#fff
		);
	}
	.ho-logo {
		opacity: 0;
		.with-love {
			${getFontDetailsByLabel(TYPE_LABELS.HEADING_XS)}
			svg {
				display: inline-block;
				transform: translateY(-2px);
			}
			margin-bottom: 0.9375rem;
		}
		.logo {
			margin-bottom: 1.5625rem;
			svg {
				max-width: 11.5625rem;
				margin: 0 auto;
			}
		}
	}
	.ho-arc {
		margin: 1.25rem auto 1.125rem;
		svg {
			max-width: 100%;
		}
	}
	.booster-text {
		${getFontDetailsByLabel(TYPE_LABELS.HEADING_XS)}
		color: ${colors.BLACK};
		margin-bottom: 0.625rem;
	}

	.ho-brand-closing-animation {
		animation: fadeOutLoaderAnimation 300ms ease-in-out 200ms forwards;
		.center-content {
			animation: loaderCenterContentCollapseAnimation 500ms ease-in
				forwards;
		}
		.center-svg {
			animation: centerSvgCollapseAnimationDweb 500ms ease-in forwards;
		}
		.ripple-wrapper {
			display: none;
		}
	}

	.ho-brand-animation {
		.ripple-container {
			position: relative;
			width: 206px;
			height: 242.59px;
		}

		.center-content {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 10;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 12px;
			padding-bottom: 2px;
			.powered-by {
				color: ${colors.BLACK};
				text-align: center;
				${getFontDetailsByLabel(TYPE_LABELS.HEADING_LARGE)};
				font-family: ${fonts.HALYARD.TEXT};
			}
			.headout-label-svg {
				height: 36.08px;
				width: 255.12px;
			}
			.headout-blimp {
				height: 36.08px;
				width: 60.15px;
				margin-left: -2px;
			}
		}

		.center-svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 3;
			width: 206px;
			height: 242.59px;
		}

		.ripple-wrapper {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			height: 100%;
			animation: loaderRippleAnimationDweb 1.5s linear infinite;
		}

		.ripple-svg {
			width: 100%;
			height: 100%;
		}

		[data-animation='2'] {
			animation-delay: 0s;
		}

		[data-animation='3'] {
			animation-delay: 0.125s;
		}

		[data-animation='4'] {
			animation-delay: 0.25s;
		}

		[data-animation='5'] {
			animation-delay: 0.375s;
		}

		[data-animation='6'] {
			animation-delay: 0.5s;
		}

		[data-animation='7'] {
			animation-delay: 0.625s;
		}

		[data-animation='8'] {
			animation-delay: 0.75s;
		}

		[data-animation='9'] {
			animation-delay: 0.875s;
		}

		[data-animation='10'] {
			animation-delay: 1s;
		}

		[data-animation='11'] {
			animation-delay: 1.125s;
		}

		[data-animation='12'] {
			animation-delay: 1.25s;
		}

		[data-animation='13'] {
			animation-delay: 1.375s;
		}

		[data-animation='14'] {
			animation-delay: 1.5s;
		}

		[data-animation='15'] {
			animation-delay: 1.625s;
		}
	}

	.center {
		margin: 0 auto;
	}

	@media (max-width: 768px) {
		.ho-brand-animation {
			.ripple-container {
				width: 99.35px;
				height: 117px;
			}

			.ripple-wrapper {
				transform: translate(-50%, -50%) scale(1);
			}

			.center-content {
				.powered-by {
					${getFontDetailsByLabel(TYPE_LABELS.SUBHEADING_LARGE)};
				}
				.headout-label-svg {
					width: 170.2px;
					height: 24.07px;
				}
				.headout-blimp {
					width: 36.48px;
					height: 21.88px;
				}
			}

			.center-svg {
				transform: translate(-50%, -50%) scale(1);
				width: 99.35px;
				height: 117px;
			}

			.ripple-wrapper {
				animation: loaderRippleAnimationMweb 1s linear infinite;
			}

			[data-animation='2'] {
				animation-delay: 0s;
			}

			[data-animation='3'] {
				animation-delay: 0.125s;
			}

			[data-animation='4'] {
				animation-delay: 0.25s;
			}

			[data-animation='5'] {
				animation-delay: 0.375s;
			}

			[data-animation='6'] {
				animation-delay: 0.5s;
			}

			[data-animation='7'] {
				animation-delay: 0.625s;
			}

			[data-animation='8'] {
				animation-delay: 0.75s;
			}

			[data-animation='9'] {
				animation-delay: 0.875s;
			}

			[data-animation='10'] {
				animation-delay: 1s;
			}

			[data-animation='11'] {
				display: none;
			}

			[data-animation='12'] {
				display: none;
			}

			[data-animation='13'] {
				display: none;
			}

			[data-animation='14'] {
				display: none;
			}

			[data-animation='15'] {
				display: none;
			}
		}
		.ho-brand-closing-animation {
			.center-svg {
				animation: centerSvgCollapseAnimationMweb 500ms ease-in forwards;
			}
		}
	}
`;
