export const REVIEWS_DATA = [
	{
		bookingID: 10687617,
		customerName: 'Innocent',
		experienceImageUrl:
			'https://cdn-imgix.headout.com/userCollectedReviewMediaFiles/10687617/42746ec93a1a8c10a64561a7ea3a5407-16bdf5620e2a',
		rating: 5,
		content:
			'Everything was on point. I just want to thank you for bringing the wildlife to the convenience of the people in the UAE. The customer service was excellent.',
		tourID: 15798,
		tourName: 'Safari Journey Ticket',
		city: 'DUBAI',
		tourGroupId: 8843,
		language: 'EN',
		tourGroupName: 'Dubai Safari Park Tickets',
		customerCountry: 'UAE',
		customerCountryCode: 'ae',
		directUrl:
			'/dubai-safari-park-tickets/entry-ticket-to-dubai-safari-park-e-8843/',
	},
	{
		bookingID: 10750901,
		customerName: 'Tannu',
		experienceImageUrl:
			'https://cdn-imgix.headout.com/userCollectedReviewMediaFiles/10750901/b15fdae65a69e9decb270298767bcc30-37ebc4f4cab7',
		rating: 4,
		content:
			"It's a very good place for the kids. They loved it a lot. Ticketing process with headout is awesome. Their timing and process are very swift.",
		tourID: 13122,
		tourName: 'Dubai Garden Glow',
		city: 'DUBAI',
		tourGroupId: 7210,
		language: 'EN',
		tourGroupName: 'Dubai Garden Glow Tickets',
		customerCountry: 'UAE',
		customerCountryCode: 'ae',
		directUrl:
			'/dubai-garden-glow-tickets/dubai-garden-glow-tickets-e-7210/',
	},
	{
		bookingID: 11093494,
		customerName: 'John',
		experienceImageUrl:
			'https://cdn-imgix.headout.com/userCollectedReviewMediaFiles/11093494/f321497b276acae7b08a61b8ab8ba2ff-aa5842bbc53d',
		rating: 5,
		content:
			'We met 3 koalas which was amazing. Unforgettable experience! Purchasing the tickets was very quick and easy. No issues at all with tickets or gaining entry to the zoo.',
		tourID: 19178,
		tourName: 'Taronga Zoo Entry Ticket',
		city: 'SYDNEY',
		tourGroupId: 10339,
		language: 'EN',
		tourGroupName: 'Taronga Zoo Entry Ticket',
		customerCountry: 'United Kingdom',
		customerCountryCode: 'gb',
		directUrl: '/taronga-zoo-tickets/taronga-zoo-entry-ticket-e-10339/',
	},
	{
		bookingID: 10762709,
		customerName: 'Baskaran',
		experienceImageUrl:
			'https://cdn-imgix.headout.com/userCollectedReviewMediaFiles/10762709/2ab7272190da1457a1cdc1c4f0956a2d-6c215aecbaf1',
		rating: 4,
		content:
			'I bought the ticket while walking to the attraction and yet it is cheaper than the counter.  Fantastic view of the city skyline from the observation deck. ',
		tourID: 13460,
		tourName: 'Admission Ticket',
		city: 'SINGAPORE',
		tourGroupId: 7371,
		language: 'EN',
		tourGroupName: 'Marina Bay Sands Skypark Observation Deck Tickets',
		customerCountry: 'Singapore',
		customerCountryCode: 'sg',
		directUrl:
			'/marina-bay-sands-skypark-tickets/singapore-marina-bay-sands-skypark-tickets-e-7371/',
	},
	{
		bookingID: 10879059,
		customerName: 'Chirag',
		experienceImageUrl:
			'https://cdn-imgix.headout.com/userCollectedReviewMediaFiles/10879059/5529ab70c2eddfe4bef2baed8470c116-1544ba633630',
		rating: 5,
		content:
			'Best experience ever. It was a surpirse for my partner and she just loved it. Thank you team',
		tourID: 30795,
		tourName: 'Sky High Bento - vegetarian',
		city: 'SINGAPORE',
		tourGroupId: 7404,
		language: 'EN',
		tourGroupName: 'Singapore Cable Car Dining Experience',
		customerCountry: 'Australia',
		customerCountryCode: 'au',
		directUrl:
			'/cable-car-tours/singapore-cable-car-dining-experience-e-7404/',
	},

	{
		bookingID: 10994491,
		customerName: 'Mushtaryzah',
		experienceImageUrl:
			'https://cdn-imgix.headout.com/userCollectedReviewMediaFiles/10994491/f5ce8fc5848b64592548c578c9e3a759-4e0ab5f5f9bb',
		rating: 5,
		content:
			'Awesome awesomeness awesomely! The experience was wonderful & enjoyed with our in family group, Recommended other to buy tickets through Headout 😍',
		tourID: 30707,
		tourName: 'Standard Gondola (Two way)',
		city: 'KUALA_LUMPUR',
		tourGroupId: 15982,
		language: 'EN',
		tourGroupName: 'Genting Awana SkyWay Tickets',
		customerCountry: 'Malaysia',
		customerCountryCode: 'my',
		directUrl:
			'/genting-highlands-tickets/genting-awana-skyway-tickets-e-15982/',
	},

	{
		bookingID: 10875070,
		customerProfile:
			'https://cdn-imgix-open.headout.com/home/customer-profile-images/kashan.jpeg',
		customerName: 'Kashan',
		experienceImageUrl:
			'https://cdn-imgix.headout.com/userCollectedReviewMediaFiles/10875070/1da0ff3966524ccd7c099540e205d798-a99e0372b73d',
		rating: 5,
		content:
			'The ticketing experience through Headout was very good as we got a significant discount on the tickets. Customer support is extremely well managed.❣️ The FantaSea show was awesome!👌 We would highly recommend it to whoever is visiting Phuket, Thailand.👏',
		tourID: 34128,
		tourName: 'Normal Seat + Round Trip Shared Transfer',
		city: 'PHUKET',
		tourGroupId: 17883,
		language: 'EN',
		tourGroupName: 'Phuket Fantasea Ticket',
		customerCountry: 'Pakistan',
		customerCountryCode: 'pk',
		directUrl: '/phuket-fantasea-tickets/phuket-fantasea-ticket-e-17883/',
	},
	{
		bookingID: 9914788,
		customerName: 'Szász',
		experienceImageUrl:
			'https://cdn-imgix.headout.com/userCollectedReviewMediaFiles/9914788/640677d53748901e63956a8a0ff494d0-51694006c4c6',
		rating: 5,
		content:
			'Disneyland was my childhood dream, and I was very exited to visit it . I waited for 10 years and it was amazing! A lot of experience, a lot of fun. In this place you can be child for a day again:)',
		tourID: 18859,
		tourName: '1 Disney® Park',
		city: 'PARIS',
		tourGroupId: 10209,
		language: 'EN',
		tourGroupName: 'Disneyland® Paris Tickets',
		customerCountry: 'Romania',
		customerCountryCode: 'ro',
		directUrl:
			'/paris-disneyland-tickets/disneyland-paris-1-day-ticket-e-10209/',
	},
	{
		bookingID: 10796431,
		customerName: 'Elise',
		experienceImageUrl:
			'https://cdn-imgix.headout.com/userCollectedReviewMediaFiles/10796431/c530a5837de95f4d60db8f7df9a3666e-9b9be9595150',
		rating: 5,
		content:
			'The tour guide was so lovely, polite and informative! It was really good actually walking to the Eiffel Tower, lots of great photo opportunities! ',
		tourID: 18324,
		tourName: 'Access to 2nd floor',
		city: 'PARIS',
		tourGroupId: 9990,
		language: 'EN',
		tourGroupName: 'Eiffel Tower 2nd Floor: Guided Tour',
		directUrl:
			'/eiffel-tower-tickets/eiffel-tower-second-floor-guided-tour-with-optional-seine-river-cruise-e-9990/',
	},

	{
		bookingID: 10641654,
		customerName: 'Jason',
		experienceImageUrl:
			'https://cdn-imgix.headout.com/userCollectedReviewMediaFiles/10641654/aca1461d30e3f67984b6003b734ea6bd-0d3ef8d6dab7',
		rating: 5,
		content:
			"Amazing experience! I really enjoyed the tour of the Colosseum, Vatican Museum, Sistine Chapel and St Peter's Basilica. The tour guides were very knowledgeable. Great from start to finish!",
		tourID: 5014,
		tourName: 'English Guide',
		city: 'ROME',
		tourGroupId: 3241,
		language: 'EN',
		tourGroupName:
			'Rome in a Day: Guided Tour of Vatican Museums & Colosseum with Sistine Chapel',
		directUrl:
			'/vatican-tickets/rome-in-a-day-guided-tour-vatican-museums-colosseum-with-sistine-chapel-e-3241/',
	},

	{
		bookingID: 10895081,
		customerName: 'Elen',
		experienceImageUrl:
			'https://cdn-imgix.headout.com/userCollectedReviewMediaFiles/10895081/dc82f7f8e84d6c172f283ad8de9dde68-23c6013e38cd',
		rating: 5,
		content:
			'Thanks for the great experience👍 Loved the possibility to skip the line and enjoy the gallery with no fuss at all! ',
		tourID: 22829,
		tourName: 'Skip The Line Ticket with Host',
		city: 'FLORENCE',
		tourGroupId: 7600,
		language: 'EN',
		tourGroupName:
			"Reserved Entrance - Accademia Gallery and Michelangelo's David",
		customerCountry: 'Armenia',
		customerCountryCode: 'am',
		directUrl:
			'/accademia-gallery-tickets/reserved-entrance-accademia-gallery-and-michaelangelos-david-e-7600/',
	},

	{
		bookingID: 10703955,
		customerName: 'Steve',
		experienceImageUrl:
			'https://cdn-imgix.headout.com/userCollectedReviewMediaFiles/10703955/50424ccf6c5088efaab66bcbc9dc1003-72b82d85d7a0',
		rating: 5,
		content:
			"Our guide was amazing. He helped open our minds and explained that we need to look beyond the obvious to fully appreciate the park and Gaudi's vision. This was one of the best tours we took in Barcelona. ",
		tourID: 12159,
		tourName: 'English Guided Tour',
		city: 'BARCELONA',
		tourGroupId: 6705,
		language: 'EN',
		tourGroupName: 'Park Güell Guided Tour with Skip-the-Line Access',
		customerCountry: 'United States',
		customerCountryCode: 'us',
		directUrl:
			'/barcelona-park-guell-tickets/park-guell-skip-the-line-tickets-with-guided-tour-e-6705/',
	},

	{
		bookingID: 11379170,
		customerName: 'Deborah',
		experienceImageUrl:
			'https://cdn-imgix.headout.com/userCollectedReviewMediaFiles/11379170/7fc1f96dfbc62685b48ebf0b0ff820d6-ac9038f2dfe0',
		rating: 5,
		content:
			'The Royal Palace is a feast for the eyes. It is beautiful with lots of rooms on show.  The cost was very fair and we loved not having to queue to get out tickets.',
		tourID: 19316,
		tourName: 'Timed Entry',
		city: 'MADRID',
		tourGroupId: 10403,
		language: 'EN',
		tourGroupName: 'Skip the Line Tickets to Royal Palace of Madrid',
		customerCountry: 'Ireland',
		customerCountryCode: 'ie',
		directUrl:
			'/royal-palace-of-madrid-tickets/royal-palace-of-madrid-skip-the-line-tickets-e-10403/',
	},

	{
		bookingID: 9199735,
		customerName: 'Deborah',
		experienceImageUrl:
			'https://cdn-imgix.headout.com/userCollectedReviewMediaFiles/9199735/875ea23f7099cb5bf7a00311572caa19-0a86c2778ea4',
		rating: 5,
		content:
			'Being at Stonehenge on Winter Solstice. I loved the whole experience.. the new visitors & exhibition centre is amazing.. Seeing the huts and how people would have lived back then was so fantastic to learn and see.',
		tourID: 11362,
		tourName: 'General Admission',
		city: 'LONDON',
		tourGroupId: 6269,
		language: 'EN',
		tourGroupName: 'Stonehenge Tickets with Audio Guide',
		customerCountry: 'Australia',
		customerCountryCode: 'au',
		directUrl:
			'/london-to-stonehenge-tours/stonehenge-tickets-with-audio-guide-e-6269/',
	},
	{
		bookingID: 11177251,
		customerProfile:
			'https://cdn-imgix-open.headout.com/home/customer-profile-images/ben.jpeg',
		customerName: 'Ben',
		experienceImageUrl:
			'https://cdn-imgix.headout.com/userCollectedReviewMediaFiles/11177251/d002cef6ac2322b75176f6b282047894-5eea73b3aef9',
		rating: 5,
		content:
			'The atmosphere created with the authentic set pieces on the real film location made you believe that you really have been in Hogwarts. Headout was very helpful in helping me book despite my disability. My carer and I had a fantastic day.',
		tourID: 29710,
		tourName: 'From Russell Square',
		city: 'LONDON',
		tourGroupId: 8881,
		language: 'EN',
		tourGroupName:
			'Warner Bros. Studio Harry Potter Tour with Premium Transfers from London',
		customerCountry: 'Belgium',
		customerCountryCode: 'be',
		directUrl:
			'/theme-parks/warner-bros-studios-london-tour-with-premium-bus-transfers-e-8881/',
	},
	{
		bookingID: 10758480,
		customerName: 'Fotjona',
		experienceImageUrl:
			'https://cdn-imgix.headout.com/userCollectedReviewMediaFiles/10758480/169e353685f7bcc1adc28f45b40b94f9-ec8418e78cc8',
		rating: 5,
		content:
			'Definitely was one the most incredible shows I ever seen 👏. I highly recommended to everyone to come and see it! Amazing performance.',
		tourID: 4717,
		tourName: 'Stalls',
		city: 'LONDON',
		tourGroupId: 3027,
		language: 'EN',
		tourGroupName: 'Matilda The Musical',
		directUrl: '/london-theatre-tickets/matilda-the-musical-e-3027/',
	},

	{
		bookingID: 10702922,
		customerName: 'Kelly',
		experienceImageUrl:
			'https://cdn-imgix.headout.com/userCollectedReviewMediaFiles/10702922/37699d968da62306227913fac620af8f-7692839bc6a4',
		rating: 4,
		content:
			"It was enjoyable, educational as well. I learned more than I would have thought. I'm 64 and remember watching on tv the moon landing.",
		tourID: 2342,
		tourName: 'General Admission',
		city: 'ORLANDO',
		tourGroupId: 1647,
		language: 'EN',
		tourGroupName: 'Kennedy Space Center Admission Ticket',
		customerCountry: 'United States',
		customerCountryCode: 'us',
		directUrl:
			'/kennedy-space-center-tickets-tours/kennedy-space-center-admission-skip-the-line-tickets-e-1647/',
	},
	{
		bookingID: 10738008,
		customerName: 'David',
		experienceImageUrl:
			'https://cdn-imgix.headout.com/userCollectedReviewMediaFiles/10738008/6328fcf568166cf3cec579e96b05d243-a1f618441758',
		rating: 5,
		content:
			'Back to the Future was excellent. Effects were amazing, quirky and great acting. Highly recommended!!!',
		tourID: 25700,
		tourName: 'Dress Circle',
		city: 'LONDON',
		tourGroupId: 13402,
		language: 'EN',
		tourGroupName: 'Back to the Future',
		customerCountry: 'United Kingdom',
		customerCountryCode: 'gb',
		directUrl: '/london-theatre-tickets/back-to-the-future-e-13402/',
	},
	{
		bookingID: 11164099,
		customerName: 'Darren',
		experienceImageUrl:
			'https://cdn-imgix.headout.com/userCollectedReviewMediaFiles/11164099/e181d296f87721b8c56ca5ded4e9eda9-856187f665ec',
		rating: 5,
		content:
			'All the staff were really friendly and helpful. The helicopter was new and had Bose headsets so we could all talk to the pilot. The pilot gave us good comentry throughout the tour. We went round the Statue of Liberty to do both sides and got a good view.',
		tourID: 1317,
		tourName: '15 Minute Helicopter Tour',
		city: 'NEW_YORK',
		tourGroupId: 924,
		language: 'EN',
		tourGroupName: '15 Minute Helicopter Tour - Including Heliport Fees',
		customerCountry: 'United Kingdom',
		customerCountryCode: 'gb',
		directUrl:
			'/helicopter-tours/15-minute-nyc-helicopter-tour-from-downtown-manhattan-including-heliport-fee-e-924/',
	},
];
