import { getChannel, getUserCountryCode } from 'Utils/stateUtils';

import { ACTIVE_LANGUAGE_CODES, CUSTOM_HEADER } from 'Constants/constants';

import { hasValidCurrencyCode, hasValidLanguageCode } from './bookingFlowUtils';
import { isServer } from './envUtils';
import { getCurrencyFromUrl, getLanguageFromUrl } from './urlUtils';

export const getPostRequest = (postData = {}, headers = {}) => ({
	method: 'POST',
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		...headers,
	},
	body: JSON.stringify(postData),
});

export const getPutRequest = (putData = {}, headers = {}) => ({
	method: 'PUT',
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		...headers,
	},
	body: JSON.stringify(putData),
});

export const getPatchRequest = (patchData = {}) => ({
	method: 'PATCH',
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
	body: JSON.stringify(patchData),
});

export const getDeleteRequest = () => ({
	method: 'DELETE',
	body: null,
});

export const getApiLanguageParameter = (
	currentLanguageCode: string,
	langUpperCase?: boolean,
) => {
	const languageFromUrl = !isServer() && getLanguageFromUrl(window.location);
	const languageCode = hasValidLanguageCode(
		languageFromUrl,
		currentLanguageCode,
	)
		? languageFromUrl
		: currentLanguageCode;
	let validatedLanguageCode =
		ACTIVE_LANGUAGE_CODES.includes(languageCode) && languageCode !== 'ar'
			? languageCode
			: '';
	if (langUpperCase)
		validatedLanguageCode = validatedLanguageCode.toUpperCase();
	return validatedLanguageCode ? `&language=${validatedLanguageCode}` : '';
};

export const getApiCurrencyParameter = (
	state: any,
	currentCurrencyCode: string,
) => {
	const currencyFromUrl = !isServer() && getCurrencyFromUrl(window.location);
	const currencyCode = hasValidCurrencyCode({
		state,
		currency: currencyFromUrl,
		currentCurrencyCode,
	})
		? currencyFromUrl
		: currentCurrencyCode;
	return currencyCode ? `&currency=${currencyCode}` : '';
};

export const getBaseRequestOptions = (
	state: any,
	baseOptions: RequestInit = {},
) => {
	const headers = new Headers(baseOptions?.headers || {});

	const country = getUserCountryCode(state);
	if (country) headers.set(CUSTOM_HEADER.FORWARDED_COUNTRY_CODE, country);

	const channel = getChannel(state);
	if (channel) {
		headers.set(CUSTOM_HEADER.CHANNEL, channel);
	}

	return Object.assign({}, baseOptions, { headers });
};
