// @ts-expect-error TS(7016): Could not find a declaration file for module 'loda... Remove this comment to see the full error message
import groupBy from 'lodash.groupby';

import type { TOperatingDaySchedule } from 'Components/desktop/operatingHoursTable/type';

import { ENDPOINTS } from 'Utils/apiEndpoints';
import { getTicketValidityByTourId } from 'Utils/checkoutMetadata';
import dayjs from 'Utils/dayjsUtil';
import {
	getApiCurrencyParameter,
	getApiLanguageParameter,
} from 'Utils/fetchUtils';
import { getImgixImageUrlFixed, sanitiseURLProtocol } from 'Utils/imageUtils';
import { getLocalizedPercentage } from 'Utils/localizationUtils';
import { capitalizeFirstLetter } from 'Utils/stringUtils';

import {
	ANALYTICS_FLOW_TYPE,
	BROADWAY_COLLECTION_ID,
	DESCRIPTORS_RANKING_LOGIC,
	FLEXIBLE_START_TIME,
	getDescriptorsMap,
	GLOBAL_CITY_CODE,
	LTD_COLLECTION_ID,
	MAX_DESCRIPTORS_DISPLAYED,
	PAGE_TYPE,
	PROFILE_TYPE,
	SENSITIVE_COLLECTION_IDS,
	SEO_NON_INDEXABLE_LANGUAGE_CODES,
	SORT_ORDER,
	SORT_TYPE,
	SUFFIX_PRODUCT_NAME,
	TICKETS_CATEGORY,
	TOUR_TYPE,
	VALIDITY_TYPES,
} from 'Constants/constants';
import { strings } from 'Constants/strings';

import { EN } from 'Static/labels/english';

import {
	getFlexiTours,
	isComboVariant,
	isSeatmap,
	isTourGroupOpenDated,
} from './bookingFlowUtils';
import { getPriceProfile } from './bookingUtils';
import { getExtraChargesList } from './breakupUtils';
import { getCityUrlAsPath, getGlobalPageUrlAsPath } from './cityUtils';
import { getAllPlacesToVisitURL } from './collectionsUtils';
import {
	addDay,
	checkIfDateInBetweenDateRange,
	format,
	formatDurationToHoursMinutes,
	getCancellationDayAndTime,
	getCurrentDateInTimezone,
	getDayJSLocaleAndHumanReadableDate,
	getDurationInDays,
	getDurationInHours,
	getHumanReadableDateTime,
	getHumanReadableTime,
	isDateValid,
} from './dateUtils';
import { formatPrice, generateFilterKey } from './gen';
import { getPaxPriceDetails, getPriceTag } from './pricingUtils';
import {
	getApiCDNBaseUrlV2,
	getApiString,
	getBaseUrl,
	getHostName,
	getSEOLanguageLabel,
	getWebPathString,
} from './urlUtils';

export const getCashbackAmount = (product: any, lang?: string) => {
	const { listingPrice } = product;
	if (!listingPrice) return null;
	const { cashbackValue, cashbackType } = listingPrice;
	if (cashbackValue && cashbackValue > 0) {
		if (cashbackType === 'ABSOLUTE') {
			const { currency } = product;
			const priceAsString = formatPrice(cashbackValue, currency);
			return `${priceAsString}`;
		} else if (cashbackType === 'PERCENTAGE') {
			const langWithSpacedSign = ['it', 'es', 'fr', 'de'];

			return lang && langWithSpacedSign.includes(lang)
				? `${cashbackValue} %`
				: `${cashbackValue}%`;
		}
	}
	return null;
};

export const getAddressAsString = (product: any) => {
	if (!product) {
		return '';
	}
	const { startLocation } = product;
	const { addressLine1, addressLine2, cityName } = startLocation;
	const addressLines = [];
	if (addressLine1) addressLines.push(addressLine1);
	if (addressLine2) addressLines.push(addressLine2);
	if (cityName) addressLines.push(cityName);

	return addressLines.join(', ');
};

export const getURLSlugForEntity = ({
	// @ts-expect-error TS(7031): Binding element 'product' implicitly has an 'any' ... Remove this comment to see the full error message
	product,
	paramLang = 'en',
	relative = false,
}) => {
	if (!product) return null;
	const { supportedLanguages } = product;
	const apiLang = isProductSupportedInCurrentLanguage({
		paramLang: paramLang,
		supportedLanguages,
	})
		? getApiString(paramLang)
		: 'EN';
	const productUrl = product?.urlSlugs?.[apiLang];
	return `${relative ? '' : getBaseUrl()}${productUrl}`;
};

export const isNoIndex = ({
	// @ts-expect-error TS(7031): Binding element 'product' implicitly has an 'any' ... Remove this comment to see the full error message
	product,
	paramLang = 'en',
	isCityNoIndex = false,
}) => {
	if (!product) return false;

	const { noIndex, supportedLanguages = [] } = product;
	const formattedLang = paramLang?.toUpperCase?.() || 'EN';
	return (
		isCityNoIndex ||
		!supportedLanguages.includes(formattedLang) ||
		!!noIndex
	);
};

export const isProductSupportedInCurrentLanguage = ({
	paramLang = 'en',
	// @ts-expect-error TS(7031): Binding element 'supportedLanguages' implicitly ha... Remove this comment to see the full error message
	supportedLanguages,
}) => {
	return !!supportedLanguages.includes(getApiString(paramLang));
};

export const getProductCardClickUrl = ({
	// @ts-expect-error TS(7031): Binding element 'productCard' implicitly has an 'a... Remove this comment to see the full error message
	productCard,
	paramLang = 'en',
	paramCity = '',
	withBase = false,
}) => {
	const { tourGroupUrl } = productCard;
	if (!tourGroupUrl) return '';
	const bookingPageUrl = tourGroupUrl.replace(/\/tour\//, '/book/');
	if (isOneTimeEvent(productCard)) {
		return bookingPageUrl;
	}
	const currentLang = paramLang ? paramLang.toUpperCase() : 'EN';
	const entitySlug = productCard?.urlSlugs?.[getApiString(currentLang)];
	const cityParam = paramCity ? `?city=${paramCity}` : '';
	return `${withBase ? getBaseUrl() : ''}${entitySlug}${cityParam}`;
};

export const getProductCardClickUrlV2 = ({
	urlSlug,
	paramCity,
	withBase = false,
}: {
	urlSlug: string;
	paramCity?: string | null;
	withBase?: boolean;
}) => {
	const cityParam = paramCity ? `?city=${paramCity}` : '';
	const baseUrl = withBase ? getBaseUrl() : '';
	return `${baseUrl}${urlSlug}${cityParam}`;
};

// @ts-expect-error TS(7031): Binding element 'product' implicitly has an 'any' ... Remove this comment to see the full error message
export const getReviewsPageUrl = ({ product, paramLang, withBase = false }) => {
	if (!product) return null;
	const langPrefix = paramLang && paramLang !== 'en' ? `/${paramLang}` : '';
	return `${withBase ? getBaseUrl() : ''}${langPrefix}${product?.url.replace(
		/\/tour\//,
		'/reviews/',
	)}`;
};

export const getSiteMapMetaData = ({
	product,
	currentCity,
	lang = 'en',
	slots,
	categoriesAndSubCategoriesInfo,
	localizedStrings,
}: {
	product: any;
	currentCity: any;
	lang?: string;
	slots?: any;
	categoriesAndSubCategoriesInfo: any;
	localizedStrings: TStaticStringLabels;
}) => {
	const {
		id: sku,
		name,
		imageUploads,
		listingPrice,
		reviewsDetails,
		topReviews,
		shortSummary,
	} = product;
	// @ts-expect-error TS(7034): Variable 'imageUrls' implicitly has type 'any[]' i... Remove this comment to see the full error message
	const imageUrls = [];
	for (const imgObject of imageUploads) {
		const imageUrl = imgObject?.url;
		imageUrls.push(
			sanitiseURLProtocol(imageUrl, 'auto=compress&w=768&h=480&fit=min'),
		);
	}
	const metaData = {
		'@context': 'http://www.schema.org',
		'@type': 'Product',
		url: getURLSlugForEntity({ product, paramLang: lang }),
		brand: {
			'@type': 'Organization',
			name: 'Headout',
		},
		name,
		image: imageUrls[0],
		description: shortSummary,
		sku,
		productID: sku,
		offers: getOffersSchema({ product, lang }),
	};

	// Adding AggregateRating as a part of StructuredData
	if (reviewsDetails) {
		const { reviewsCount, averageRating, ratingsCount } = reviewsDetails;
		if (reviewsCount || averageRating || ratingsCount) {
			(metaData as any).aggregateRating = {
				'@type': 'AggregateRating',
				bestRating: 5,
				worstRating: 1,
				...(averageRating && { ratingValue: averageRating }),
				...(ratingsCount && { reviewCount: ratingsCount }),
			};
		}
	}
	// Adding Reviews as a part of StructuredData
	if (topReviews) {
		(metaData as any).review = getReviewsSchema({
			topReviews,
			name,
		});
	}
	const { linkUrls, linkTitles } = getBreadcrumbsForProductPage({
		product,
		city: currentCity,
		serverStrings: localizedStrings,
		lang,
		...shouldShowEntitiesInBreadcrumbs({
			product,
			categoriesAndSubCategoriesInfo,
		}),
	});
	const breadcrumbData = {
		'@context': 'https://schema.org',
		'@type': 'BreadcrumbList',
		itemListElement: linkTitles.map((_, index) => ({
			'@type': 'ListItem',
			name: linkTitles[index],
			position: index + 1,
			...(linkUrls[index] && {
				item: getHostName(linkUrls[index])
					? linkUrls[index]
					: `${getBaseUrl()}${linkUrls[index]}`,
			}),
		})),
	};

	const productUrl = `${getBaseUrl()}${product.urlSlugs[lang.toUpperCase()]}`;
	const eventData =
		slots?.slots && slots?.slots?.length
			? slots?.slots.slice(0, 10).map((inv: any) => ({
					'@context': 'https://schema.org',
					'@type': 'Event',
					name,
					startDate: `${inv?.startDate}T${inv?.startTime}`,
					location: getLocationSchema(product),
					// @ts-expect-error TS(7005): Variable 'imageUrls' implicitly has an 'any[]' typ... Remove this comment to see the full error message
					image: imageUrls[0],
					endDate: `${inv?.startDate}T${inv?.endTime}`,
					description: `Book ${name} tickets for ${getHumanReadableDateTime(
						inv,
					)}`,
					performer: {
						'@type': 'PerformingGroup',
						name,
					},
					offers: {
						'@type': 'Offer',
						url: productUrl,
						price: listingPrice ? listingPrice?.finalPrice : null,
						priceCurrency: listingPrice
							? listingPrice?.currencyCode
							: null,
						availability: listingPrice
							? 'http://schema.org/InStock'
							: 'http://schema.org/OutOfStock',
						itemCondition: 'http://schema.org/NewCondition',
						validFrom: '2017-01-20T16:20-08:00',
					},
			  }))
			: [];

	return [metaData, breadcrumbData, ...eventData].filter(data => data);
};
export const getLocationSchema = (product: any) => {
	const { startLocation } = product;
	const { addressLine1, addressLine2, postalCode, state, countryCode } =
		startLocation;

	return {
		'@type': 'Place',
		name: addressLine1,
		address: {
			'@type': 'PostalAddress',
			streetAddress: addressLine1,
			addressLocality: addressLine2,
			postalCode,
			addressRegion: state,
			addressCountry: countryCode,
		},
	};
};

const getOffersSchema = ({
	product,
	lang = 'en',
}: {
	product: any;
	lang: string;
}) => {
	const { listingPrice, currency } = product;

	return {
		'@type': 'Offer',
		price: listingPrice ? listingPrice?.finalPrice : 0,
		priceCurrency: listingPrice
			? listingPrice?.currencyCode
			: currency.code,
		availability: listingPrice
			? 'http://schema.org/InStock'
			: 'http://schema.org/OutOfStock',
		itemCondition: 'http://schema.org/NewCondition',
		url: getURLSlugForEntity({ product, paramLang: lang }),
	};
};

export const getProductPageTitle = (product: any) => {
	if (!product) return null;
	const { metaTitle, name, city } = product;
	if (metaTitle) {
		return metaTitle;
	}
	const titles = [
		`${name} ${city?.displayName} | ${strings.TICKETS}, ${strings.TOURS} & ${strings.DEALS} | Headout`,
		`${name} ${city?.displayName} | ${strings.TICKETS} & ${strings.TOURS} | Headout`,
		`${name} ${city?.displayName} ${strings.TICKETS} | Headout`,
		`${name} ${city?.displayName} | Headout`,
		`${name} | Headout`,
	];
	return titles.find(x => x.length < 70) || titles[titles.length - 1];
};

export const getProductMetaDescription = (product: any) => {
	if (!product) return null;
	const { name, city } = product;
	return (
		product?.metaDescription ||
		strings.formatString(
			strings.PRODUCT_META_DESCRIPTION,
			name,
			city?.displayName,
		)
	);
};

export const htmlEscapeQuotes = (str: any) => str.replace(/\"/g, '&quot;');

export const getProductMetaTags = ({ product, paramLang }: any) => {
	if (!product) return null;
	const { listingPrice, name, displayTags, supportedLanguages } = product;
	let tags = [
		'<meta property="og:site_name" content="Headout" />',
		'<meta property="og:type" content="product" />',
		`<meta property="og:url" content="${getURLSlugForEntity({
			product,
		})}" />`,
		`<meta property="og:description" content="${htmlEscapeQuotes(
			getProductMetaDescription(product),
		)}" />`,
		`<meta name="twitter:description" content="${htmlEscapeQuotes(
			getProductMetaDescription(product),
		)}" />`,
		`<meta property="og:title" content="${htmlEscapeQuotes(
			getProductPageTitle(product),
		)}" />`,
		`<meta property="twitter:title" content="${htmlEscapeQuotes(
			getProductPageTitle(product),
		)}" />`,
		'<meta name="twitter:card" content="summary_large_image" />',
		'<meta name="twitter:site" content="@headout" />',
		`<meta property="og:availability" content="${
			listingPrice ? 'instock' : 'out of stock'
		}" />`,
		`<meta property="product:availability" content="${
			listingPrice ? 'instock' : 'out of stock'
		}" />`,
		`<meta property="product:category" content="${htmlEscapeQuotes(
			product?.primaryCollection?.displayName || '',
		)}" />`,
		'<meta property="product:condition" content="new" />',
		`<meta property="og:price:amount" content="${
			listingPrice ? listingPrice?.finalPrice : ''
		}" />`,
		`<meta property="og:price:currency" content="${product?.currency?.code}" />`,
		`<meta property="product:price:amount" content="${
			listingPrice ? listingPrice?.finalPrice : ''
		}" />`,
		`<meta property="product:price:currency" content="${product?.currency?.code}" />`,
		`<meta name="keywords" content="${displayTags.join(
			', ',
		)} ${getCityDisplayName(product)}, ${htmlEscapeQuotes(
			name,
		)}, tickets, discount, best price, top-rated, reviews" />`,
	];
	if (
		isProductSupportedInCurrentLanguage({
			paramLang: paramLang,
			supportedLanguages,
		}) &&
		supportedLanguages?.length > 1 // Show alt-lang, only if there's atleast one non english supported language.
	) {
		tags.push(
			`<link rel="alternate" hreflang="x-default" href="${getURLSlugForEntity(
				{
					product,
				},
			)}" />`,
		);
		tags = tags.concat(
			supportedLanguages
				.filter(
					(lang: any) =>
						!SEO_NON_INDEXABLE_LANGUAGE_CODES.includes(
							getWebPathString(lang),
						),
				)
				.map(
					(lang: any) =>
						`<link rel="alternate" hreflang="${getSEOLanguageLabel(
							lang,
						)}" href="${getURLSlugForEntity({
							product,
							paramLang: lang,
						})}" />`,
				),
		);
	}
	const { imageUploads } = product;
	const cleanedImageAlt = imageUploads?.[0]?.alt?.replaceAll('"', "'");
	if (imageUploads.length > 0) {
		const defaultImageUrl = sanitiseURLProtocol(
			imageUploads?.[0]?.url,
			'auto=compress&w=768&h=480&fit=min',
		);
		tags = tags.concat([
			`<meta property="twitter:image" content="${defaultImageUrl}" />`,
			`<meta property="og:image:url" content="${defaultImageUrl}" />`,
			`<meta property="og:image:secure_url" content="${defaultImageUrl}" />`,
			`<meta property="og:image:type" content="${getURLSlugForEntity({
				product,
			})}" />`,
			'<meta property="og:image:width" content="768" />',
			'<meta property="og:image:height" content="480" />',
			`<meta property="og:image:alt" content="${cleanedImageAlt}" />`,
		]);
	}
	return tags.reduce((tag, allTags) => `${allTags} \n ${tag}`, '');
};

export const isOneTimeEvent = (product: any) => {
	// @NOTE wrap product with Map as some function calls not passing immutable object, just plain JS object
	return product
		? product?.allTags?.indexOf(TOUR_TYPE.ONE_TIME) !== -1
		: false;
};

export const getProductListingPriceValue = (product: any) => {
	const { listingPrice } = product;
	return listingPrice?.finalPrice;
};

export const getPrimaryCollectionId = (product: any) =>
	product?.primaryCollection ? product?.primaryCollection?.id : null;

export const getPrimaryCollectionName = (product: any) =>
	product?.primaryCollection ? product?.primaryCollection?.displayName : null;

export const getPrimaryCategoryId = (product: any) =>
	product?.primaryCategory?.id || null;

export const getPrimaryCategoryName = (product: any) =>
	product?.primaryCategory?.name || null;

export const getPrimarySubCategoryId = (product: any) =>
	product?.primarySubCategory?.id || null;

export const getPrimarySubCategoryName = (product: any) =>
	product?.primarySubCategory?.name || null;

export const getReviewsSchema = ({ topReviews, name }: any) => {
	if (!topReviews) return null;
	// @ts-expect-error TS(7034): Variable 'reviewsSchema' implicitly has type 'any[... Remove this comment to see the full error message
	const reviewsSchema = [];
	topReviews.forEach((reviewDetails: any) => {
		reviewsSchema.push(getIndividualReviewSchema(reviewDetails, name));
	});
	// @ts-expect-error TS(7005): Variable 'reviewsSchema' implicitly has an 'any[]'... Remove this comment to see the full error message
	return reviewsSchema;
};

export const getIndividualReviewSchema = (reviewDetails: any, name: any) => {
	const { nonCustomerName, rating, reviewTime, content } = reviewDetails;
	return {
		'@type': 'Review',
		author: {
			'@type': 'Person',
			name: nonCustomerName,
		},
		datePublished: format(reviewTime, 'dd, mmmm, yyyy'),
		reviewBody: content,
		name,
		reviewRating: {
			'@type': 'Rating',
			bestRating: 5,
			ratingValue: rating,
			worstRating: 1,
		},
	};
};

export const getGroupedSelectedSeats = (seatsInfo: any) => {
	let groupedSelectedSeats = {};
	seatsInfo.forEach((seat: any) => {
		let seatDetails = {};
		seatDetails = { ...seatDetails, count: 1, price: seat.price };
		groupedSelectedSeats = {
			...groupedSelectedSeats,
			[seat.id]: seatDetails,
		};
	});
	return groupedSelectedSeats;
};

export const getPaxDetails = ({ booking, pricing }: any) => {
	const { selectionMap, seatMapInfo, groupSize, selectedVariantId } = booking;
	const priceProfile = getPriceProfile(pricing, booking);
	if (!priceProfile || isComboVariant(pricing, selectedVariantId)) {
		return null;
	}
	const profileType =
		priceProfile?.priceProfileType || PROFILE_TYPE.PER_PERSON;
	let paxDetails;
	if (profileType === PROFILE_TYPE.PER_PERSON && !isSeatmap(pricing)) {
		paxDetails = getPaxPriceDetails(selectionMap, priceProfile);
	} else if (profileType === PROFILE_TYPE.PER_GROUP) {
		paxDetails = {
			groupSize,
		};
	} else {
		paxDetails = getGroupedSelectedSeats(seatMapInfo);
	}

	return paxDetails;
};

export const getStructuredFilters = (filters: any) => {
	const { POPULARITY, PRICE } = SORT_TYPE;
	const { ASC } = SORT_ORDER;
	if (filters?.['sort-type']) {
		const sortOrder = filters?.['sort-order'];
		const sortType = filters?.['sort-type'];
		let sortLabel: string;
		switch (sortType) {
			case POPULARITY:
				sortLabel = EN['CP_SORT_POPULARITY'];
				break;
			case PRICE:
				if (sortOrder === ASC) {
					sortLabel = EN['CP_PRICE_LOW_HIGH'];
				} else {
					sortLabel = EN['CP_PRICE_HIGH_LOW'];
				}
				break;
			default:
				// @ts-ignore
				sortLabel = EN['CP_PICKED_FOR_YOU'];
				break;
		}
		return {
			isSortedFilter: true,
			sortType: sortLabel,
		};
	} else {
		let structuredFilter = {};
		let priceFilter = {};
		let timeFilter = {};
		let dateFilter = {};

		// structuring the price filter
		if (filters?.['filter-price-high']) {
			priceFilter = {
				...priceFilter,
				selected: true,
				minPrice: filters?.['filter-price-low'],
				maxPrice: filters?.['filter-price-high'],
			};
		} else {
			priceFilter = {
				...priceFilter,
				selected: false,
			};
		}

		// structuring the date filter
		if (filters?.['filter-dates[]']) {
			dateFilter = {
				...dateFilter,
				selected: true,
				datesSelected: filters?.['filter-dates[]'],
			};
		} else {
			dateFilter = {
				...dateFilter,
				selected: false,
			};
		}

		// structuring the time filter
		if (filters?.['filter-times[]']) {
			timeFilter = {
				...timeFilter,
				selected: true,
				timeSelected: filters?.['filter-times[]'],
			};
		} else {
			timeFilter = {
				...timeFilter,
				selected: false,
			};
		}

		structuredFilter = {
			...structuredFilter,
			priceFilter: priceFilter,
			timeFilter,
			dateFilter,
		};
		return structuredFilter;
	}
};

export const getStructuredTourList = (
	selectedDate: any,
	selectedTime: any,
	pricing: any,
) => {
	const { inventoryMap, inventoryMapByDateTime, tourMap, currency } = pricing;
	const allKeys = Object.keys(tourMap);
	// @ts-expect-error TS(7034): Variable 'toursList' implicitly has type 'any[]' i... Remove this comment to see the full error message
	const toursList = [];
	if (selectedDate && selectedTime) {
		const datedInventory = inventoryMap?.[selectedDate];
		if (selectedTime === FLEXIBLE_START_TIME) {
			const flexiTours = getFlexiTours(tourMap);
			const tourIdsWithFlexibleTime = Object.keys(flexiTours);
			Object.keys(datedInventory)
				.filter(x => tourIdsWithFlexibleTime.indexOf(String(x)) !== -1)
				.forEach(tourKey => {
					const tourId = String(tourKey);
					const inv = datedInventory?.[tourId];
					const inventoryType = tourMap?.[tourId]?.[0]?.inventoryType;
					// @ts-expect-error TS(2339): Property 'price' does not exist on type '{ price: ... Remove this comment to see the full error message
					const { price, originalPrice } = getPriceTag(
						inv?.[0],
						currency,
						// @ts-expect-error TS(2554): Expected 2 arguments, but got 3.
						inventoryType,
					);
					toursList.push({
						id: tourId,
						availability: true,
						netPrice: formatPrice(price, currency),
						originalPrice: formatPrice(originalPrice, currency),
					});
				});
		} else {
			const filteredInventoryByTime = inventoryMapByDateTime?.[
				selectedDate
			]?.[selectedTime]
				? inventoryMapByDateTime?.[selectedDate]?.[selectedTime]
				: [];
			if (filteredInventoryByTime) {
				filteredInventoryByTime.forEach((inv: any) => {
					const tourId = String(inv?.tourId);
					const inventoryType = tourMap?.[tourId]?.[0]?.inventoryType;
					// @ts-expect-error TS(2339): Property 'price' does not exist on type '{ price: ... Remove this comment to see the full error message
					const { price, originalPrice } = getPriceTag(inv, currency);
					toursList.push({
						id: tourId,
						availability: true,
						netPrice: formatPrice(price, currency),
						originalPrice: formatPrice(originalPrice, currency),
						inventoryType,
					});
				});
			}
		}
		// @ts-expect-error TS(7005): Variable 'toursList' implicitly has an 'any[]' typ... Remove this comment to see the full error message
		const availableToursKeys = toursList.map(tour => tour.id);
		const unavailableToursKeys = allKeys.filter(
			x => !availableToursKeys.includes(x),
		);
		unavailableToursKeys.forEach(id => {
			const tourId = String(id);
			toursList.push({
				id: tourId,
				availability: false,
			});
		});
	}
	// @ts-expect-error TS(7005): Variable 'toursList' implicitly has an 'any[]' typ... Remove this comment to see the full error message
	return groupBy(toursList, 'id');
};

export const getStructuredTourListMobile = (
	selectedDate: any,
	pricing: any,
) => {
	const { inventoryMap, tourMap, currency } = pricing;
	const allKeys = Object.keys(tourMap);
	// @ts-expect-error TS(7034): Variable 'toursList' implicitly has type 'any[]' i... Remove this comment to see the full error message
	const toursList = [];
	if (selectedDate) {
		const datedInventory = inventoryMap?.[selectedDate];
		const availableKeys = Object.keys(datedInventory);
		Object.keys(datedInventory)
			.filter(x => availableKeys.indexOf(String(x)) !== -1)
			.forEach(tourKey => {
				const tourId = String(tourKey);
				const inv = datedInventory?.[tourId];
				const inventoryType = tourMap?.[tourId]?.[0]?.inventoryType;
				// @ts-expect-error TS(2339): Property 'price' does not exist on type '{ price: ... Remove this comment to see the full error message
				const { price, originalPrice } = getPriceTag(
					inv?.[0],
					currency,
				);
				toursList.push({
					id: tourId,
					availability: true,
					netPrice: formatPrice(price, currency),
					originalPrice: formatPrice(originalPrice, currency),
					inventoryType,
				});
			});
		// @ts-expect-error TS(7005): Variable 'toursList' implicitly has an 'any[]' typ... Remove this comment to see the full error message
		const availableToursKeys = toursList.map(tour => tour.id);
		const unavailableToursKeys = allKeys.filter(
			x => !availableToursKeys.includes(x),
		);
		unavailableToursKeys.forEach(id => {
			const tourId = String(id);
			toursList.push({
				id: tourId,
				availability: false,
			});
		});
	}
	// @ts-expect-error TS(7005): Variable 'toursList' implicitly has an 'any[]' typ... Remove this comment to see the full error message
	return groupBy(toursList, 'id');
};

export const getExtraCharges = (breakup: any) => {
	if (!breakup) return null;
	let extraCharges = 0;
	getExtraChargesList(breakup).forEach((item: any) => {
		extraCharges += item?.value;
	});
	return extraCharges;
};

export const getProductPropertiesForConversionEvent = (product: any) => {
	const { id, name, listingPrice } = product;
	const properties = {
		id,
		name,
		productId: id, // Note: Ideally productId and productName should be removed, keeping them for backward compatibility reasons
		productName: name,
		tourGroupID: id,
		tourGroupName: name,
		currency: product?.currency?.code,
		cityCode: getTourCityCode(product),
		categoryId: product?.primaryCollection?.id,
		category: product?.primaryCollection?.displayName,
		list: product?.primaryCollection?.displayName,
		list_name: product?.primaryCollection?.displayName,
		brand: getCityDisplayName(product),
	};
	if (listingPrice) {
		const { bestDiscount, cashbackValue, cashbackType } = listingPrice;
		return {
			...properties,
			discountPercentage: bestDiscount,
			cashbackValue,
			cashbackType,
		};
	}
	return properties;
};

export const getPrimaryImageUrl = (product: any) =>
	product?.imageUploads?.[0]?.url;

export const getTourCountryCode = (product: any): string =>
	product?.city?.country?.code;

export const getTourCountryDisplayName = (product: any): string =>
	product?.city?.country?.displayName;

export const getTourCityCode = (product: any) => product?.city?.code;

export const getCityDisplayName = (product: any): string =>
	product?.city?.displayName;

export const getCashbackString = (product: any, stringTemplate?: string) => {
	// show max cashbackValue available
	const { language, listingPrice } = product ?? {};

	if (!listingPrice) return null;

	const { cashbackValue, cashbackType } = listingPrice;
	if (cashbackValue && cashbackValue > 0) {
		if (cashbackType === 'ABSOLUTE') {
			const { currency } = product;
			const priceAsString = formatPrice(cashbackValue, currency);

			return strings.formatString(
				stringTemplate ?? strings.GET_CASHBACK,
				priceAsString,
			);
		} else if (cashbackType === 'PERCENTAGE') {
			const formattedCashback = getLocalizedPercentage(
				cashbackValue,
				language,
			);

			return strings.formatString(
				stringTemplate ?? strings.GET_CASHBACK,
				formattedCashback,
			);
		}
	}

	return null;
};

// @ts-expect-error TS(7031): Binding element 'descriptorType' implicitly has an... Remove this comment to see the full error message
export const getDescriptorByType = ({ descriptorType, descriptors = [] }) =>
	descriptors.find(desc => (desc as any)?.code === descriptorType);

export const getDescriptorObject = (descriptor: any) => {
	const DESCRIPTOR_MAP = getDescriptorsMap();
	// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
	return DESCRIPTOR_MAP[descriptor];
};

export const rankDescriptorList = (descriptorList: any) => {
	const rankedDescriptorList = descriptorList.sort(
		(a: any, b: any) =>
			DESCRIPTORS_RANKING_LOGIC.indexOf(a?.icon) -
			DESCRIPTORS_RANKING_LOGIC.indexOf(b?.icon),
	);
	return rankedDescriptorList;
};

export const getDescriptorList = (product: any) => {
	const { minDuration, maxDuration, descriptors } = product;
	let durationText;

	if (!minDuration || !maxDuration) {
		durationText = strings.FLEXIBLE_DURATION;
	} else {
		const durationMin = formatDurationToHoursMinutes(minDuration);
		const durationMax = formatDurationToHoursMinutes(maxDuration);
		if (minDuration === maxDuration) {
			durationText = `${durationMin}`;
		} else {
			durationText = `${durationMin} - ${durationMax}`;
		}
	}
	const descriptorList: { icon: string; label: string }[] = (
		descriptors as { code: string; name: string }[]
	).map(descriptor => getDescriptorObject(descriptor.code));
	if (
		isTourGroupOpenDated(product) &&
		!descriptorList.find(
			({ label }) =>
				label.toLowerCase() === strings.PPD_VALIDITY.toLowerCase(),
		)
	) {
		const ValidityDescriptor = {
			icon: 'validity',
			label: strings.PPD_VALIDITY,
		};
		descriptorList.push(ValidityDescriptor);
	}
	if (!hasComboVariants(product)) {
		const DurationDescriptor = { icon: 'clock', label: durationText };
		descriptorList.push(DurationDescriptor);
	}
	const rankedDescriptorList = rankDescriptorList(descriptorList);
	return rankedDescriptorList.slice(0, MAX_DESCRIPTORS_DISPLAYED);
};

export const getProductCashback = (product: any) => {
	const { listingPrice } = product;
	if (!listingPrice) return null;
	const { cashbackValue, cashbackType } = listingPrice;
	if (cashbackValue && cashbackValue > 0) {
		if (cashbackType === 'ABSOLUTE') {
			const { currency } = product;
			if (!currency) return null;
			const priceAsString = formatPrice(cashbackValue, currency);
			return `${priceAsString}`;
		} else if (cashbackType === 'PERCENTAGE') {
			return `${cashbackValue}%`;
		}
	}
	return null;
};

// @ts-expect-error TS(7031): Binding element 'id' implicitly has an 'any' type.
export const getExperiencePageUrl = ({ id, lang = 'en', query = '' }) => {
	const langPrefix = `/${lang}`;
	const routeWithId = `/e-${id}`;
	return `${langPrefix}${routeWithId}${query}`;
};

export const getExperienceLinkHref = (lang: any) => {
	const langPrefix = lang ? `/[lang]` : '';
	return `${langPrefix}/e-[id]`;
};

export const getSavingsPercent = ({ originalPrice, finalPrice }: any) =>
	Number((((originalPrice - finalPrice) / originalPrice) * 100).toFixed(2));

export const getSavingsString = (
	savings: any,
	cashback?: any,
	saveStringOverride?: any,
) => {
	if (isNaN(savings) || savings < 3) return '';

	let savingsString = '';
	if (cashback && !isNaN(cashback)) {
		const saveString = strings.formatString(
			strings.PRODUCT_CARD.SAVE,
			Math.round(savings),
		);
		const cashbackString = strings.formatString(
			strings.PRODUCT_CARD.CASHBACK,
			cashback,
		);
		savingsString = `${saveString} + ${cashbackString}`;
	} else {
		savingsString = strings.formatString(
			saveStringOverride
				? saveStringOverride
				: strings.CMN_SAVE_UPTO_PERCENT,
			Math.round(savings),
		);
	}

	return savingsString;
};

export const getProductDiscountString = (savings: any) => {
	let savingsString = '';

	if (isNaN(savings) || savings < 3) return savingsString;
	else return strings.formatString(strings.HOHO.OFF_PERCENT, savings);
};
export const getAvailableVariants = (product: any) =>
	product?.variants?.filter((variant: any) => !!variant?.listingPrice);

export const isSingleVariantProduct = (product: any) =>
	product?.variants?.length === 1;

export const hasMultipleAvailableVariants = (product: any) =>
	getAvailableVariants(product)?.length > 1;

export const hasComboVariants = (product: any) =>
	getAvailableVariants(product)?.some(
		(variant: any) => variant?.tours?.length > 1,
	);

export const hasComboVariantsInPricing = (pricing: any) =>
	getAvailableVariants(pricing)?.some(
		(variant: any) => variant?.tours?.length > 1,
	);

export const checkIfMultiVariantComboProduct = (product: any) =>
	hasMultipleAvailableVariants(product) && hasComboVariants(product);

export const getProductIdFromLocation = (location: any) => location?.query?.id;

export const getSuffixNameFromTag = ({
	allTags = [],
	useSuffixWithHypen = true,
	currentLang = 'en',
}) => {
	const supportedLanguages = ['EN'];
	const isLanguageSupported = supportedLanguages.includes(
		currentLang.toUpperCase(),
	);
	if (!isLanguageSupported) return '';

	const suffixProductTagObj = allTags.filter(tag =>
		(tag as any).includes(SUFFIX_PRODUCT_NAME),
	);
	let suffixProductTag;
	if (suffixProductTagObj && suffixProductTagObj.length > 0) {
		suffixProductTag = (suffixProductTagObj?.[0] as any).match(
			/^SFX-(.*)$/,
		)[1];
	}

	return suffixProductTag
		? `${useSuffixWithHypen ? ' - ' : ' '}${capitalizeFirstLetter(
				suffixProductTag,
		  )}`
		: '';
};

export const getProductImage = ({ product }: any) => {
	const imageUrl = getPrimaryImageUrl(product);

	return getImgixImageUrlFixed({
		url: imageUrl,
		width: 400,
		height: 300,
		compression: 30,
	});
};

const productCardBoosterOrder = ({
	hasSellingOutFastBooster,
	hasFreeCancellation,
}: any) => {
	const constructBoosterObject = (name: any) => ({ name, utilized: false });
	const boosterOrder = {
		...(hasSellingOutFastBooster && {
			sellingOutFast: constructBoosterObject(
				strings.BOOSTERS.SELLING_OUT_FAST,
			),
		}),
		...(hasFreeCancellation && {
			freeCancellation: constructBoosterObject(strings.PPD_FREE_CANCEL),
		}),
	};
	return boosterOrder
		? Object.values(boosterOrder).map(booster => (booster as any).name)
		: [];
};

export const productHasSpecialOffer = ({ microBrandsHighlight }: any) => {
	const specialOfferExists =
		microBrandsHighlight?.includes('Special Offer :');
	const specialOfferDateRegex =
		/Closing Date Special Offer\s*(\d{4}-\d{2}-\d{2})/;
	const match = specialOfferDateRegex.exec(microBrandsHighlight);

	if (!match) return specialOfferExists;

	const closingDate = match[1];
	const offerStillValid = new Date(closingDate).getTime() > Date.now();
	return offerStillValid && specialOfferExists;
};

export const getHohoDescriptors = (microBrandsHighlight: string) => {
	const operatingHoursRegex =
		/operating hours DoNotTranslate\s*([\d\w\s\-:]+)\s*/;
	const frequencyRegex = /frequency DoNotTranslate\s*([\d\w\s\-]+)\s*/;
	const startingStop = /starting stop DoNotTranslate\s*([\d\w\s\-,\.]+)\s*/;

	const operatingHoursMatch = operatingHoursRegex.exec(microBrandsHighlight);
	const frequencyMatch = frequencyRegex.exec(microBrandsHighlight);
	const startingStopMatch = startingStop.exec(microBrandsHighlight);

	const operatingHours = operatingHoursMatch
		? operatingHoursMatch[1]?.trim()
		: null;
	const frequency = frequencyMatch ? frequencyMatch[1]?.trim() : null;
	const startStop = startingStopMatch ? startingStopMatch[1]?.trim() : null;

	return {
		frequency,
		operatingHours,
		startStop,
	};
};

export const getBookPageInstructions = (product: any) =>
	product?.selectPageInstructions;

export const getBreadcrumbsForProductPage = ({
	product,
	city,
	lang,
	showCategory,
	showSubCategory,
	serverStrings,
}: {
	product: any;
	city: any;
	lang: any;
	showCategory: boolean;
	showSubCategory: boolean;
	serverStrings?: TStaticStringLabels;
}) => {
	if (serverStrings)
		strings.setContent({
			default: serverStrings,
		});
	const apiLang = getApiString(lang);
	const {
		displayName: cityDisplayName,
		cityCode,
		urlSlugs: cityUrlSlugs,
	} = city;
	const { primaryCategory, primarySubCategory, collectionsFromRoot } =
		product;
	const linkUrl = (collection: any) =>
		`${getBaseUrl()}${collection?.urlSlugs?.[apiLang]}`;

	let linkTexts = [strings.BREADCRUMB_HOME, cityDisplayName],
		linkTitles = [
			strings.BREADCRUMB_HOME,
			strings.formatString(
				strings.THINGS_TO_DO_IN_CITY,
				cityDisplayName,
			) as string,
		],
		linkUrls = [
			getGlobalPageUrlAsPath(lang),
			getCityUrlAsPath({ urlSlugs: cityUrlSlugs, lang }),
		],
		linkTypes = ['Home Page', 'City Page'];

	if (primaryCategory && showCategory) {
		if (primaryCategory?.displayName === TICKETS_CATEGORY) {
			linkTexts = linkTexts.concat([strings.PLACES_TO_VISIT.NAME]);
			linkTitles = linkTitles.concat([
				strings.formatString(
					strings.PLACES_TO_VISIT.TITLE,
					cityDisplayName,
				) as string,
			]);
			linkUrls = linkUrls.concat([
				getAllPlacesToVisitURL({ lang, cityCode }),
			]);
			linkTypes.push('Attractions Page');
		}

		linkTexts = linkTexts.concat([primaryCategory?.displayName]);
		linkTitles = linkTitles.concat([primaryCategory?.heading]);
		linkUrls = linkUrls.concat([linkUrl(primaryCategory)]);
		linkTypes = linkTypes.concat(['Category Page']);

		if (showSubCategory) {
			linkTexts = linkTexts.concat([primarySubCategory?.displayName]);
			linkTitles = linkTitles.concat([primarySubCategory?.heading]);
			linkUrls = linkUrls.concat([linkUrl(primarySubCategory)]);
			linkTypes = linkTypes.concat(['Sub-Category Page']);
		}
	}

	if (collectionsFromRoot.length > 0) {
		linkTexts = linkTexts.concat(
			collectionsFromRoot.map(
				(collection: any) => collection?.displayName,
			),
		);
		linkTitles = linkTitles.concat(
			collectionsFromRoot.map(
				(collection: any) => collection?.displayName,
			),
		);
		linkUrls = linkUrls.concat(
			collectionsFromRoot.map((collection: any) => {
				if (!collection.active) return '';

				const collectionLinkUrl = linkUrl(collection);
				const isSecondaryCity = collection?.cityCode !== cityCode;

				if (isSecondaryCity) {
					const linkUrlWithSecondaryCity = new URL(collectionLinkUrl);
					linkUrlWithSecondaryCity.searchParams.set('city', cityCode);

					return linkUrlWithSecondaryCity.toString();
				}

				return collectionLinkUrl;
			}),
		);
		linkTypes = linkTypes.concat(
			collectionsFromRoot.map(() => 'Collection Page'),
		);
	}

	return { linkUrls, linkTexts, linkTitles, linkTypes };
};

export const getCancellationPolicyString = ({
	cancellationPolicy = {},
	reschedulePolicy = {},
	ticketValidity = {},
	isBookingFlow = false,
	// @ts-expect-error TS(7031): Binding element 'booking' implicitly has an 'any' ... Remove this comment to see the full error message
	booking,
	// @ts-expect-error TS(7031): Binding element 'bookingId' implicitly has an 'any... Remove this comment to see the full error message
	bookingId,
}) => {
	// @ts-expect-error TS(2339): Property 'cancellable' does not exist on type '{}'... Remove this comment to see the full error message
	const { cancellable, cancellableUpTo: cancellableUptoMinutes } =
		cancellationPolicy;
	// @ts-expect-error TS(2339): Property 'reschedulable' does not exist on type '{... Remove this comment to see the full error message
	const { reschedulable, reschedulableUpTo: reschedulableUptoMinutes } =
		reschedulePolicy;
	const {
		// @ts-expect-error TS(2339): Property 'ticketValidityType' does not exist on ty... Remove this comment to see the full error message
		ticketValidityType: validityType,
		// @ts-expect-error TS(2339): Property 'ticketValidityUntilDate' does not exist ... Remove this comment to see the full error message
		ticketValidityUntilDate: validUptoDate,
		// @ts-expect-error TS(2339): Property 'ticketValidityUntilDaysFromPurchase' doe... Remove this comment to see the full error message
		ticketValidityUntilDaysFromPurchase: validUptoDays,
	} = ticketValidity;

	const isValidUptoMonths = validUptoDays >= 60; // show validity in months if n(months) >= 2
	const validUptoMonths = isValidUptoMonths
		? // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
		  parseInt(validUptoDays / 30)
		: 0;
	const cancellableUptoHours = getDurationInHours(cancellableUptoMinutes);
	const reschedulableUptoHours = getDurationInHours(reschedulableUptoMinutes);
	const formattedValidUptoDate = isDateValid(validUptoDate)
		? dayjs(validUptoDate).format('D MMM, YYYY')
		: null;

	let amendableTime, amendableDate;
	if (booking) {
		const isCombos = bookingId && booking?.comboSelections;
		const { selectedDate, selectedTime } = booking;
		let selectedTourDate, selectedTourTime;

		// Select the bookingId with the strictest policy for combos for showing date & time
		if (isCombos) {
			// @ts-expect-error TS(2339): Property 'selectedTourDate' does not exist on type... Remove this comment to see the full error message
			({ selectedTourDate, selectedTourTime } =
				Object.values(booking?.comboSelections).filter(
					booking => (booking as any).bookingId === Number(bookingId),
				)?.[0] ?? {});
		}

		const { time, date, month, year } = getCancellationDayAndTime({
			selectedDate: isCombos ? selectedTourDate : selectedDate,
			selectedTime: isCombos ? selectedTourTime : selectedTime,
			durationInHours: cancellable
				? cancellableUptoHours
				: reschedulableUptoHours,
		});
		amendableTime = time;
		amendableDate = dayjs(
			`${date} ${month}, ${year}`,
			'D MMMM, YYYY',
		).format('MMM DD, YYYY');
	}

	if (!cancellable && !reschedulable) {
		switch (validityType) {
			case VALIDITY_TYPES.UNTIL_DATE:
				return strings.formatString(
					strings.CANCELLATION_POLICY.VALID_UNTIL_DATE,
					formattedValidUptoDate,
				);
			case VALIDITY_TYPES.UNTIL_DAYS_FROM_PURCHASE:
				return isValidUptoMonths
					? strings.formatString(
							strings.CANCELLATION_POLICY
								.VALID_WITHIN_NEXT_MONTHS,
							validUptoMonths,
					  )
					: strings.formatString(
							strings.CANCELLATION_POLICY.VALID_WITHIN_NEXT_DAYS,
							validUptoDays,
					  );
			case VALIDITY_TYPES.EXTENDABLE_BUT_UNKNOWN:
				return strings.CANCELLATION_POLICY
					.EXTENDED_BUT_UNKNOWN_VALIDITY;
			default:
				return strings.CANCELLATION_POLICY
					.NON_CANCELLABLE_NON_RESCHEDULABLE;
		}
	} else if (!cancellable && reschedulable) {
		return !isBookingFlow
			? strings.formatString(
					strings.CANCELLATION_POLICY
						.NON_CANCELLABLE_BUT_RESCHEDULABLE,
					reschedulableUptoHours,
			  )
			: strings.formatString(
					strings.CANCELLATION_POLICY
						.NON_CANCELLABLE_BUT_RESCHEDULABLE_BEFORE_TIME,
					amendableTime,
					amendableDate,
			  );
	} else {
		let preBookingCancellationString;
		if (cancellableUptoHours === 0)
			preBookingCancellationString =
				strings.FREE_CANCELLATION_BANNER.CANCELLATION_ANYTIME;
		else if (cancellableUptoHours < 72)
			preBookingCancellationString = strings.formatString(
				strings.CANCELLATION_POLICY.CANCELLABLE_HOURS,
				cancellableUptoHours,
			);
		else
			preBookingCancellationString = strings.formatString(
				strings.CANCELLATION_POLICY.CANCELLABLE_DAYS,
				getDurationInDays(cancellableUptoMinutes),
			);
		return !isBookingFlow
			? preBookingCancellationString
			: strings.formatString(
					strings.CANCELLATION_POLICY.CANCELLABLE_UNTIL_TIME,
					amendableTime,
					amendableDate,
			  );
	}
};

export const getCancellationPolicyContent = ({
	// @ts-expect-error TS(7031): Binding element 'cancellationPolicy' implicitly ha... Remove this comment to see the full error message
	cancellationPolicy,
	// @ts-expect-error TS(7031): Binding element 'reschedulePolicy' implicitly has ... Remove this comment to see the full error message
	reschedulePolicy,
	// @ts-expect-error TS(7031): Binding element 'ticketValidity' implicitly has an... Remove this comment to see the full error message
	ticketValidity,
	isBookingFlow = false,
	booking = {},
	bookingId = null,
}) => `
	<h2>${strings.CANCELLATION_POLICY_HEADING}</h2>
	<p>${getCancellationPolicyString({
		cancellationPolicy,
		reschedulePolicy,
		ticketValidity,
		isBookingFlow,
		booking,
		bookingId,
	})}</p>`;

export const getTicketValidityString = ({ ticketValidity = {} }) => {
	const {
		// @ts-expect-error TS(2339): Property 'ticketValidityType' does not exist on ty... Remove this comment to see the full error message
		ticketValidityType: validityType,
		// @ts-expect-error TS(2339): Property 'ticketValidityUntilDate' does not exist ... Remove this comment to see the full error message
		ticketValidityUntilDate: validUptoDate,
		// @ts-expect-error TS(2339): Property 'ticketValidityUntilDaysFromPurchase' doe... Remove this comment to see the full error message
		ticketValidityUntilDaysFromPurchase: validUptoDays,
	} = ticketValidity;

	if (!validityType || validityType === VALIDITY_TYPES.NOT_EXTENDABLE)
		return '';

	const isValidUptoMonths = validUptoDays >= 60; // Show validity in months if n(months) >= 2
	const validUptoMonths = isValidUptoMonths
		? // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
		  parseInt(validUptoDays / 30)
		: 0;
	const formattedValidUptoDate = isDateValid(validUptoDate)
		? dayjs(validUptoDate).format('D MMM, YYYY')
		: null;

	switch (validityType) {
		case VALIDITY_TYPES.UNTIL_DATE:
			return strings.formatString(
				strings.VALIDITY.UNTIL_DATE,
				formattedValidUptoDate,
			);
		case VALIDITY_TYPES.UNTIL_DAYS_FROM_PURCHASE:
			return isValidUptoMonths
				? strings.formatString(
						strings.VALIDITY.UNTIL_MONTHS_FROM_PURCHASE,
						validUptoMonths,
				  )
				: strings.formatString(
						strings.VALIDITY.UNTIL_DAYS_FROM_PURCHASE,
						validUptoDays,
				  );
		default:
			return strings.VALIDITY.EXTENDED_BUT_UNKNOWN_VALIDITY;
	}
};

export const getTicketValidityContent = ({ ticketValidity = {} }) => {
	// @ts-expect-error TS(2339): Property 'ticketValidityType' does not exist on ty... Remove this comment to see the full error message
	const { ticketValidityType: validityType } = ticketValidity;

	if (!validityType || validityType === VALIDITY_TYPES.NOT_EXTENDABLE)
		return null;

	return `
	<h2>${strings.VALIDITY.HEADING}</h2>
	<p>${getTicketValidityString({ ticketValidity })}</p>`;
};

export const getFormattedValidUntilDate = ({
	ticketValidity,
	creationDateTime,
}: any) => {
	const {
		ticketValidityType: validityType,
		ticketValidityUntilDate: validUptoDate,
		ticketValidityUntilDaysFromPurchase,
	} = ticketValidity;
	const validUptoDays = ticketValidityUntilDaysFromPurchase ?? 0;

	return validityType === VALIDITY_TYPES.UNTIL_DATE
		? dayjs(validUptoDate).format('D MMM, YYYY')
		: dayjs(addDay(creationDateTime, validUptoDays)).format('D MMM, YYYY');
};

export const getOpenDatedValidity = ({
	checkoutMetadata,
	selectedTourId,
	experienceTimeZone,
}: any) => {
	const ticketValidity = getTicketValidityByTourId({
		checkoutMetadata,
		tourId: selectedTourId,
	});
	const { ticketValidityType: validityType } = ticketValidity;
	if (
		validityType === VALIDITY_TYPES.UNTIL_DATE ||
		validityType === VALIDITY_TYPES.UNTIL_DAYS_FROM_PURCHASE
	)
		return strings.formatString(
			strings.VALIDITY.VALID_UNTIL,
			getFormattedValidUntilDate({
				ticketValidity,
				creationDateTime: getCurrentDateInTimezone(experienceTimeZone),
			}),
		);
	else return '';
};

export const getNextAvailableDateInHumanReadableFormat = ({
	nextAvailableDate,
	lang,
}: any) => {
	const today = dayjs().format('YYYY-MM-DD');
	const tomorrow = dayjs().add(1, 'days').format('YYYY-MM-DD');

	switch (true) {
		case nextAvailableDate === today:
			return strings.VPDB_TODAY;
		case nextAvailableDate === tomorrow:
			return strings.VPDB_TOM;
		default:
			return getDayJSLocaleAndHumanReadableDate(nextAvailableDate, lang);
	}
};

export const getProductBoosters = ({
	productCard,
	productCityDisplayName,
	pageType,
}: {
	productCard: any;
	/**
	 * Might be undefined in cases where the product's city is not avaialbe in citiesMap.
	 */
	productCityDisplayName: string;
	pageType: string;
}): { l1Booster: string; l2Booster: string } => {
	const {
		primaryCollection,
		primarySubCategory,
		cancellationPolicy,
		primaryCategory,
		combo,
		listingAvailability,
	} = productCard;

	const primaryCollectionDisplayName =
		primaryCollection?.['displayName'] || '';
	const primarySubCategoryDisplayName =
		primarySubCategory?.['displayName'] || '';
	const primaryCategoryDisplayName = primaryCategory?.['displayName'] || '';
	const paxAvailabilityOnNextDate =
		listingAvailability?.['nextAvailableDate']?.['availability'];
	const numPaxAvailabilityOnNextDate =
		listingAvailability?.['nextAvailableDate']?.['remaining'];
	const hasFreeCancellation = cancellationPolicy?.['cancellable'] || false;
	const hasSellingOutFastBooster =
		paxAvailabilityOnNextDate === 'LIMITED' &&
		numPaxAvailabilityOnNextDate < 9 &&
		numPaxAvailabilityOnNextDate > 0;

	const availableBoostersInfo = productCardBoosterOrder({
		hasSellingOutFastBooster,
		hasFreeCancellation,
	});
	let l1Booster = availableBoostersInfo?.[0],
		l2Booster;
	availableBoostersInfo.shift();
	switch (pageType) {
		case PAGE_TYPE.HOME:
		case PAGE_TYPE.SEARCH_LIST:
			l2Booster = productCityDisplayName;
			break;
		case PAGE_TYPE.CITY:
			l2Booster = primaryCollectionDisplayName;
			if (!l2Booster) {
				l2Booster = primarySubCategoryDisplayName;
			}
			if (!l2Booster) {
				if (availableBoostersInfo.length > 0)
					l2Booster = availableBoostersInfo[0];
				else {
					l2Booster = l1Booster;
					l1Booster = '';
				}
			}
			break;
		case PAGE_TYPE.SUB_CATEGORY:
			l2Booster = primaryCollectionDisplayName;
			if (!l2Booster) {
				l2Booster = primaryCategoryDisplayName;
			}
			if (!l2Booster) {
				if (availableBoostersInfo.length > 0)
					l2Booster = availableBoostersInfo[0];
				else {
					l2Booster = l1Booster;
					l1Booster = '';
				}
			}
			break;
		case PAGE_TYPE.CATEGORY:
			l2Booster = primaryCollectionDisplayName;
			if (!l2Booster) {
				l2Booster = primarySubCategoryDisplayName;
			}
			break;
		default:
			l2Booster = primarySubCategoryDisplayName;
			break;
	}
	if (combo && pageType !== PAGE_TYPE.SUB_CATEGORY) {
		l2Booster = strings.BOOSTERS.COMBO;
	}

	return {
		l1Booster,
		l2Booster,
	};
};

export const getFirstAvailableTour = ({
	pricing,
	selectedDate,
}: {
	pricing: any;
	selectedDate: string;
}) => {
	if (!selectedDate) return null;

	const { inventoryMap, tourMap } = pricing;
	const inventoryByDate = inventoryMap?.[selectedDate];

	if (!inventoryByDate) return null;

	for (const tourId in inventoryByDate) {
		const inv = inventoryByDate[tourId];

		if (inv?.length > 0) {
			const tour = tourMap?.[tourId]?.[0];
			const firstSlot = inv[0];

			return {
				...tour,
				id: tourId,
				isAvailable: true,
				scheduleInventory: firstSlot,
			};
		}
	}

	return null;
};

export const getUniqueRandomOutputs = ({
	numArraySize = 0,
	outputCount,
	sampleArray,
}: {
	numArraySize?: number;
	outputCount: number;
	sampleArray?: any[];
}) => {
	if (
		sampleArray
			? outputCount > sampleArray.length
			: outputCount > numArraySize
	)
		return;
	const numbers =
		sampleArray ?? Array.from({ length: numArraySize + 1 }, (_, i) => i);
	const selectedOutputs = [];
	while (selectedOutputs.length < outputCount) {
		const randomIndex = Math.floor(Math.random() * numbers.length);
		const selectedOutput = numbers.splice(randomIndex, 1)[0];
		selectedOutputs.push(selectedOutput);
	}
	return selectedOutputs;
};

export const shouldUseSuffixWithHypen = (primaryCollectionId: number) => {
	return (
		primaryCollectionId !== LTD_COLLECTION_ID &&
		primaryCollectionId !== BROADWAY_COLLECTION_ID
	);
};

export const getTgidBilink = (state: any, location: any) => {
	const byProductId = state?.biLinkStore?.byProductId ?? {};
	const currentProductId = getProductIdFromLocation(location);

	const { bi, startTime, endTime } = byProductId[currentProductId] ?? {};
	const isActive = checkIfDateInBetweenDateRange({
		date: dayjs().toString(),
		startDate: startTime,
		endDate: endTime,
	});
	if (!isActive) return null;
	else return bi;
};

export const getOperatingHoursInfo = (
	poiName: string,
	operatingSchedules: {
		startDate: string;
		endDate: string;
		scheduleName: string;
		operatingDaySchedules: TOperatingDaySchedule[];
	}[],
	experienceTimezone: string,
): any => {
	const currentExperienceDateTime = dayjs().tz(experienceTimezone);
	const dateFormat = 'YYYY-MM-DD';
	const availableOperatingSchedule = operatingSchedules.find(
		operatingSchedule => {
			const {
				startDate: scheduleStartDate,
				endDate: scheduleEndDate,
				operatingDaySchedules,
			} = operatingSchedule;

			// Add 23 hours and 59 minutes to the dates since they start at 12:00am.
			const startDate = dayjs(scheduleStartDate).add(1439, 'minutes');
			const endDate = dayjs(scheduleEndDate).add(1439, 'minutes');

			return (
				currentExperienceDateTime.isBetween(
					startDate,
					endDate,
					'day',
					'[]',
				) &&
				Object.values(operatingDaySchedules).some(
					daySchedule => !daySchedule.closed,
				)
			);
		},
	);

	if (!availableOperatingSchedule) {
		// Find the next available operating schedule.
		const nextAvailableOperatingSchedule = operatingSchedules.find(
			operatingSchedule => {
				const { startDate: scheduleStartDate, operatingDaySchedules } =
					operatingSchedule;

				const startDate =
					dayjs(scheduleStartDate).tz(experienceTimezone);

				return (
					currentExperienceDateTime.isBefore(startDate) &&
					Object.values(operatingDaySchedules).some(
						daySchedule => !daySchedule.closed,
					)
				);
			},
		);

		if (!nextAvailableOperatingSchedule) {
			return {
				isOpen: false,
				subtext: null,
				label: strings.CLOSED,
				poiName,
			};
		}

		const { startDate, operatingDaySchedules } =
			nextAvailableOperatingSchedule;

		let nextAvailableStartDate = dayjs(startDate);
		let nextAvailableDaySchedule: TOperatingDaySchedule | null = null;
		let dayIteration = 0;

		while (!nextAvailableDaySchedule && dayIteration < 7) {
			const currentDaySchedule = operatingDaySchedules.find(
				daySchedule =>
					daySchedule.dayOfWeek ===
					nextAvailableStartDate
						.locale('en')
						.format('dddd')
						.toUpperCase(),
			)!;

			if (currentDaySchedule.closed) {
				nextAvailableStartDate = nextAvailableStartDate.add(1, 'day');
				dayIteration++;
				continue;
			}

			nextAvailableDaySchedule = currentDaySchedule;
		}

		const nextAvailableOperatingHoursInfo = {
			isOpen: false,
			subtext:
				!!nextAvailableDaySchedule?.openingTime &&
				!!nextAvailableDaySchedule?.closingTime &&
				`${getHumanReadableTime(
					nextAvailableDaySchedule.openingTime,
				)} - ${getHumanReadableTime(
					nextAvailableDaySchedule.closingTime,
				)}`,
			label: strings.formatString(
				strings.PPD_OPENS_ON,
				nextAvailableStartDate.format('D MMM YYYY'),
			),
			poiName,
			operatingDaySchedules,
			nextAvailableStartDate: nextAvailableStartDate.format(),
		};

		return nextAvailableOperatingHoursInfo;
	}

	const { operatingDaySchedules, endDate: availableScheduleEndDate } =
		availableOperatingSchedule;

	const currentDaySchedule = operatingDaySchedules.find(
		({ dayOfWeek }) =>
			dayOfWeek ===
			currentExperienceDateTime.locale('en').format('dddd').toUpperCase(),
	);

	const closingDateTimeFormat = currentDaySchedule?.closingTime
		? `${currentExperienceDateTime.format(dateFormat)} ${
				currentDaySchedule.closingTime
		  }`
		: currentExperienceDateTime.format(dateFormat);

	const openingDateTimeFormat = currentDaySchedule?.openingTime
		? `${currentExperienceDateTime.format(dateFormat)} ${
				currentDaySchedule.openingTime
		  }`
		: currentExperienceDateTime.format(dateFormat);

	const openingDateTime = dayjs.tz(openingDateTimeFormat, experienceTimezone);
	const closingDateTime = dayjs.tz(closingDateTimeFormat, experienceTimezone);

	const operatingHoursInfo = {
		poiName,
		isOpen: true,
		label: strings.PPD_OPEN_TODAY,
		subtext:
			!!currentDaySchedule?.openingTime &&
			!!currentDaySchedule?.closingTime &&
			`${getHumanReadableTime(
				currentDaySchedule.openingTime,
			)} - ${getHumanReadableTime(currentDaySchedule.closingTime)}`,
		operatingDaySchedules,
	};

	if (
		!currentDaySchedule ||
		currentDaySchedule?.closed ||
		(!closingDateTime.isBefore(openingDateTime) &&
			currentExperienceDateTime.isAfter(closingDateTime))
	) {
		// If it's the final day of the current operating schedule, get the next available date.
		if (
			currentExperienceDateTime.isSameOrAfter(
				availableScheduleEndDate,
				'day',
			)
		) {
			// Recursively call function with updated schedule.
			const updatedOperatingSchedules = operatingSchedules.filter(
				({ endDate }) => endDate !== availableScheduleEndDate,
			);

			return getOperatingHoursInfo(
				poiName,
				updatedOperatingSchedules,
				experienceTimezone,
			);
		}

		let nextAvailableOperatingDay = null;
		let daysUntilNextAvailableDay = 1;

		while (!nextAvailableOperatingDay && daysUntilNextAvailableDay <= 7) {
			const currentOperatingDaySchedule = operatingDaySchedules.find(
				({ dayOfWeek }) =>
					dayOfWeek ===
					currentExperienceDateTime
						.add(daysUntilNextAvailableDay, 'day')
						.locale('en')
						.format('dddd')
						.toUpperCase(),
			);

			if (!currentOperatingDaySchedule!.closed) {
				nextAvailableOperatingDay = currentOperatingDaySchedule;
				break;
			}

			daysUntilNextAvailableDay += 1;
		}

		operatingHoursInfo.operatingDaySchedules = operatingDaySchedules;
		operatingHoursInfo.subtext =
			!!nextAvailableOperatingDay?.openingTime &&
			!!nextAvailableOperatingDay?.closingTime &&
			`${getHumanReadableTime(
				nextAvailableOperatingDay.openingTime,
			)} - ${getHumanReadableTime(
				nextAvailableOperatingDay.closingTime,
			)}`;

		if (daysUntilNextAvailableDay === 1) {
			const updatedOperatingHoursInfo = {
				...operatingHoursInfo,
				isOpen: false,
				label: strings.PPD_OPENS_TOMORROW,
			};

			return updatedOperatingHoursInfo;
		} else if (
			daysUntilNextAvailableDay > 1 &&
			daysUntilNextAvailableDay <= 7
		) {
			const nextAvailableStartDate = currentExperienceDateTime
				.add(daysUntilNextAvailableDay, 'days')
				.format('dddd');

			const updatedOperatingHoursInfo = {
				...operatingHoursInfo,
				isOpen: false,
				label: strings.formatString(
					strings.PPD_OPENS_ON,
					nextAvailableStartDate,
				),
				nextAvailableStartDate: currentExperienceDateTime
					.add(daysUntilNextAvailableDay, 'days')
					.format(),
			};

			return updatedOperatingHoursInfo;
		} else {
			const updatedOperatingHoursInfo = {
				...operatingHoursInfo,
				isOpen: false,
				label: strings.CLOSED,
			};

			return updatedOperatingHoursInfo;
		}
	}

	return operatingHoursInfo;
};

export const shouldShowEntitiesInBreadcrumbs = ({
	product,
	categoriesAndSubCategoriesInfo,
}: {
	product: any;
	categoriesAndSubCategoriesInfo: any;
}) => {
	const { primaryCategory, primarySubCategory } = product;

	let showCategory = false,
		showSubCategory = false;

	categoriesAndSubCategoriesInfo.forEach(
		(entity: { id: number; isCategory: boolean }) => {
			const { id, isCategory } = entity;
			if (isCategory && id === primaryCategory?.id) {
				showCategory = true;
			} else if (id === primarySubCategory?.id) {
				showSubCategory = true;
			}
		},
	);

	return {
		showCategory,
		showSubCategory,
	};
};

export const getThumbnailFromYTVideoUrl = (videoUrl: string) => {
	// video url format: 'https://youtu.be/<video-id>?someQuery'
	const videoId = new URL(videoUrl).pathname.split('/').pop();
	return `https://i.ytimg.com/vi/${videoId}/mqdefault.jpg`;
};

export const isLttCollectionId = (product: any) =>
	getPrimaryCollectionId(product) === LTD_COLLECTION_ID;

export const isHopOnHopOffRevampEligible = (product: any) =>
	getPrimarySubCategoryId(product) === 1011 ||
	getPrimarySubCategoryId(product) === 1009;

export const getProductFeedSortKey = (
	sortType: string,
	ids: (string | number)[] = [],
) => {
	return `pf-${sortType}-${generateFilterKey(...[...ids].sort()) || 'ALL'}`;
};

export const fetchBulkProducts = async ({
	tgids,
	lang,
	currencyCode,
	state,
}: {
	tgids: (number | string)[];
	lang: string;
	currencyCode: string;
	state: any;
}) => {
	if (tgids.length < 1) return {};
	const hostName = getApiCDNBaseUrlV2({ state });
	const currencyParam = getApiCurrencyParameter(state, currencyCode);
	const langParam = getApiLanguageParameter(lang, true);
	const fetchPromises = tgids.map(tgid =>
		fetch(
			`${hostName}${ENDPOINTS.PRODUCT}${tgid}/?${langParam}${currencyParam}`,
		),
	);
	const productResponses = await Promise.all(fetchPromises);
	const productsData = await Promise.all(
		productResponses.map(response => response.json()),
	);
	const productsObject = productsData.reduce((acc, curr) => {
		return { ...acc, [curr.id]: curr };
	}, {});
	return productsObject;
};

export const isGuidedTourFlow = (product: any) =>
	product?.flowType === ANALYTICS_FLOW_TYPE.GUIDED_TOUR_PROPERTY_SELECTION;

export const isFilterPropertiesFlow = (product: any) =>
	product?.flowType === ANALYTICS_FLOW_TYPE.PROPERTY_SELECTION;

export const getPropertyTypeLocalisation = ({
	product,
	propertyTypeLabel,
}: {
	product: any;
	propertyTypeLabel: string;
}) => product?.propertyTypes?.[propertyTypeLabel];

export const getPropertyValueLocalisation = ({
	product,
	propertyValueLabel,
}: {
	product: any;
	propertyValueLabel: string;
}) => product?.propertyValues?.[propertyValueLabel];

export const getCityParamForProductUrl = ({
	currentCityCode,
	primaryCityCode,
}: {
	currentCityCode: string;
	primaryCityCode?: string;
}) => {
	return currentCityCode !== primaryCityCode &&
		currentCityCode !== GLOBAL_CITY_CODE
		? currentCityCode
		: null;
};

export const shouldExcludeHolidayTheming = (primaryCollectionId?: number) => {
	return primaryCollectionId
		? SENSITIVE_COLLECTION_IDS.includes(primaryCollectionId)
		: false;
};
