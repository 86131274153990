import type { Pricing } from 'src/types/pricing';

import type { TProductCardDataV2 } from 'Components/mobile/miniProductCard/types';

import { getProductListDetails } from 'Utils/categoryUtils';
import { getPlaceholderBanner } from 'Utils/cityUtils';
import { generateFilterKey } from 'Utils/gen';
import {
	addQueryParamsToUrl,
	addQueryParamToString,
	getApiCDNBaseUrlV2,
} from 'Utils/urlUtils';

import type { reducers } from 'Reducers/rootReducer';
import type { BannersData } from 'ReduxTypes/banner';
import type { TCategory, TSubCategory } from 'ReduxTypes/categories';
import type { TCity } from 'ReduxTypes/city';
import type { TCollection, TCollectionCardV2 } from 'ReduxTypes/collection';
import type { TReduxState as TPersonaReduxState } from 'ReduxTypes/persona';
import type { TPOIThunkParams, TReduxState } from 'ReduxTypes/poi';
import type { TIsFetching, TReviewStateReviews } from 'ReduxTypes/review';

import type { BANNERS_PLATFORM } from 'Constants/constants';
import {
	BANNER_NON_CITY_KEY,
	FALLBACK_GUEST_COUNT,
	GLOBAL_CITY_CODE,
	SORT_TYPE_RECENTLY_VIEWED,
	UserProfileTypes,
} from 'Constants/constants';

import type { MCartStore, MCrossSellRecommendations } from './crossSell/types';
import { getCollectionListSortKey } from './collectionsUtils';

export const getHost = (state: any) => {
	const host = state?.deviceStore?.host;
	if (host?.includes('localhost')) {
		return 'www.localhost.com';
	}
	if (host?.includes('next-deimos.test-headout')) {
		return 'www.test-headout.com';
	}
	if (host?.includes('next-deimos.headout')) {
		return 'www.headout.com';
	}
	return host;
};

export const isBot = (state: any) => {
	return !!state?.deviceStore?.isBot === true;
};

export const getChannel = (state: any) => {
	return state?.deviceStore?.channel;
};

export const getLazyloadOverrideStatus = (state: any) => {
	return !!state?.appStore?.overrideLazyload === true;
};

export const getDeviceType = (state: any) => {
	return state?.deviceStore?.deviceType;
};

export const getCurrentCityCode = (state: any) => {
	return state?.city?.currentCityCode;
};

export const getCurrentCurrency = (state: any) => {
	return (
		state?.currencies?.currentCurrencyCode ||
		state?.currencies?.locationCurrencyCode
	);
};

export const getGeolocationCurrency = (state: any) => {
	return state?.currencies?.geolocationCurrency;
};

export const getCurrenciesMap = (state: any) => {
	return state?.currencies?.currenciesMap;
};

export const getCurrencyCodesList = (state: any) => {
	return Object.keys(state?.currencies?.currenciesMap ?? {});
};

export const getCurrencyCodesListFromMap = (currenciesMap: any) => {
	return currenciesMap && Object.keys(currenciesMap);
};

export const getCurrentLanguageCode = (state: any) => {
	return state?.languageStore?.code || 'en';
};

export const getLanguages = (state: any) => {
	return state?.languageStore?.languages;
};

export const getCurrentCity = (state: any) => {
	return state?.city?.citiesMap?.[getCurrentCityCode(state)];
};

export const getCitiesMap = (state: any): Record<string, TCity> => {
	return state?.city?.citiesMap;
};

export const getDiscoverableCitiesCount = (state: any) => {
	return state?.city?.discoverableCitiesCount;
};

export const getTotalCitiesCount = (state: any) => {
	return state?.city?.totalCitiesCount;
};

export const getCollectionsCodes = (state: any, urlParams: string) => {
	return state?.collectionsList?.[urlParams]?.collectionIdList;
};

export const getCitiesCodes = (state: any, urlParams: string) => {
	return state?.citiesList?.[urlParams]?.cityIdList;
};

export const getDiscoverableCityCodesByParams = (
	state: any,
	urlParams: string,
) => {
	return state?.citiesList?.[urlParams]?.discoverableCityIdList;
};

export const getNonDiscoverableCityCodesByParams = (
	state: any,
	urlParams: string,
) => {
	return state?.citiesList?.[urlParams]?.nonDiscoverableCityIdList;
};

export const getDiscoverableCityCodes = (state: any) => {
	return state?.city?.discoverableCityCodes;
};

export const getCollectionsList = (state: any) => {
	return state?.collectionsList;
};

export const getCitiesList = (state: any) => {
	return state?.citiesList;
};

export const getCityCodes = (state: any) => {
	return state?.city?.cityCodes;
};

export const getCity = (state: any, cityCode: any) => {
	return state?.city?.citiesMap?.[cityCode];
};

export const getCurrentCountryCode = (state: any) => {
	return state?.city?.citiesMap?.[getCurrentCityCode(state)]?.country?.code;
};

export const getUserCountryCode = (state: any) =>
	state?.deviceStore?.countryCode;

export const getProduct = (state: any, id: any) => {
	return state?.productStore?.byId?.[String(id)];
};

export const getProductCard = (state: any, id: any) => {
	return state?.productStore?.byCardId?.[String(id)];
};
export const getProductCardV2 = (state: any, id: any): TProductCardDataV2 => {
	return state?.productStore?.byCardIdV2?.[String(id)];
};

export const getProductTags = (state: any, id: any, booking: any) => {
	if (!booking) return [];
	const toursInfo = state?.productStore?.byId?.[String(id)]?.tours;
	if (!toursInfo || toursInfo.length === 0) return [];
	const { selectedTourId: tourId } = booking;
	const filteredTourInfo = toursInfo.filter((tour: any) => {
		const { id } = tour;
		return id === tourId;
	})?.[0]?.tags;
	if (filteredTourInfo && filteredTourInfo.length !== 0) {
		return filteredTourInfo;
	}
	return [];
};

export const getSlots = (state: any, productId: any) => {
	return state?.slotsStore?.byProductId?.[String(productId)] || [];
};

export const getPricing = (state: any, productId: any) => {
	return state?.pricingStore?.byProductId?.[String(productId)];
};

export const getInventoryMap = (state: any, productId: any) => {
	return getPricing(state, productId)?.inventoryMap;
};

export const getInventoryMapByTourDate = (state: any, productId: any) => {
	return getPricing(state, productId)?.inventoryMapByTourDate;
};

export const getInventory = (state: any, productId: any) => {
	return getPricing(state, productId)?.availabilities?.[0];
};

export const getBooking = (state: any, id: any) => {
	return state?.bookings?.[String(id)];
};

export const getBookingTgids = (state: any) => {
	if (!state?.bookings) return [];
	return Object.keys(state.bookings);
};

export const getItineraryProductId = (state: any, piid: any) => {
	const bookingObj = state?.bookings;
	const tourGroupIDs = Object.keys(bookingObj);
	let tourGroupId = tourGroupIDs?.[0];
	if (tourGroupIDs.length > 1) {
		tourGroupIDs.forEach(tourGroupID => {
			const bookingInfo = bookingObj[tourGroupID];
			if (bookingInfo?.bookingResponse?.secureId === piid) {
				tourGroupId = tourGroupID;
			}
		});
	}
	return tourGroupId;
};

export const getItineraries = (state: any, id: any) => {
	return state?.itineraryStore?.[id];
};

export const getBanners = ({
	state,
	platform,
}: {
	state: any;
	platform: BANNERS_PLATFORM;
}): BannersData => {
	const platformParam = `platform=${platform}`;
	const placeholderBannerArray = [getPlaceholderBanner(platform)];
	const cityKey = getCurrentCityCode(state) || BANNER_NON_CITY_KEY;
	const {
		bannersList = [],
		isFetching,
	}: {
		bannersList?: BannersData;
		isFetching?: boolean;
	} = state?.banners?.[cityKey]?.[platformParam] || {};

	switch (true) {
		case bannersList?.length > 0:
			return bannersList;
		case isFetching:
			return [];
		default:
			return placeholderBannerArray;
	}
};

/**
 * @param state
 * @returns {Boolean} true if fetching, false o.w
 */
export const getFetchingStatus = (state: any) => {
	return state?.fetchingStatus?.isFetching;
};

/**
 * @param state
 * @returns {string} PAGE_TYPE constant. will be undefined/null until the first page is mounted
 */
export const getCurrentPage = (state: any) => {
	return state?.page?.currentPage;
};

/**
 * @param state
 * @returns {string} PAGE_TYPE constant. will be undefined/null until the second page is mounted
 */
export const getPrevPage = (state: any) => {
	return state?.page?.prevPage;
};

export const getUser = (state: any) => {
	return state?.user?.user;
};

export const getResetPassword = (state: any) => {
	return state?.user?.resetAffiliatePassword;
};

export const isLoginModalOpen = (state: any) => {
	return state?.user?.isLoginModalOpen && state?.user?.isLoginModalOpen;
};

export const getUserLandingUrl = (state: any) => {
	return state?.user?.user?.landingUrl;
};

export const getUserProfileType = (state: any) => {
	if (state?.user?.profileType) {
		return state?.user?.profileType;
	}
	return UserProfileTypes.ANONYMOUS;
};

export const getUserFetchStatus = (state: any) => {
	return state?.user?.isFetchingUser;
};

export const getPromoObject = (state: any, id: any) => {
	return state?.bookings?.[String(id)]?.promoObject;
};

export const getBreakup = (state: any) => {
	return state?.breakupStore?.breakupResponse;
};

export const getBreakupFetchingStatus = (state: any) => {
	return state?.breakupStore?.fetchingBreakup;
};

export const getErrorInfo = (state: any) =>
	state?.serverStatus?.pageStatus?.info;

export const getServerPageStatusCode = (state: any) =>
	state?.serverStatus?.pageStatus?.statusCode;

export const getAPIServerError = (state: any) => state?.serverStatus?.APIStatus;

export const getAllCollectionIds = (state: any) => {
	const currentCity = getCurrentCity(state);
	let currentCityCode = GLOBAL_CITY_CODE;
	if (currentCity) {
		currentCityCode = currentCity?.cityCode;
	}
	return getCollectionCardIdsByCityCode(state, currentCityCode);
};

export const getCollectionsMap = (state: any) => {
	return state?.collectionsStore?.byCardIds;
};

// sortType could be 'Trending' or 'Popularity'
export const getProductIdListFromState = (state: any, sortType: any) => {
	// @ts-expect-error TS(7034): Variable 'trendingProductIdsFromAllCities' implici... Remove this comment to see the full error message
	let trendingProductIdsFromAllCities = [];
	const trendingProductIdsFromAllCitiesDeFlattened = Object.values(
		state?.productList,
	)
		.filter(
			value => (value as any)?.[`sort-type=${sortType}`]?.productIdList,
		)
		.map(x =>
			(x as any)?.[`sort-type=${sortType}`]?.productIdList.slice(0, 2),
		)
		.slice(0, 3);
	trendingProductIdsFromAllCitiesDeFlattened &&
		trendingProductIdsFromAllCitiesDeFlattened.forEach(ids => {
			trendingProductIdsFromAllCities =
				// @ts-expect-error TS(7005): Variable 'trendingProductIdsFromAllCities' implici... Remove this comment to see the full error message
				trendingProductIdsFromAllCities.concat(ids);
		});
	// @ts-expect-error TS(7005): Variable 'trendingProductIdsFromAllCities' implici... Remove this comment to see the full error message
	return trendingProductIdsFromAllCities;
};

export const getProductIdListFromTag = (state: any, tag: any) => {
	return state?.productList?.[GLOBAL_CITY_CODE]?.[`sort-type=${tag}`]
		?.productIdList;
};

export const getProductIdListByCityCode = (
	state: any,
	cityCode: string,
	sortingParam: string,
) => {
	return state?.productList?.[cityCode]?.[sortingParam]?.productIdList;
};

export const getProductIdListByCollectionId = (
	state: any,
	collectionId: string,
	sortingParam: string,
) => {
	return state?.productList?.[collectionId]?.[sortingParam]?.productIdList;
};
export const getProductListDataByCityCode = (
	state: any,
	cityCode: string,
	sortingParam: string,
) => {
	return state?.productList?.[cityCode]?.[sortingParam];
};

export const getSelectedDate = (state: any, id: any) => {
	return state?.bookings?.[String(id)]?.selectedDate;
};

export const getSelectedTime = (state: any, id: any) => {
	return state?.bookings?.[String(id)]?.selectedTime;
};

export const getSelectedTourId = (state: any, id: any) => {
	return state?.bookings?.[String(id)]?.selectedTourId;
};

export const getSelectedVariantId = (state: any, id: any) => {
	return state?.bookings?.[String(id)]?.selectedVariantId;
};

export const getSelectionMap = (state: any, id: any) => {
	return state?.bookings?.[String(id)]?.selectionMap;
};

export const getGroupSize = (state: any, id: any) => {
	return state?.bookings?.[String(id)]?.groupSize;
};

export const getComboSelections = (state: any, id: any) =>
	getBooking(state, id)?.comboSelections;

export const getSelectedTourDate = (state: any, id: any, tourId: any) =>
	getComboSelections(state, id)?.[String(tourId)]?.selectedTourDate;

export const getSelectedTourTime = (state: any, id: any, tourId: any) =>
	getComboSelections(state, id)?.[String(tourId)]?.selectedTourTime;

export const getTourSelectionMap = (state: any, id: any, tourId: any) =>
	getComboSelections(state, id)?.[String(tourId)]?.tourSelectionMap;

export const getTourGroupSize = (state: any, id: any, tourId: any) =>
	getComboSelections(state, id)?.[String(tourId)]?.tourGroupSize;

export const getSupportedPaymentMethods = (state: any) =>
	state?.paymentGatewayStore?.supportedPaymentMethods;

export const getPaymentGatewayCredentials = (state: any) =>
	state?.paymentGatewayStore?.paymentGatewayCredentials;

export const getCustomPaymentFieldValues = (state: any) =>
	state?.paymentGatewayStore?.customPaymentFieldValues;

export const getSearchResults = (state: any, query: any, cityCode: any) => {
	return state?.searchResults?.[query]?.[cityCode || 'WORLD']?.results;
};

export const getFetchingStatusFromSearchResults = (
	state: any,
	query: any,
	isProduct: any,
	cityCode: any,
) => {
	return state?.searchResults?.[query]?.[cityCode || 'WORLD']?.results?.[
		isProduct ? 'products' : 'collections'
	]?.isFetching;
};

export const getUserGeoLocation = (state: any) => {
	return state?.userGeoLocation;
};

export const getSelectedSeatsValidationDetails = (state: any) => {
	return state?.selectedSeatsValidationDetails;
};

export const getReservationKeyFetchingStatus = (
	state: any,
	productId: any,
	variantId: any,
) => {
	return (
		state?.bookings?.[productId]?.reserveSessions?.isFetching?.[
			productId
		]?.[variantId] ?? true
	);
};

export const getPriceFetchingStatus = (state: any, productId: any) => {
	return state?.pricingStore?.status?.isFetching?.[productId];
};

export const getCurrentCityTimeZone = (state: any) => {
	const currentCity = state?.city?.currentCityCode;
	return state?.city?.citiesMap?.[currentCity]?.timeZone;
};

export const getConfigs = (state: any) => state?.domainConfig;

export const getDomainLogoConfig = (state: any) =>
	getConfigs(state)?.logo ?? {};

export const showGoogleLoginButton = (state: any) =>
	getConfigs(state)?.login?.showGoogleLoginButton;

export const showAppleLoginButton = (state: any) =>
	getConfigs(state)?.login?.showAppleLoginButton;

export const shouldEnableApplePay = (state: any) =>
	getConfigs(state)?.payment?.enableApplePay;

export const getDomainConfig = (state: any) => getConfigs(state);

export const getAvailableProductIds = (state: any, productIds: any) => {
	return productIds.filter(
		(id: any) => getProductCard(state, id)?.listingPrice,
	);
};

export const getCloseByCities = (state: any) => {
	return state?.city?.closeByCities;
};

export const getNearByCityCodes = (state: any) => {
	return state?.city?.nearByCityCodes;
};

/**
 *
 * @param {*} state
 * @returns one of the following,
 * 1. empty string if hsid is not ready (i.e hsid ensurer iframe still loading)
 * 2. null in case hsid ensurer timed-out
 * 3. hsid value.
 */
export const getSandboxID = (state: any) => {
	return state?.user?.hsid || '';
};

export const getCollectionById = (state: any, id: any) => {
	return state?.collectionsStore?.byId?.[id];
};

export const getCollectionCardIdsByCityCode = (state: any, cityCode: any) => {
	return state?.collectionsStore?.byCityCode?.[cityCode];
};
export const getCollectionCardIdsByCityCodeV2 = (
	state: any,
	cityCode: any,
	sortType: string,
) => {
	return state?.collectionsList?.byCityCode?.[cityCode]?.[
		getCollectionListSortKey(sortType)
	]?.collectionIdList;
};

export const getCollectionCardIdsByCategoryId = (
	state: any,
	categoryId: any,
) => {
	return state?.collectionsStore?.byCategoryId?.[categoryId];
};

export const getCollectionCardIdsBySubcategoryId = (
	state: any,
	subCategoryId: any,
) => {
	return state?.collectionsStore?.bySubcategoryId?.[subCategoryId];
};

export const getCollectionCardIdsByPersonaId = (
	state: any,
	cityCode: string,
	personaId: number,
) => {
	const key = generateFilterKey(cityCode, personaId);
	return state?.collectionsStore?.byCityCodeAndPersonaId?.[key];
};

export const getCollectionCardById = (state: any, id: any): TCollection => {
	return state?.collectionsStore?.byCardIds?.[String(id)];
};

export const getCollectionCardByIdV2 = (
	state: any,
	id: any,
): TCollectionCardV2 => {
	return state?.collectionsStore?.byCardIdsV2?.[String(id)];
};

export const getCollectionCardsListByCityCodeAndSubCategoryId = (
	state: any,
	cityCode: string,
	subCategoryId: string,
) => {
	return state?.collectionsList?.byCityCodeAndSubCategoryId?.[
		`${cityCode}-${subCategoryId}`
	];
};

export const getDayTripsCollectionCardById = (
	state: any,
	// cityCode: string,
	// subCategoryId: string,
	id: any,
) => {
	return state?.collectionsStore?.byCityCodeAndSubCategoryId?.[id];
};

export const getCategoriesInfoById = (
	state: any,
	id: any,
	cityCode = GLOBAL_CITY_CODE,
): TCategory => {
	return (
		state?.categoriesStore?.categoriesByCityCode?.[cityCode]?.[
			String(id)
		] ?? {}
	);
};

export const getSubCategoriesInfoById = (
	state: any,
	id: any,
	cityCode = GLOBAL_CITY_CODE,
): TSubCategory => {
	return state?.categoriesStore?.subCategoriesByCityCode?.[cityCode]?.[
		String(id)
	];
};

export const getCategoriesInfoByCityCode = (
	state: any,
	cityCode = GLOBAL_CITY_CODE,
) => {
	return state?.categoriesStore?.byCityCode?.[cityCode];
};
export const getSubCategoriesListByCityCode = (
	state: any,
	cityCode = GLOBAL_CITY_CODE,
) => {
	return state?.categoriesStore?.subCategoriesByCityCode?.[cityCode];
};

export const getStarredCategoriesInfoByCityCode = ({ state, cityCode }: any) =>
	cityCode
		? state?.categoriesStore?.starredCategoriesAndSubCategoriesByCityCode?.[
				cityCode
		  ]
		: [];

export const getProductListByCityCode = (
	state: any,
	cityCode = GLOBAL_CITY_CODE,
) => {
	return state?.productList?.[cityCode];
};

export const getProductListByPersona = (
	state: any,
	cityCode: string,
	personaAffinityId: number,
	categories: number[] = [],
) => {
	const key = `pa-${personaAffinityId}-${
		generateFilterKey(...categories.sort()) || 'DEFAULT'
	}`;
	return state?.productList?.[cityCode.toUpperCase()]?.[key];
};

export const getProductListByCategoryIdWithSubCategoryFilter = (
	state: any,
	categoryId: number,
	cityCode: string,
	pageSortingParams: string,
	subCategoryIds: Array<number> = [],
) => {
	let params = pageSortingParams;
	if (!params.includes('categoryId')) {
		params = addQueryParamToString(params, {
			categoryId: categoryId.toString(),
		});
	}

	if (subCategoryIds.length) {
		params = addQueryParamToString(params, {
			subCategoryIds: generateFilterKey(...[...subCategoryIds].sort()),
		});
	}

	return getProductListDetails(
		state?.productList?.[cityCode.toUpperCase()],
		params,
	);
};

export const getProductListBySubCategoryId = (
	state: any,
	subCategoryId: number,
	cityCode: string,
	pageSortingParams: string,
) => {
	let params = pageSortingParams;
	if (!params.includes('subCategoryId')) {
		params = addQueryParamToString(params, {
			subCategoryId: subCategoryId.toString(),
		});
	}

	return getProductListDetails(
		state?.productList?.[cityCode.toUpperCase()],
		params,
	);
};

export const getCategoryIdsByCityCode = (state: any, cityCode: any) => {
	return state?.categoriesStore?.byCityCode?.[cityCode];
};

export const getCategoryFeedSections = (state: any, cityCode: any) => {
	return state?.categoriesStore?.sections?.[cityCode];
};

export const getCityCodeFromCityId = ({ state, cityId }: any) => {
	const cityCodes = state?.city?.cityCodes;
	return cityCodes.find((cityCode: any) => {
		const { id } = getCity(state, cityCode);
		return Number(id) === Number(cityId);
	});
};

export const getCityIdByCityCode = (state: any, cityCode: any) => {
	return state?.city?.citiesMap?.[cityCode]?.id;
};

export const getCalendarInventories = (
	state: any,
	productId: any,
	tourId?: any,
) => {
	return tourId
		? state?.calendarInvsStore?.byTourId?.[`${tourId}`]
		: state?.calendarInvsStore?.byProductId?.[`${productId}`];
};

export const getFirstAvailableDateForAllTours = (
	state: any,
): Record<string, any> => {
	return Object.entries(state?.calendarInvsStore?.byTourId).reduce(
		(acc, [tourId, inventories]) => {
			// @ts-expect-error TS(2339): Property 'sortedInventoryDates' does not exist on ... Remove this comment to see the full error message
			const { sortedInventoryDates } = inventories;
			return {
				...acc,
				[tourId]: sortedInventoryDates?.[0],
			};
		},
		{},
	);
};

export const getProductVariants = ({ state, productId }: any) => {
	return getProduct(state, productId)?.variants;
};

export const getBlogsByCityCode = ({ state, cityCode }: any) => {
	return state?.blogPostsStore?.byCityCode[cityCode] ?? [];
};

export const getBlogsByCategoryId = ({ state, categoryId, city }: any) => {
	return state?.blogPostsStore?.byCategoryId[`${city}-${categoryId}`] ?? [];
};

export const getBlogsBySubCategoryId = ({
	state,
	subCategoryId,
	city,
}: any) => {
	return (
		state?.blogPostsStore?.bySubCategoryId[`${city}-${subCategoryId}`] ?? []
	);
};

export const getBlogsByCollectionId = ({ state, collectionId }: any) => {
	return state?.blogPostsStore?.byCollectionId[collectionId] ?? [];
};

export const getCheckoutMetadataById = ({ state, id }: any) => {
	return state?.checkoutMetadataStore?.byId?.[id] ?? {};
};

export const getVoucherData = (state: any) => {
	return state?.voucherData ?? {};
};

export const getRecentlyViewedProductIds = (state: any, cityCode: string) => {
	return state?.productList?.[cityCode]?.[SORT_TYPE_RECENTLY_VIEWED]
		?.productIdList;
};

export const getActiveModal = (state: any) => {
	return state?.appStore?.activeModal;
};

export const getComboUpsellScreenData = (
	state: any,
	tourGroupId: string,
	tourId: string,
) => state?.bookings?.[tourGroupId]?.comboUpsell?.data?.[tourId] ?? null;

export const isComboDataFetching = (
	state: any,
	tourGroupId: string,
	tourId: string,
) =>
	Boolean(
		state?.bookings?.[tourGroupId]?.comboUpsell?.status?.isFetching?.[
			tourId
		],
	) ?? false;

export const getIsProductFetching = (
	state: any,
	id: number | string | null,
) => {
	if (!id) {
		return false;
	}
	return Boolean(state?.productStore?.status?.isFetching?.byId[id]);
};

export const getTravelerMedia = (state: any, tgid: number) => {
	const key = tgid.toString();
	if (!state || !state.travelerMediaStore) return;
	const isFetching =
		state.travelerMediaStore.status.isFetching?.byTgIdAndFilter[key] ??
		true;
	if (!state.travelerMediaStore.byTgIdAndFilter[key]) return { isFetching };
	const { reviews, total, nextOffset } =
		state.travelerMediaStore.byTgIdAndFilter[key];
	return { reviews, total, nextOffset, isFetching };
};

type TReviewFetchReturn = (TIsFetching & TReviewStateReviews) | undefined;

export const getCityReviews = (
	state: any,
	city: string,
): TReviewFetchReturn => {
	if (!state || !state.reviewStore) return;
	const key = city;
	const { isFetching, byCity } = state.reviewStore;
	const currentFetchingStatus = isFetching?.byCity[key] ?? true;
	if (!byCity[key])
		return { isFetching: !!currentFetchingStatus, total: 0, reviews: [] };
	const { reviews, total, nextOffset } = byCity[key];
	return { reviews, total, nextOffset, isFetching };
};

export const getCollectionReviews = (
	state: any,
	collection: string,
): TReviewFetchReturn => {
	if (!state || !state.reviewStore) return;
	const key = collection;
	const { isFetching, byCollectionId } = state.reviewStore;
	const currentFetchingStatus = isFetching?.byCollectionId[key] ?? true;
	if (!byCollectionId[key])
		return { isFetching: !!currentFetchingStatus, total: 0, reviews: [] };
	const { reviews, total, nextOffset } = byCollectionId[key];
	return { reviews, total, nextOffset, isFetching };
};

export const getPersonas = (state: any, city: string) =>
	(state?.personaStore as TPersonaReduxState)?.[city];

export const getPersonaByCityAndId = (state: any, city: string, id: number) =>
	(state?.personaStore as TPersonaReduxState)?.[city].personaAffinities?.find(
		p => p.id === id,
	);

export const getCategoriesByPersonaId = (
	state: any,
	city: string,
	id: number,
) =>
	(state?.personaStore as TPersonaReduxState)?.[city]?.[id]
		?.availableCategories ?? [];

export const getPersonaReviews = (
	state: any,
	city: string,
	persona: number,
): TReviewFetchReturn => {
	if (!state || !state.reviewStore) return;
	const key = generateFilterKey(persona, city);
	const { isFetching, byPersonaAndCity } = state.reviewStore;
	const currentFetchingStatus = isFetching?.byPersonaAndCity[key] ?? true;
	if (!byPersonaAndCity[key])
		return {
			isFetching: !!currentFetchingStatus,
			total: 0,
			reviews: [],
		};
	const { reviews, total, nextOffset } = byPersonaAndCity[key];
	return { reviews, total, nextOffset, isFetching };
};

export const getCityCategoryReviews = (
	state: any,
	city: string,
	categoryId: number,
): TReviewFetchReturn => {
	if (!state || !state.reviewStore) return;

	const key = generateFilterKey(city, categoryId);

	const { isFetching, byCityAndCategory } = state.reviewStore;

	const currentFetchingStatus = isFetching?.byCityAndCategory[key] ?? true;

	if (!byCityAndCategory[key])
		return { isFetching: !!currentFetchingStatus, total: 0, reviews: [] };

	const { reviews, total, nextOffset } = byCityAndCategory[key];

	return { reviews, total, nextOffset, isFetching };
};

export const getCitySubCategoryReviews = (
	state: any,
	city: string,
	subCategoryId: number,
): TReviewFetchReturn => {
	if (!state || !state.reviewStore) return;

	const key = generateFilterKey(city, subCategoryId);

	const { isFetching, byCityAndSubCategory } = state.reviewStore;

	const currentFetchingStatus = isFetching?.byCityAndSubCategory[key] ?? true;

	if (!byCityAndSubCategory[key])
		return { isFetching: !!currentFetchingStatus, total: 0, reviews: [] };

	const { reviews, total, nextOffset } = byCityAndSubCategory[key];

	return { reviews, total, nextOffset, isFetching };
};

export const getLocalizedContentLanguageCode = (state: any) => {
	const {
		languageStore: { localizedContentLanguageCode },
	} = state;

	return localizedContentLanguageCode;
};

export const getPoiInfoById = (state: { poiStore?: TReduxState }, id: number) =>
	state.poiStore?.byId?.[id];

/**
 * @param state redux state
 * @param query either one of tgid or collectionID is required
 * @returns poi info for sent query
 */
export const getPoiInfo = (
	state: {
		poiStore?: TReduxState;
	},
	{
		tgid,
		collectionID,
	}: {
		tgid?: number;
		collectionID?: number;
	},
) => {
	switch (true) {
		case !!tgid:
			return state.poiStore?.byTgId?.[tgid!]?.map(
				poiId => state.poiStore?.byId?.[poiId],
			);
		case !!collectionID: {
			return state.poiStore?.byCollectionId?.[collectionID!]?.map(
				poiId => state.poiStore?.byId?.[poiId],
			);
		}
		default:
			return [];
	}
};

export const getSeatSvg = (state: any, id: string) =>
	state?.seatSVGs?.[String(id)]?.svg;

export const getSubCategoryFiltersDataByCategoryId = (
	state: any,
	categoryId: number,
	useCollectionFilter?: boolean,
): Array<TSubCategory> => {
	const reduxKey = useCollectionFilter
		? 'subCategoriesFilterDataByCategoryIdWithCollectionFilter'
		: 'subCategoriesFilterDataByCategoryId';

	const { categoriesStore } = state;

	return categoriesStore[reduxKey][categoryId] ?? [];
};

export const getFetchPoiInfoBaseApiUrl = (
	state: any,
	{
		poiId,
		tourgroupId,
		collectionId,
		content,
		location,
		operatingSchedules,
		language,
	}: TPOIThunkParams & { language: string },
) => {
	let baseUrl;
	if (poiId) {
		baseUrl = `${getApiCDNBaseUrlV2(state)}/api/v1/pois/${poiId}`;
	} else if (tourgroupId) {
		baseUrl = `${getApiCDNBaseUrlV2(
			state,
		)}/api/v6/tour-groups/${tourgroupId}/pois`;
	} else if (collectionId) {
		baseUrl = `${getApiCDNBaseUrlV2(
			state,
		)}/api/v1/collection/${collectionId}/pois`;
	}

	return addQueryParamsToUrl(baseUrl, {
		content,
		location,
		operatingSchedules,
		language,
	})!;
};

/**
 * Cart
 */
/**
 *
 * @param state global state
 * @returns cartRecommendation, if available from the cartStore
 */
export const getCartRecommendations = (state: {
	[key: keyof typeof reducers]: any;
}) => {
	const cartRecommendations: MCrossSellRecommendations.ICrossSellRecommendations | null =
		state?.cartStore?.cartRecommendations;

	if (cartRecommendations) {
		return cartRecommendations;
	}

	return null;
};

/**
 * Retrieves relevant cart recommendations from the state based on the tour group ID.
 *
 * @param {Object} state - The application state containing cart recommendations.
 * @param {number} tourGroupId - The tour group ID to match against the stored recommendations.
 * @returns {MCrossSellRecommendations.ICrossSellRecommendations | null} - The relevant cart recommendations or null if they need to be refetched.
 */
export const getRelevantCartRecommendations = (
	state: { [key: keyof typeof reducers]: any },
	tourGroupId: string | number,
) => {
	const cartRecommendations: MCrossSellRecommendations.ICrossSellRecommendations | null =
		state?.cartStore?.cartRecommendations;
	const { tourGroupId: storeTourGroupId } = cartRecommendations ?? {};

	/* if the tgid is different, we need to refetch the recommendations */
	if (String(tourGroupId) === String(storeTourGroupId)) {
		return cartRecommendations;
	}

	return null;
};

/**
 * Retrieves bulk calendar inventories for the specified product IDs from the state.
 *
 * @param {any} state - The application state containing calendar inventories.
 * @param {Array<string | number>} productIds - The array of product IDs for which to retrieve calendar inventories.
 * @returns {Array<any>} - An array of calendar inventories corresponding to the provided product IDs.
 */
export const getBulkCalendarInventoriesByProductIds = (
	state: any,
	productIds: Array<string | number>,
) => {
	if (!productIds) {
		return [];
	}

	const inventories = productIds?.map(id => {
		return getCalendarInventories(state, id);
	});

	return inventories;
};

/**
 * Retrieves the calendar inventories store data from the state.
 *
 * @param {Object} state - The application state containing calendar inventories data.
 * @returns {Object} - The calendar inventories store data, or an empty object if not available.
 */
export const getCalendarInventoriesStoreData = (state: {
	[key: keyof typeof reducers]: any;
}) => {
	return state?.calendarInvsStore?.byProductId ?? {};
};

/**
 * Gets the selected date for a cart item.
 *
 * @param {object} state - The state object containing the reducers.
 * @param {string | number} id - The ID of the cart item.
 * @returns {string | null} The selected date or null if not found.
 */
export const getCartItemSelectedDate = (
	state: {
		[key: keyof typeof reducers]: any;
	},
	id: string | number,
) => {
	return state?.cartStore?.cartSelections?.[String(id)]?.selectedDate ?? null;
};

/**
 * Gets the selected variant ID for a cart item.
 *
 * @param {object} state - The state object containing the reducers.
 * @param {string | number} id - The ID of the cart item.
 * @returns {string | null} The selected variant ID or null if not found.
 */
export const getCartItemSelectedVariant = (
	state: {
		[key: keyof typeof reducers]: any;
	},
	id: string | number,
) => {
	return (
		state?.cartStore?.cartSelections?.[String(id)]?.selectedVariantId ??
		null
	);
};

/**
 * Gets the selected time for a cart item.
 *
 * @param {object} state - The state object containing the reducers.
 * @param {string | number} id - The ID of the cart item.
 * @returns {string | null} The selected time or null if not found.
 */
export const getCartItemSelectedTime = (
	state: {
		[key: keyof typeof reducers]: any;
	},
	id: string | number,
) => {
	return state?.cartStore?.cartSelections?.[String(id)]?.selectedTime ?? null;
};

/**
 * Gets the selected tour ID for a cart item.
 *
 * @param {object} state - The state object containing the reducers.
 * @param {string | number} id - The ID of the cart item.
 * @returns {string | null} The selected tour ID or null if not found.
 */
export const getCartItemSelectedTourId = (
	state: {
		[key: keyof typeof reducers]: any;
	},
	id: string | number,
) => {
	return (
		state?.cartStore?.cartSelections?.[String(id)]?.selectedTourId ?? null
	);
};

/**
 * Gets the booking equivalent for a cart item.
 *
 * @param {object} state - The state object containing the reducers.
 * @param {string | number} id - The ID of the cart item.
 * @returns {MCartStore.ISingularCartSelection | undefined} The cart item selection or undefined if not found.
 */
export const getCartItemBookingEquivalent = (
	state: {
		[key: keyof typeof reducers]: any;
	},
	id: string | number,
) => {
	const cartItemSelection: MCartStore.ISingularCartSelection =
		state?.cartStore?.cartSelections?.[String(id)];
	return cartItemSelection;
};

/**
 * Gets the selection map for a cart item.
 *
 * @param {object} state - The state object containing the reducers.
 * @param {string | number} id - The ID of the cart item.
 * @returns {object | null} The selection map or null if not found.
 */
export const getCartItemSelectionMap = (
	state: {
		[key: keyof typeof reducers]: any;
	},
	id: string | number,
) => {
	return state?.cartStore?.cartSelections?.[String(id)]?.selectionMap ?? null;
};

/**
 * Gets the group size for a cart item.
 *
 * @param {object} state - The state object containing the reducers.
 * @param {string | number} id - The ID of the cart item.
 * @returns {number} The group size or 0 if not found.
 */
export const getCartItemGroupSize = (
	state: {
		[key: keyof typeof reducers]: any;
	},
	id: string | number,
) => {
	return state?.cartStore?.cartSelections?.[String(id)]?.groupSize ?? 0;
};

export const getBookingStore = (state: {
	[key: keyof typeof reducers]: any;
}): object | null => {
	return state?.bookings ?? null;
};

export const getPricingStore = (state: {
	[key: keyof typeof reducers]: any;
}): Record<string, Pricing> | null => {
	return state?.pricingStore?.byProductId ?? null;
};

export const getProductStore = (state: Record<keyof typeof reducers, any>) => {
	return state?.productStore?.byId ?? null;
};

export const getProductFeatureFlags = (
	state: Record<keyof typeof reducers, any>,
	id: string | number,
): Record<string, boolean> => {
	return state?.productStore?.productFeatureFlags?.[String(id)];
};

export const getGuestCount = (state: any) => {
	return state?.domainConfig?.guestCount?.totalServed ?? FALLBACK_GUEST_COUNT;
};
