import { useMemo, useRef, useState } from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';
import {
	Swiper,
	type TSwiperRefActions,
	type TSwiperSlideChangedCbProps,
} from '@headout/espeon/components';

import { ChevronRightSvg } from 'Assets/svg/feedPage/ChevronRightSvg';

import { useAutoPlayingLottieAnimation } from 'Hooks/useAutoPlayingLottieAnimation';
import { generateRandomArray } from 'Utils/gen';

import { HEART_URL } from 'Constants/constants';

import { REVIEWS_DATA } from 'Static/labels/reviewsLabels/english';
import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

import ReviewCard from '../common/globalCustomerReviewCard';
import LH2 from '../common/localizedTags/localizedHeading2';
import { TrackScrollIntoView } from '../common/trackInViewWrapper';

const StyledContainer = styled.div`
	position: relative;
	width: 100%;
	background-color: #130d1a;
	overflow: hidden;
`;

const StyledReviewsGrid = styled.section`
	position: relative;
	display: flex;
	z-index: 1;
	margin-left: calc(50vw - 37.5rem);
	max-height: 100%;
	overflow-x: visible;
`;
const StyledReviewsSection = styled.div`
	position: relative;
	flex: 1;
	overflow: hidden;
	height: 100%;
	padding: 3.75rem 0;
`;
const StyledLeftCol = styled.div`
	display: grid;
	grid-template-rows: 16.25rem 1fr;
	grid-template-columns: 25.625rem;
	grid-template-areas: 'heading' 'controls';

	padding: 3.75rem 0;
	height: 100%;
`;
const StyledBgBlob = styled.div<{ $color: String }>`
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	filter: blur(160px);
	opacity: 0.3;
	z-index: 0;

	background: ${({ $color }) => `
        radial-gradient(
            22.5rem 22.5rem at 50% 15%,
            ${$color} 0%,
			${$color} 50%,
            rgba(255, 255, 255, 0) 50%
        ),
        radial-gradient(
            22.5rem 22.5rem at 85% 70%,
            ${$color} 0%,
			${$color} 50%,
			rgba(255, 255, 255, 0) 50%
		)`};
`;
const StyledGradientEdge = styled.div<{ gradientLocation: 'left' | 'right' }>`
	position: absolute;
	top: 0;
	bottom: 0;
	${props => {
		return props.gradientLocation === 'left' ? 'left: 0;' : 'right: 0;';
	}}
	width: 100px;
	height: 100%;
	z-index: 10;

	background: ${props =>
		props.gradientLocation === 'right'
			? `linear-gradient(270deg, ${colors.GREY['22']} 0%, rgba(34, 34, 34, 0) 100%)`
			: 'linear-gradient(270deg, rgba(19, 13, 26, 0) 0%, #130D1A 100%)'};
`;
const StyledHeadingContainer = styled.div`
	max-width: 24.375rem;
	grid-area: heading;
	align-self: flex-end;
	h2 {
		color: ${colors.WHITE};
		${getFontDetailsByLabel(TYPE_LABELS.DISPLAY_LARGE)};
	}
	svg {
		display: inline-block;
		max-width: 2.625rem;
		margin-left: 0.625rem;
	}
`;
const StyledCarouselControls = styled.div`
	display: flex;
	gap: 2.125rem;
	margin-top: 2.625rem;
	grid-area: controls;

	:first-child {
		margin-right: 0.625rem;
	}
`;
export const StyledArrowButton = styled.button<{ disabled: boolean }>`
	@media (max-width: 768px) {
		display: none;
	}
	width: 3.5rem;
	height: 3.5rem;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	${props => !props.disabled && 'cursor: pointer;'}

	background: #2e1f40;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);

	svg {
		width: 1.5rem;
		height: 1.5rem;
		stroke: ${props =>
			props.disabled ? 'rgba(248, 248, 248, 0.2)' : colors.WHITE};
		transition: all 0.2s ease-in-out 0s;
	}

	&.prev-button {
		right: initial;

		svg {
			transform: translateX(-50%);
			transform: rotate(180deg);
		}
	}

	&:hover {
		&:not(.flat-arrows) {
			box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
		}
	}
`;
const LottieContainer = styled.div`
	display: inline-block;
	max-width: 2.625rem;
	margin-left: 0.625rem;
`;

type Props = {
	onScrollIntoView: (ref: any) => void;
};

const ReviewsSection = ({ onScrollIntoView }: Props) => {
	const slider = useRef<TSwiperRefActions>(null);
	const sliderContainer = useRef(null);
	const lottieContainerRef = useRef<HTMLDivElement | null>(null);
	const [showLeftArrow, setShowLeftArrow] = useState(false);
	const [showRightArrow, setShowRightArrow] = useState(false);
	const shuffledReviews = useMemo(
		() =>
			generateRandomArray(19)
				.slice(10)
				.map(reviewIndex => REVIEWS_DATA[reviewIndex]),
		[],
	);

	useAutoPlayingLottieAnimation({
		ref: lottieContainerRef,
		animationProperties: { loop: true, path: HEART_URL },
	});

	const onSlideChanged = ({
		isLeftArrowEnabled,
		isRightArrowEnabled,
	}: TSwiperSlideChangedCbProps) => {
		setShowLeftArrow(isLeftArrowEnabled);
		setShowRightArrow(isRightArrowEnabled);
	};

	const prevHandler = () => {
		slider?.current?.prevSlide();
	};
	const nextHandler = () => {
		slider?.current?.nextSlide();
	};

	return (
		<TrackScrollIntoView callback={onScrollIntoView}>
			<StyledContainer className='core-section-header-container'>
				<StyledReviewsGrid>
					<StyledLeftCol>
						<StyledHeadingContainer>
							<LH2>
								Millions <br />
								love heading out with us
								<LottieContainer
									ref={lottieContainerRef}
									role='none'
								/>
							</LH2>
						</StyledHeadingContainer>
						<StyledCarouselControls>
							<StyledArrowButton
								onClick={prevHandler}
								disabled={!showLeftArrow}
								className='prev-button'
								aria-label='Previous review'
							>
								<ChevronRightSvg />
							</StyledArrowButton>
							<StyledArrowButton
								onClick={nextHandler}
								disabled={!showRightArrow}
								aria-label='Next review'
							>
								<ChevronRightSvg />
							</StyledArrowButton>
						</StyledCarouselControls>
					</StyledLeftCol>

					<StyledReviewsSection ref={sliderContainer}>
						<Swiper
							slideWidth='404px'
							swiperRef={slider}
							slidesToScrollBy={1}
							onSlideChanged={onSlideChanged}
						>
							{shuffledReviews.map(reviewData => {
								const {
									bookingID,
									experienceImageUrl,
									customerName,
									rating,
									content,
									tourGroupId,
									customerCountry,
									customerCountryCode,
									tourGroupName,
									customerProfile,
									directUrl,
								} = reviewData;

								return (
									<div key={bookingID}>
										<ReviewCard
											isMobile={false}
											experienceName={tourGroupName}
											imageUrl={experienceImageUrl}
											customerProfile={customerProfile}
											customerName={customerName}
											customerCountry={customerCountry}
											customerCountryCode={
												customerCountryCode
											}
											rating={rating}
											content={content}
											tourGroupId={tourGroupId}
											directUrl={directUrl}
										/>
									</div>
								);
							})}
						</Swiper>
						<StyledGradientEdge
							gradientLocation={showRightArrow ? 'right' : 'left'}
						/>
					</StyledReviewsSection>
				</StyledReviewsGrid>
				<StyledBgBlob $color={colors.DARK_BLOB_BG} />
			</StyledContainer>
		</TrackScrollIntoView>
	);
};

export default ReviewsSection;
