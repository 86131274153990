import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import Conditional from 'Components/common/conditional';

import HeadoutBlimpBold from 'Assets/svg/header/HeadoutBlimpBold';
import HeadoutLogoBold from 'Assets/svg/header/HeadoutLogoBold';
import PortalLoaderFilled from 'Assets/svg/header/PortalLoaderFilled';
import PortalLoaderStroked from 'Assets/svg/header/PortalLoaderStroked';

import { getABTestingVariantBySandboxId } from 'Utils/experiments/experimentUtils';
import { isWhitelabel } from 'Utils/hostUtils';
import { getHost, getSandboxID } from 'Utils/stateUtils';

import { HSID_DEFAULT_VALUE } from 'Constants/constants';
import { EXPERIMENT_NAMES, VARIANTS } from 'Constants/experiments';
import { strings } from 'Constants/strings';

import PageLoader from '../pageLoader';
import RenderOneOf from '../renderOneOf';

import { PageLoaderContainer, PageLoaderWrapper } from './styles';

type TDelayedUnmountReturn = {
	shouldRender: boolean;
	isTransitioning: boolean;
	showComponent: () => void;
	hideComponent: () => void;
};
const useDelayedUnmount = (
	delayTime = 500,
	defaultRender = false,
	hideCb?: () => void,
): TDelayedUnmountReturn => {
	const [shouldRender, setShouldRender] = useState(defaultRender);
	const [isTransitioning, setIsTransitioning] = useState(false);

	const timeoutRef = useRef<null | NodeJS.Timeout>();

	useEffect(() => {
		return () => clearTimeout(timeoutRef.current ?? undefined);
	}, []);

	const showComponent = () => {
		setShouldRender(true);
	};

	const hideComponent = () => {
		setIsTransitioning(true);
		timeoutRef.current = setTimeout(() => {
			setShouldRender(false);
			setIsTransitioning(false);
			hideCb?.();
		}, delayTime);
	};

	return { shouldRender, isTransitioning, showComponent, hideComponent };
};

export const BrandLoader = ({
	isLoading,
	children,
	showClosing = false,
}: {
	isLoading: boolean;
	showClosing?: boolean;
	children?: React.ReactNode;
}) => {
	const { hsid, isWhiteLableHost } = useSelector(state => ({
		hsid: getSandboxID(state),
		isWhiteLableHost: isWhitelabel({ host: getHost(state) }),
	}));

	const showHOInLoader = useMemo(() => {
		if (!hsid || hsid === HSID_DEFAULT_VALUE) return false;
		return (
			getABTestingVariantBySandboxId(
				EXPERIMENT_NAMES.BRAND_LOADER_EXP,
				hsid,
				true,
			) === VARIANTS.TREATMENT
		);
	}, [hsid]);

	const {
		shouldRender: shouldRenderLoader,
		isTransitioning: isLoaderTransitioning,
		hideComponent: hideLoaderComponent,
	} = useDelayedUnmount(showClosing ? 500 : 0, isLoading);

	useEffect(() => {
		if (isLoading) return;
		hideLoaderComponent();
	}, [isLoading]);

	if (!showHOInLoader) {
		if (isLoading) return <PageLoader />;
		return children;
	}

	return (
		<>
			<Conditional if={shouldRenderLoader || isLoaderTransitioning}>
				<HoBrandAnimation
					isClosing={
						isLoaderTransitioning && !!children && showClosing
					}
					showPoweredBy={isWhiteLableHost}
				/>
			</Conditional>
			<Conditional if={!isLoading && children}>{children}</Conditional>
		</>
	);
};

export const HoBrandAnimation = ({
	isClosing,
	showPoweredBy = false,
}: {
	isClosing?: boolean;
	showPoweredBy?: boolean;
}) => {
	return (
		<PageLoaderContainer>
			<PageLoaderWrapper>
				<div
					className={`container center ho-brand-animation ${
						isClosing && 'ho-brand-closing-animation'
					}`}
				>
					<div className='ripple-container center'>
						<div className='center-content'>
							<RenderOneOf
								positionalConditions={[
									showPoweredBy,
									!showPoweredBy,
								]}
							>
								<>
									<span className='powered-by'>
										{
											strings.RECOMMENDATIONS_PAGE
												.POWERED_BY
										}
									</span>
									<HeadoutLogoBold className='headout-label-svg' />
								</>
								<HeadoutBlimpBold className='headout-blimp' />
							</RenderOneOf>
						</div>
						<PortalLoaderFilled className='center-svg' />

						{[...Array(14)].map((_, key) => (
							<div
								className='ripple-wrapper'
								data-animation={`${key + 1}`}
								key={key}
							>
								<PortalLoaderStroked />
							</div>
						))}
					</div>
				</div>
			</PageLoaderWrapper>
		</PageLoaderContainer>
	);
};
