import Router from 'next/router';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'loda... Remove this comment to see the full error message
import groupBy from 'lodash.groupby';
import Cookies from 'nookies';

import embedScript from 'Components/common/embedScript';
import { notify } from 'Components/common/notify';

import type { LiveInventoryPropsType } from 'Hooks/useLiveInventorySupport';
import { trackEvent } from 'Utils/analytics';
import {
	getPriceProfile,
	getPricesWithSelections as getPricesWithSelectionsfromBookingUtils,
	getScheduleInfo,
	getSelectedNumberFromMap,
	getTimeSlot,
	getTourSelectionState,
	getUserFieldsArray,
	isDVTBookingFlow,
} from 'Utils/bookingUtils';
import { getLocalisedPrice } from 'Utils/currencyUtils';
import {
	formatDurationToHoursMinutes,
	formatTime,
	formatTimeLocalised,
	formatUsingDayJS,
	getDayName,
	localDateToJsDate,
} from 'Utils/dateUtils';
import dayjs from 'Utils/dayjsUtil';
import {
	flatten,
	fromEntries,
	isNonEmptyObject,
	isSubstring,
	takeOutKeys,
} from 'Utils/gen';
import { removeFromCache } from 'Utils/localStorageUtils';
import {
	getAvailableInventory,
	getDistinctTimes,
	getInventoryWithMinPrice,
	getListingPriceFromPriceProfile,
	getPriceTag,
	getTour,
	validatePaxSelections,
} from 'Utils/pricingUtils';
import {
	getProductMetaDescription,
	getProductPageTitle,
	getURLSlugForEntity,
	hasComboVariants,
	hasMultipleAvailableVariants,
} from 'Utils/productUtils';
import { read as readFromSessionStore } from 'Utils/sessionStoreUtil';
import { generateTourIdPropertiesMap } from 'Utils/sharedAirportTransfer/stateTransformationUtils';
import {
	getBooking,
	getCurrencyCodesList,
	getInventoryMapByTourDate,
	getPricing,
	getProduct,
} from 'Utils/stateUtils';
import {
	doesCurrentRouteIncludeString,
	getApiCDNBaseUrl,
	getCouponCodeFromUrl,
	getQueryFromURLByName,
	getStringifiedQueryFromObject,
} from 'Utils/urlUtils';

import { fetchCheckoutMetadata } from 'Thunks/checkoutMetadata';
import { setTourUserDetails } from 'Actions/booking';

import {
	ACTIVE_LANGUAGE_CODES,
	ANALYTICS_FLOW_TYPE,
	BOOKING_FLOW_STAGE,
	BOOKING_FLOW_SUBSTAGE,
	BOOKING_FLOW_TYPE,
	BROADWAY_MAX_PAX,
	broadwaySectionNames,
	CITIES_EXCLUDED_FROM_VIEWING_TICKETS,
	COOKIE,
	COOKIE_DELIMITER,
	DEVICE_TYPE,
	FLEXIBLE_START_TIME,
	GRAND_PRIX,
	INVENTORY_DURATION_TYPE,
	INVENTORY_START_TYPE,
	INVENTORY_TYPE,
	LSCACHE_PARAM,
	NON_BOOKING_FLOW_REGEX,
	PAGE_TYPE,
	PAX_TYPES,
	POST_CHECKOUT_STAGES,
	PROFILE_TYPE,
	QUERY_PARAM_WHITELIST,
	RESERVATION_SESSION_STORAGE_KEY,
	TIME,
	TOUR_PROPERTIES,
} from 'Constants/constants';
import { strings } from 'Constants/strings';

import fetchWrapper from './fetchWrapper';

export const getSelectionStateQuery = ({
	date,
	time,
	tourId,
	variantId,
	pax = {},
	couponCode,
	instantCheckout,
	whitelistedQueryparams = {},
	airportTransferQuoteId,
}: {
	date?: string;
	time?: string;
	tourId?: string;
	variantId?: string;
	pax?: Record<string, any>;
	couponCode?: any;
	instantCheckout?: string;
	whitelistedQueryparams?: Record<string, any>;
	airportTransferQuoteId?: string;
}) => ({
	...(date && { date }),
	...(time && { time }),
	...(tourId && { tourId }),
	...(variantId && { variantId }),
	...pax,
	...(couponCode && { couponCode }),
	...(instantCheckout && {
		instantCheckout: instantCheckout ? '1' : '',
	}),
	...(airportTransferQuoteId && { airportTransferQuoteId }),
	...whitelistedQueryparams,
});

export const getBookingFlowRoute = ({
	id,
	lang,
	stage,
	date,
	time,
	tourId,
	variantId,
	pax,
	trackConversion,
	source,
	instantCheckout,
	couponCode,
	whitelistedQueryparams = {},
	airportTransferQuoteId,
	showFullScreenPax,
	currencyCode,
	isAddOnFlow,
}: any) => {
	const langPrefix = lang && lang !== 'en' ? '/[lang]' : '';
	const isPostCheckoutStage = POST_CHECKOUT_STAGES.includes(stage);
	const isRecommendationsStage = stage === BOOKING_FLOW_STAGE.RECOMMENDATIONS;
	const isConfirmationStage = stage === BOOKING_FLOW_STAGE.CONFIRMATION;
	const isRouteDynamic = !isPostCheckoutStage && !isRecommendationsStage;
	const includeStageInRoute = isPostCheckoutStage || isRecommendationsStage;
	const routeWithId = isRouteDynamic ? `/book/[id]` : '';
	const flowStage = includeStageInRoute ? `/${stage}` : '/[...slug]';
	const keySuffix = isPostCheckoutStage ? '/[piid]' : '';

	const selectionStateQuery = !isRecommendationsStage
		? getSelectionStateQuery({
				date,
				time,
				tourId,
				variantId,
				pax,
				instantCheckout,
				couponCode,
				whitelistedQueryparams,
				airportTransferQuoteId,
		  })
		: {};
	const confirmationStageQuery = isConfirmationStage
		? {
				...(trackConversion && { trackConversion }),
				showPaymentDetails: true,
		  }
		: {};
	const addonFlowQuery = isAddOnFlow ? { isAddOnFlow: true } : {};
	const miscQuery = {
		...(source && { source }),
		...(showFullScreenPax && { showFullScreenPax }),
		...(id && { id, date, currencyCode }),
	};

	const queryString = getStringifiedQueryFromObject(
		{
			...selectionStateQuery,
			...confirmationStageQuery,
			...addonFlowQuery,
			...miscQuery,
		},
		{ sort: false },
	);

	const query = queryString ? `?${queryString}` : '';
	return `${langPrefix}${routeWithId}${flowStage}${keySuffix}${query}`;
};

export const getBookingFlowUrl = ({
	id,
	lang = null,
	stage = null,
	subStage = null,
	key = null,
	date = null,
	time = null,
	tourId = null,
	variantId = null,
	pax = null,
	trackConversion = null,
	source = null,
	instantCheckout = null,
	couponCode = null,
	whitelistedQueryparams = {},
	airportTransferQuoteId = null,
	showFullScreenPax = null,
	currencyCode = null,
	isAddOnFlow = false,
}: any) => {
	const isRecommendationsStage = stage === BOOKING_FLOW_STAGE.RECOMMENDATIONS;
	const isConfirmationStage = stage === BOOKING_FLOW_STAGE.CONFIRMATION;
	const isRouteDynamic =
		!POST_CHECKOUT_STAGES.includes(stage) && !isRecommendationsStage;
	const langPrefix = lang && lang !== 'en' ? `/${lang}` : '';
	const routeWithId = isRouteDynamic ? `/book/${id}` : '';
	const flowSubStage = subStage ? `/${subStage}` : '';
	const flowStage = stage ? `/${stage}${flowSubStage}` : '';
	const keySuffix = key ? `/${key}` : '';

	const selectionStateQuery = !isRecommendationsStage
		? getSelectionStateQuery({
				date,
				time,
				tourId,
				variantId,
				pax,
				instantCheckout,
				couponCode,
				whitelistedQueryparams,
				...(airportTransferQuoteId && { airportTransferQuoteId }),
		  })
		: {};
	const confirmationStageQuery = isConfirmationStage
		? {
				...(trackConversion && { trackConversion }),
				...(id && { id }),
		  }
		: {};
	const recommendationsStageQuery = isRecommendationsStage
		? {
				id,
				date,
				currencyCode,
				...(isAddOnFlow && { isAddOnFlow: true }),
		  }
		: {};
	const miscQuery = {
		...(source && { source }),
		...(showFullScreenPax && { showFullScreenPax }),
	};

	const queryString = getStringifiedQueryFromObject(
		{
			...selectionStateQuery,
			...confirmationStageQuery,
			...miscQuery,
			...recommendationsStageQuery,
		},
		{ sort: false },
	);

	const query = queryString ? `?${queryString}` : '';
	return `${langPrefix}${routeWithId}${flowStage}${keySuffix}${query}`;
};

export const getConfirmationPageDeepLink = (secureId: string) => {
	return `headout://confirmation/${secureId}`;
};

export const bookingFlowPush = ({
	id,
	lang,
	stage,
	subStage,
	key,
	date,
	time,
	tourId,
	variantId,
	pax,
	trackConversion,
	source,
	instantCheckout,
	couponCode,
	airportTransferQuoteId,
	shallow = false,
	includeIdInRoute = false,
}: any) => {
	const nextRoute = getBookingFlowRoute({
		lang,
		stage,
		subStage,
		date,
		time,
		tourId,
		variantId,
		pax,
		trackConversion,
		source,
		instantCheckout,
		couponCode,
		airportTransferQuoteId,
		...(includeIdInRoute
			? {
					id,
			  }
			: {}),
	});

	const nextUrl = getBookingFlowUrl({
		id,
		lang,
		stage,
		subStage,
		key,
		date,
		time,
		tourId,
		variantId,
		pax,
		trackConversion,
		source,
		instantCheckout,
		couponCode,
		airportTransferQuoteId,
	});

	Router.push(nextRoute, nextUrl, { shallow });
};

export const bookingFlowReplace = ({
	id,
	lang,
	stage,
	subStage,
	key,
	date,
	time,
	tourId,
	variantId,
	pax,
	trackConversion,
	source,
	couponCode,
	airportTransferQuoteId,
}: any) => {
	const newRoute = getBookingFlowRoute({
		lang,
		stage,
		subStage,
		date,
		time,
		tourId,
		variantId,
		pax,
		trackConversion,
		source,
		couponCode,
		airportTransferQuoteId,
	});
	const newUrl = getBookingFlowUrl({
		id,
		lang,
		stage,
		subStage,
		key,
		date,
		time,
		tourId,
		variantId,
		pax,
		trackConversion,
		source,
		couponCode,
		airportTransferQuoteId,
	});
	return Router.replace(newRoute, newUrl);
};

export const isBookingStageValid = (stage: any) => {
	return stage && Object.values(BOOKING_FLOW_STAGE).includes(stage);
};

export const doesStageHaveASecureLink = (stage: any) =>
	POST_CHECKOUT_STAGES.includes(stage);

export const isCityExcludedFromViewingTickets = (currentCityCode: any) =>
	CITIES_EXCLUDED_FROM_VIEWING_TICKETS.includes(currentCityCode);

export const isFirstVisitToSecureLink = ({ itineraryDetails, stage }: any) =>
	doesStageHaveASecureLink(stage) && !itineraryDetails;

export const getCurrentBookingStage = (location: any) => {
	let pathname = location.asPath;
	if (pathname.indexOf('?')) {
		pathname = pathname.split('?')[0];
	}
	const pathArray = pathname.split('/');
	let stage =
		doesStageHaveASecureLink(pathArray[pathArray.length - 1]) ||
		pathArray.includes(BOOKING_FLOW_SUBSTAGE.PAX) ||
		pathArray.includes(BOOKING_FLOW_SUBSTAGE.TIME) ||
		pathArray.includes(BOOKING_FLOW_SUBSTAGE.RESERVATION_DETAILS)
			? pathArray[pathArray.length - 3]
			: pathArray[pathArray.length - 2];

	return isBookingStageValid(stage) ? stage : '';
};

export const isBookingFlowPage = ({ currentPage, location }: any) => {
	return (
		currentPage === PAGE_TYPE.BOOKING ||
		Boolean(getCurrentBookingStage(location))
	);
};

export const isNonBookingFlowPage = (location: any) =>
	NON_BOOKING_FLOW_REGEX.test(location.asPath);

export const hideFooterOnMobileBookingFlow = ({ location }: any) =>
	getCurrentBookingStage(location) !== BOOKING_FLOW_STAGE.CONFIRMATION;

export const hideMobileStickyFooter = ({ currentPage, location }: any) =>
	currentPage === PAGE_TYPE.EXPERIENCE ||
	doesCurrentRouteIncludeString('buy-gift-credits', location) ||
	doesCurrentRouteIncludeString('supply-partner', location) ||
	(isBookingFlowPage({ currentPage, location }) &&
		hideFooterOnMobileBookingFlow({ location })) ||
	doesCurrentRouteIncludeString('/profile/booking', location) ||
	doesCurrentRouteIncludeString('/audio-guide', location) ||
	doesCurrentRouteIncludeString('/confirmation', location);

export const getTitle = ({ store, params }: any) => {
	getProductPageTitle(getProduct(store.getState(), params.id));
};

export const getCanonicalUrl = ({ store, params }: any) => {
	getURLSlugForEntity({
		product: getProduct(store.getState(), params.id),
		paramLang: params.lang,
	});
};

export const getMetaDescription = ({ store, params }: any) => {
	getProductMetaDescription(getProduct(store.getState(), params.id));
};

export const getMinPriceOfTour = (
	tourId: any,
	pricing: any,
	selectedDate: any,
) => {
	if (!tourId || !selectedDate) return Number.MAX_SAFE_INTEGER;

	const { inventoryMap } = pricing;
	const availabilities = inventoryMap?.[selectedDate]?.[tourId];

	if (!availabilities) return Number.MAX_SAFE_INTEGER;

	const inventory = getInventoryWithMinPrice(availabilities);

	return getListingPriceFromPriceProfile(inventory?.priceProfile);
};

export const getNextDatesAvailable = (
	id: any,
	pricing: any,
	selectedDate: any,
) => {
	const { sortedInventoryDates, inventoryMap } = pricing;
	// @ts-expect-error TS(7034): Variable 'nextDatesAvailable' implicitly has type ... Remove this comment to see the full error message
	let nextDatesAvailable = [];
	sortedInventoryDates.forEach((date: any) => {
		if (date > selectedDate && inventoryMap?.[date]?.[id]) {
			const isAvailable = inventoryMap?.[date]?.[id].length > 0;
			if (isAvailable) {
				nextDatesAvailable.push(date);
			}
		}
	});
	// @ts-expect-error TS(7005): Variable 'nextDatesAvailable' implicitly has an 'a... Remove this comment to see the full error message
	return nextDatesAvailable;
};

export const getFirstAvailableTimeAcrossTours = (
	pricing: any,
	selectedDate: any,
	selectedTime: any,
) =>
	selectedTime === FLEXIBLE_START_TIME
		? Object.keys(
				groupBy(
					flatten(pricing?.inventoryMapByDateTime?.[selectedDate]),
					'startTime',
				),
		  )?.[0]
		: selectedTime;

export const getFirstAvailableTimeInTour = (
	pricing: any,
	selectedDate: any,
	selectedTourId: any,
	selectedTime: any,
) =>
	selectedTime === FLEXIBLE_START_TIME &&
	pricing?.inventoryMap?.[selectedDate]?.[selectedTourId]?.[0]?.startTime
		? pricing?.inventoryMap?.[selectedDate]?.[selectedTourId]?.[0]
				?.startTime
		: selectedTime;

export const getFirstAvailableTimeInTourDate = (
	pricing: any,
	tourId: any,
	selectedDate: any,
	selectedTime: any,
) =>
	selectedTime === FLEXIBLE_START_TIME
		? Object.keys(
				groupBy(
					pricing?.inventoryMapByTourDate?.[tourId]?.[selectedDate],
					'startTime',
				),
		  )?.[0]
		: selectedTime;

export const getVariantList = (pricing: any, selectedDate: any) => {
	const { inventoryMap, tourMap } = pricing || {};
	const allKeys = tourMap ? takeOutKeys(tourMap) : [];
	// @ts-expect-error TS(7034): Variable 'availableKeys' implicitly has type 'any'... Remove this comment to see the full error message
	let availableKeys;
	// @ts-expect-error TS(7034): Variable 'unavailableKeys' implicitly has type 'an... Remove this comment to see the full error message
	let unavailableKeys;
	if (selectedDate) {
		const datedInventory = inventoryMap?.[selectedDate];
		availableKeys = datedInventory ? takeOutKeys(datedInventory) : allKeys;
		// @ts-expect-error TS(7005): Variable 'availableKeys' implicitly has an 'any' t... Remove this comment to see the full error message
		unavailableKeys = allKeys.filter(x => availableKeys.indexOf(x) === -1);
	} else {
		availableKeys = allKeys;
		unavailableKeys = [];
	}
	// Sort keys to match server and client rendering
	availableKeys = availableKeys.sort(
		(a, b) =>
			getMinPriceOfTour(a, pricing, selectedDate) -
			getMinPriceOfTour(b, pricing, selectedDate),
	);
	// @ts-expect-error TS(7005): Variable 'unavailableKeys' implicitly has an 'any[... Remove this comment to see the full error message
	unavailableKeys = unavailableKeys.sort((a, b) => Number(a) - Number(b));
	const variantObjectList: any[] = [];
	availableKeys.forEach(item => {
		variantObjectList.push({
			id: item,
			isAvailable: true,
			nextAvailable: '',
		});
	});
	unavailableKeys.forEach(item => {
		const nextDatesAvailable = getNextDatesAvailable(
			item,
			pricing,
			selectedDate,
		);
		let nextAvailable = '';
		let nextAvailableJsDate;
		if (nextDatesAvailable.length > 0) {
			nextAvailable = nextDatesAvailable?.[0];
			nextAvailableJsDate = nextAvailable;
			const dateTime = localDateToJsDate(nextAvailable);
			nextAvailable = formatUsingDayJS(dateTime, 'ddd, D MMM');
		}
		variantObjectList.push({
			id: item,
			isAvailable: false,
			nextAvailable,
			nextAvailableJsDate,
		});
	});

	return variantObjectList;
};

/**
 * @param tour
 * @param inventory
 * @returns formatted startTime, endTime, duration
 */
export const getSchedule = (tour: any, inventory: any) => {
	if (inventory && tour) {
		const { startTime, endTime } = inventory;
		const { duration } = tour;

		return {
			// @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
			startTime: formatTimeLocalised(startTime),
			// @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
			endTime: formatTimeLocalised(endTime),
			duration: formatDurationToHoursMinutes(duration),
		};
	}

	return {
		startTime: null,
		endTime: null,
		duration: tour?.duration,
	};
};

export const isFlexiProduct = (pricing: any) => {
	const tours = pricing?.tours;

	return tours?.some(
		(tour: any) =>
			tour?.inventoryType ===
				INVENTORY_TYPE.FLEXIBLE_START_FIXED_DURATION ||
			tour?.inventoryType ===
				INVENTORY_TYPE.FLEXIBLE_START_FLEXIBLE_DURATION,
	);
};

/* Mixed type: has both Flexi and Non Flexi */
export const isMixedInventoryType = ({ tours }: any) => {
	const hasFlexi = isFlexiProduct({ tours });
	const hasNonFlexi = !!tours.find(
		({ inventoryType }: any) =>
			inventoryType === INVENTORY_TYPE.FIXED_START_FLEXIBLE_DURATION ||
			inventoryType === INVENTORY_TYPE.FIXED_START_FIXED_DURATION,
	);

	return hasFlexi && hasNonFlexi;
};

export const isFlexiProductByInventoryType = (inventoryType: any) =>
	inventoryType === INVENTORY_TYPE.FLEXIBLE_START_FIXED_DURATION ||
	inventoryType === INVENTORY_TYPE.FLEXIBLE_START_FLEXIBLE_DURATION;

export const getInventoryType = (tour: any) => tour?.inventoryType;

export const isFlexiStart = (tour: any) => {
	const inventoryType = tour?.inventoryType;
	return inventoryType.startsWith(INVENTORY_START_TYPE.FLEXIBLE_START);
};

export const isFlexiDuration = (tour: any) => {
	const inventoryType = tour?.inventoryType;
	return inventoryType.endsWith(INVENTORY_DURATION_TYPE.FLEXIBLE_DURATION);
};

export const isSingleDateProduct = (inventoryMap: any) =>
	isNonEmptyObject(inventoryMap) && Object.keys(inventoryMap).length === 1;

export const hasSingleTimeSlot = (
	inventoryMap: any,
	selectedDate: any,
	selectedTourId: any,
) => {
	const availableTimeSlots =
		inventoryMap?.[selectedDate]?.[String(selectedTourId)];

	return (
		isNonEmptyObject(availableTimeSlots) &&
		(Object.keys(availableTimeSlots).length === 1 ||
			availableTimeSlots.length === 1)
	);
};

export const isUnavailableProduct = (inventoryMap: any) =>
	!isNonEmptyObject(inventoryMap);

export const getFlexiTours = (tourMap: any) => {
	let flexiTours = {};
	for (const tourId in tourMap) {
		const item = tourMap[tourId];
		const inventoryType = item?.[0]?.inventoryType;
		if (
			inventoryType === INVENTORY_TYPE.FLEXIBLE_START_FIXED_DURATION ||
			inventoryType === INVENTORY_TYPE.FLEXIBLE_START_FLEXIBLE_DURATION
		)
			// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
			flexiTours[tourId] = item;
	}
	return flexiTours;
};

export const isFlexiTour = (tourMap: any, tourId: any) => {
	const inventoryType = tourMap?.[String(tourId)]?.[0]?.inventoryType;
	return (
		inventoryType === INVENTORY_TYPE.FLEXIBLE_START_FIXED_DURATION ||
		inventoryType === INVENTORY_TYPE.FLEXIBLE_START_FLEXIBLE_DURATION
	);
};

export const isSeatmap = (pricing: any) => {
	if (!pricing) return null;
	const { seatmapEmbeddedUrl } = pricing;
	return !!seatmapEmbeddedUrl;
};

export const hasSeatingChart = (pricing: any) => {
	if (!pricing) return null;
	const { seatingChartUpload } = pricing;

	return !!seatingChartUpload;
};

export const getAnalyticsFlowType = (product?: any) => {
	const isMultiVariant = hasMultipleAvailableVariants(product);
	if (hasComboVariants(product)) return ANALYTICS_FLOW_TYPE.COMBO;
	if (isTourGroupOpenDated(product)) {
		return !isMultiVariant
			? ANALYTICS_FLOW_TYPE.OPEN_DATED_NO_VARIANT
			: ANALYTICS_FLOW_TYPE.OPEN_DATED_MULTI_VARIANT;
	}

	switch (product?.flowType) {
		case BOOKING_FLOW_TYPE.SEATMAP:
			return ANALYTICS_FLOW_TYPE.HIFI_SEATMAP;
		case BOOKING_FLOW_TYPE.SVG:
		case BOOKING_FLOW_TYPE.RESERVATION:
			return ANALYTICS_FLOW_TYPE.LOFI_SEATMAP;
		case BOOKING_FLOW_TYPE.PRIVATE_AIRPORT_TRANSFER:
			return ANALYTICS_FLOW_TYPE.PRIVATE_AIRPORT_TRANSFERS;
		case BOOKING_FLOW_TYPE.SHARED_AIRPORT_TRANSFER:
			return ANALYTICS_FLOW_TYPE.SHARED_AIRPORT_TRANSFERS;
		case BOOKING_FLOW_TYPE.HOP_ON_HOP_OFF:
			return ANALYTICS_FLOW_TYPE.HOP_ON_HOP_OFF;
		case BOOKING_FLOW_TYPE.GUIDED_TOUR_PROPERTY_SELECTION:
			return BOOKING_FLOW_TYPE.GUIDED_TOUR_PROPERTY_SELECTION;
		case BOOKING_FLOW_TYPE.PROPERTY_SELECTION:
			return BOOKING_FLOW_TYPE.PROPERTY_SELECTION;
		default:
			return isMultiVariant
				? ANALYTICS_FLOW_TYPE.MULTI_TOUR
				: ANALYTICS_FLOW_TYPE.SINGLE_TOUR;
	}
};

export const getInitialBookingStage = (pricing: any) => {
	if (isSeatmap(pricing)) {
		return BOOKING_FLOW_STAGE.SEATMAP_VARIANT;
	} else if (hasSeatingChart(pricing)) {
		return BOOKING_FLOW_STAGE.SVG_SELECT;
	}
	return BOOKING_FLOW_STAGE.SELECT;
};

export const resetBookingFlow = ({ lang, pricing }: any) => {
	bookingFlowReplace({
		// @ts-expect-error TS(2693): 'any' only refers to a type, but is being used as ... Remove this comment to see the full error message
		id: any,
		lang: lang === 'en' ? '' : lang,
		stage: pricing ? getInitialBookingStage(pricing) : null,
	});
};

export const skipDateSelection = (flowStage: any) => {
	if (flowStage === BOOKING_FLOW_STAGE.SVG_SELECT) {
		return BOOKING_FLOW_STAGE.SVG_VARIANT;
	} else if (flowStage === BOOKING_FLOW_STAGE.SEATMAP_SELECT) {
		return BOOKING_FLOW_STAGE.SEATMAP_VARIANT;
	}
	return flowStage;
};

export const getDesktopFlowStage = (
	product: any,
	validSelectionInfo: any,
	booking: any,
) => {
	const { date, time, tourId, isPaxOnSelectPage, airportTransferQuoteId } =
		validSelectionInfo ?? {};
	const { selectedDate } = booking ?? {};
	const { flowType } = product ?? {};
	const isOpenDatedProduct = isTourGroupOpenDated(product);

	const isPrivateAirportTransfer =
		flowType === BOOKING_FLOW_TYPE.PRIVATE_AIRPORT_TRANSFER;

	const isSharedAirportTransfer =
		flowType === BOOKING_FLOW_TYPE.SHARED_AIRPORT_TRANSFER;

	const isHopOnHopOff = flowType === BOOKING_FLOW_TYPE.HOP_ON_HOP_OFF;

	let flowStage;
	if (
		(date &&
			time &&
			tourId &&
			flowType !== BOOKING_FLOW_TYPE.SEATMAP &&
			!isPaxOnSelectPage) ||
		(isOpenDatedProduct && !hasMultipleAvailableVariants(product)) ||
		(isPrivateAirportTransfer && airportTransferQuoteId)
	) {
		flowStage = BOOKING_FLOW_STAGE.CHECKOUT;
	} else {
		flowStage = BOOKING_FLOW_STAGE.SELECT;
		if (
			flowType &&
			!isOpenDatedProduct &&
			!isPrivateAirportTransfer &&
			!isSharedAirportTransfer &&
			!isDVTBookingFlow(product) &&
			flowType !== BOOKING_FLOW_TYPE.RESERVATION &&
			flowType !== BOOKING_FLOW_TYPE.COMBO &&
			!isHopOnHopOff
		) {
			flowStage = isExternalSeatmapProduct(product)
				? BOOKING_FLOW_STAGE.EXTERNAL_SEATMAP_SELECT
				: flowType === BOOKING_FLOW_TYPE.SVG
				? BOOKING_FLOW_STAGE.SVG_VARIANT
				: `${flowType.toLowerCase()}-${flowStage}`;
		}
	}

	if (date || selectedDate) {
		flowStage = skipDateSelection(flowStage);
	}
	return flowStage;
};

export const getMobileFlowStage = (
	product: any,
	validSelectionInfo: any,
	booking: any,
) => {
	const { date, time, tourId, pax, airportTransferQuoteId } =
		validSelectionInfo;
	const { selectedDate, selectedTime } = booking ?? {};
	const { flowType } = product || {};
	const isOpenDatedProduct = isTourGroupOpenDated(product);
	const isPrivateAirportTransfer =
		flowType === BOOKING_FLOW_TYPE.PRIVATE_AIRPORT_TRANSFER;

	const isSharedAirportTransfer =
		flowType === BOOKING_FLOW_TYPE.SHARED_AIRPORT_TRANSFER;

	const isHopOnHopOff = flowType === BOOKING_FLOW_TYPE.HOP_ON_HOP_OFF;

	let flowStage;
	if (
		(date && time && tourId && pax) ||
		(isPrivateAirportTransfer && airportTransferQuoteId)
	) {
		flowStage = BOOKING_FLOW_STAGE.CHECKOUT;
	} else {
		flowStage = BOOKING_FLOW_STAGE.SELECT;
	}
	if (
		flowType &&
		!(
			flowType === BOOKING_FLOW_TYPE.SVG &&
			flowStage === BOOKING_FLOW_STAGE.CHECKOUT
		) &&
		!isDVTBookingFlow(product) &&
		flowType !== BOOKING_FLOW_TYPE.RESERVATION &&
		flowType !== BOOKING_FLOW_TYPE.COMBO &&
		!isOpenDatedProduct &&
		!isPrivateAirportTransfer &&
		!isSharedAirportTransfer &&
		!isHopOnHopOff
	) {
		flowStage = isExternalSeatmapProduct(product)
			? BOOKING_FLOW_STAGE.EXTERNAL_SEATMAP_SELECT
			: `${flowType.toLowerCase()}-${flowStage}`;
	}
	if (
		(date || selectedDate) &&
		(flowType === BOOKING_FLOW_TYPE.SVG || time || selectedTime)
	) {
		flowStage = skipDateSelection(flowStage);
	}
	if (date && tourId && !pax && flowType !== BOOKING_FLOW_TYPE.SEATMAP) {
		const subStage = time
			? BOOKING_FLOW_SUBSTAGE.PAX
			: BOOKING_FLOW_SUBSTAGE.TIME;
		flowStage = `${flowStage}/${subStage}`;
	}
	if (isOpenDatedProduct && !pax) {
		flowStage = `${BOOKING_FLOW_STAGE.CHECKOUT}/${BOOKING_FLOW_SUBSTAGE.PAX}`;
		if (hasMultipleAvailableVariants(product) && !(date || time || pax)) {
			flowStage = BOOKING_FLOW_STAGE.SELECT;
		}
	}
	return flowStage;
};

export const getRoutingInfo = ({
	id,
	booking,
	validSelectionInfo = {},
	product,
	deviceType,
}: {
	id: string;
	booking: any;
	validSelectionInfo: any;
	product?: any;
	deviceType?: string;
}) => {
	const { selectedVariantId, selectedDate, selectedTime, selectedTourId } =
		booking;

	let flowStage;
	if (deviceType === DEVICE_TYPE.DESKTOP) {
		flowStage = getDesktopFlowStage(product, validSelectionInfo, booking);
	} else {
		flowStage = getMobileFlowStage(product, validSelectionInfo, booking);
	}

	const { lang, variantId, date, time, tourId } = validSelectionInfo;
	const langPrefix = lang && lang !== 'en' ? `/${lang}` : '';

	const query = getSelectionStateQuery({
		variantId: variantId || selectedVariantId,
		date: date || selectedDate,
		time: time || selectedTime,
		tourId: tourId || selectedTourId,
	});

	const queryString = isNonEmptyObject(query)
		? `?${getStringifiedQueryFromObject(query, { sort: false })}`
		: '';

	return {
		route: getBookingFlowRoute({ lang, stage: flowStage }),
		pathname: `${langPrefix}/book/${id}/${flowStage}/`,
		query,
		queryString,
	};
};

export const getQueryStringFromObject = (queryObj = {}) =>
	Object.keys(queryObj)
		// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
		.map(key => key + '=' + queryObj[key])
		.join('&');

export const routeToBookingFlow = (
	id: any,
	booking: any,
	selectionInfo: any,
	reload: any,
	product: any,
) => {
	const { lang } = selectionInfo;
	const { route, pathname, query } = getRoutingInfo({
		id,
		booking,
		validSelectionInfo: {
			lang,
		},
		product,
	});

	const sanitizedQuery = fromEntries(
		Object.entries(query).filter(([_key, value]) => !!value),
	);
	const queryString = getQueryStringFromObject(sanitizedQuery);
	const suffix = queryString ? `?${queryString}` : '';
	const nextRoute = `${route}${suffix}`;
	const nextUrl = `${pathname}${suffix}`;
	if (reload) {
		const { origin } = window.location;
		// @ts-expect-error TS(2322): Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
		window.location = `${origin}${pathname}?${queryString}`;
	} else {
		Router.push(nextRoute, nextUrl);
	}
};

export const getCorrectRouteToBookingFlow = (
	id: any,
	booking: any,
	selectionInfoFromUrl: any,
	product: any,
	deviceType: any,
) => {
	const { variantId, date, time, tourId, pax, location } =
		selectionInfoFromUrl;

	const hasDateQuery = location?.query?.date;

	const couponCode = getCouponCodeFromUrl(location);
	const whitelistedQueryparams = QUERY_PARAM_WHITELIST.reduce(
		(acc, queryParam) => {
			const queryValue = getQueryFromURLByName(location, queryParam);
			if (!queryValue) return acc;
			return {
				...acc,
				[queryParam]: queryValue,
			};
		},
		{},
	);
	const { route, pathname } = getRoutingInfo({
		id,
		booking,
		validSelectionInfo: selectionInfoFromUrl,
		product,
		deviceType,
	});

	const selectionState = getSelectionStateQuery({
		variantId,
		date,
		time,
		tourId,
		pax,
		couponCode,
		whitelistedQueryparams,
	});

	const queryString = getQueryStringFromObject(selectionState);

	const correctdPath =
		!hasDateQuery && pathname.includes(BOOKING_FLOW_STAGE.SEATMAP_VARIANT)
			? pathname.replace(
					BOOKING_FLOW_STAGE.SEATMAP_VARIANT,
					BOOKING_FLOW_STAGE.SEATMAP_SELECT,
			  )
			: pathname;

	const query = queryString ? `?${queryString}` : '';

	return {
		calculatedRoute: `${correctdPath}${query}`,
		calculatedHref: correctdPath,
		calculatedPagesRoute: `${route}${query}`,
	};
};

export const setCheckoutPageInfo = async (store: any, id: number) => {
	const booking = getBooking(store.getState(), id);
	const pricing = getPricing(store.getState(), id);
	const checkoutMetadataRequestObject = getCheckoutMetadataRequestObject({
		booking,
		pricing,
	});
	await store.dispatch(fetchCheckoutMetadata(checkoutMetadataRequestObject));
};

export const hasValidCurrencyCode = ({
	state,
	currency,
	currentCurrencyCode,
}: {
	state: Object;
	currency?: string;
	currentCurrencyCode?: string;
}) =>
	currency &&
	getCurrencyCodesList(state).includes(currency) &&
	currentCurrencyCode !== currency; // no need to override if both are same

export const hasValidLanguageCode = (language: any, currentLanguageCode: any) =>
	language &&
	ACTIVE_LANGUAGE_CODES.includes(language) &&
	currentLanguageCode !== language;

export const hasValidSourcePiid = (piid: any) => !!piid;

export const hasValidCouponCode = (couponCode: any) => !!couponCode;

export const hasValidVariant = (pricing: any, variantId: any) =>
	pricing?.variantIds?.includes(Number(variantId));

export const checkVariantIdValidity = (product: any, variantId: any) =>
	product?.variants?.some(
		(variant: any) => variant?.id === Number(variantId),
	) || null;

export const hasValidDate = (tourMinInventoryData: any, date: any) => {
	const { sortedInventoryDates } = tourMinInventoryData ?? {};
	return sortedInventoryDates?.includes(date);
};

export const validateDateSelection = (inventoryData: any, date: any) =>
	hasValidDate(inventoryData, date) ? date : null;

export const hasValidTourDate = (tourMinInventoryData: any, date: any) => {
	const { sortedInventoryDates } = tourMinInventoryData;
	return sortedInventoryDates?.includes(date);
};

export const validateTourDateSelection = (inventoryData: any, date: any) =>
	hasValidTourDate(inventoryData, date) ? date : null;

export const hasValidTime = (pricing: any, date: any, time: any) => {
	const inventoryMapByDateTime = pricing?.inventoryMapByDateTime;
	return (
		inventoryMapByDateTime?.[date]?.[time] ||
		(isFlexiProduct(pricing) && time === FLEXIBLE_START_TIME)
	);
};

export const validateTimeSelection = (pricing: any, date: any, time: any) =>
	hasValidTime(pricing, date, time) ? time : null;

export const hasValidTourTime = (
	pricing: any,
	date: any,
	time: any,
	tourId: any,
) => {
	const { inventoryMapByTourDate } = pricing;
	return (
		inventoryMapByTourDate?.[Number(tourId)]?.[date]?.some(
			(inv: any) => inv?.startTime === time,
		) ||
		(isFlexiProduct(pricing) && time === FLEXIBLE_START_TIME)
	);
};

// @ts-expect-error TS(7006): Parameter 'pricing' implicitly has an 'any' type.
export const validateTourTimeSelection = (pricing, date, time, tourId) =>
	hasValidTourTime(pricing, date, time, tourId) ? time : null;

// @ts-expect-error TS(7006): Parameter 'pricing' implicitly has an 'any' type.
export const hasValidTourUnderTime = (pricing, date, time, tour) => {
	const tourId = Number(tour);
	const inventoryMapByDateTime = pricing?.inventoryMapByDateTime;
	if (
		hasValidTime(pricing, date, time) &&
		inventoryMapByDateTime?.[date]?.[time]
	) {
		return inventoryMapByDateTime?.[date]?.[time].some((tours: any) => {
			const { tourId: invTourId } = tours;
			return tourId === invTourId;
		});
	}
	return false;
};

export const hasValidTour = (pricing: any, date: any, tour: any) => {
	const tourId = Number(tour);
	const variantList = getVariantList(pricing, date);
	return !!variantList.find(
		({ id: validTourId, isAvailable }) =>
			tourId === Number(validTourId) && isAvailable,
	);
};

// @ts-expect-error TS(7006): Parameter 'pricing' implicitly has an 'any' type.
export const hasValidTimeUnderTour = (pricing, date, tour, time) => {
	if (hasValidTour(pricing, date, tour)) {
		const inventoryMap = pricing?.inventoryMap;
		if (inventoryMap?.[date]?.[tour]) {
			return (
				inventoryMap?.[date]?.[tour].some((tour: any) => {
					const startTime = tour?.startTime;
					return startTime === time;
				}) ||
				(isFlexiProduct(pricing) && time === FLEXIBLE_START_TIME)
			);
		}
	}
	return false;
};

export const hasValidPax = (people: Record<string, any> | null) => {
	return (
		people &&
		((people.selectionMap && Object.keys(people.selectionMap).length) ||
			people.groupSize)
	);
};

// @ts-expect-error TS(7006): Parameter 'pricing' implicitly has an 'any' type.
export const getValidPax = (pricing, date, time, tour, pax) => {
	if (!pax || !Object.entries(pax).length) {
		return null;
	}
	if (
		hasValidTourUnderTime(pricing, date, time, tour) &&
		hasValidTimeUnderTour(pricing, date, tour, time)
	) {
		const inventoryMapByDateTime = pricing?.inventoryMapByDateTime;
		const tourId = Number(tour);
		const inventorySlot = inventoryMapByDateTime?.[date]?.[time].find(
			(tours: any) => {
				const { tourId: invTourId } = tours;
				return tourId === invTourId;
			},
		);
		if (inventorySlot) {
			const {
				priceProfile: { priceProfileType, groups, persons },
				paxAvailability,
				paxValidation,
			} = inventorySlot;
			if (priceProfileType === PROFILE_TYPE.PER_PERSON) {
				const personTypes = persons.map((person: any) => person?.type);
				const selections = fromEntries(
					Object.entries(pax)
						.map(([paxType, paxSelections]) => [
							paxType.toUpperCase(),
							Number(paxSelections),
						])
						.filter(([paxType]) => personTypes.includes(paxType)),
				);
				const { isValid: isPaxSelectionValid } = validatePaxSelections({
					selectionMap: selections,
					paxAvailability,
					paxValidation,
					tour: getTour(pricing, tourId),
				});

				if (isPaxSelectionValid) {
					return {
						groupSize: 0,
						selectionMap: selections,
					};
				}
			} else if (priceProfileType === PROFILE_TYPE.PER_GROUP) {
				const groupSizes = groups.map((group: any) => group?.people);
				const groupSizeSelection = Number(pax.groupSize);
				const { minPax, maxPax } =
					paxValidation?.[PAX_TYPES.GROUP] ?? {};
				if (
					groupSizeSelection &&
					groupSizes.includes(groupSizeSelection) &&
					groupSizeSelection >= minPax &&
					groupSizeSelection <= maxPax
				) {
					return {
						groupSize: groupSizeSelection,
						selectionMap: {},
					};
				}
			}
		}
	}
	return null;
};

// @ts-expect-error TS(7006): Parameter 'pricing' implicitly has an 'any' type.
export const getValidBroadwayPax = (pricing, date, time, pax) => {
	if (!pax || !Object.entries(pax).length) {
		return null;
	}

	if (hasValidTime(pricing, date, time)) {
		const inventoryMapByDateTime = pricing?.inventoryMapByDateTime;
		const defaultInventorySlot =
			inventoryMapByDateTime?.[date]?.[time]?.[0];
		if (defaultInventorySlot) {
			const {
				priceProfile: { priceProfileType, persons },
			} = defaultInventorySlot;
			if (priceProfileType === PROFILE_TYPE.PER_PERSON) {
				const personTypes = persons.map((person: any) => person?.type);
				const selections = fromEntries(
					Object.entries(pax)
						.map(([paxType, paxSelections]) => [
							paxType.toUpperCase(),
							Number(paxSelections),
						])
						.filter(
							([paxType, paxSelections]: any) =>
								personTypes.includes(paxType) &&
								paxSelections > 0 &&
								paxSelections <= BROADWAY_MAX_PAX,
						),
				);

				if (selections && Object.keys(selections).length > 0) {
					return {
						groupSize: 0,
						selectionMap: selections,
					};
				}
			}
		}
	}
	return null;
};

// @ts-expect-error TS(7006): Parameter 'pricing' implicitly has an 'any' type.
export const getValidTourPax = (pricing, variant, tourId, date, time, pax) => {
	if (
		!pax ||
		!Object.entries(pax).length ||
		!hasValidTourTime(pricing, date, time, tourId)
	) {
		return null;
	}
	const inventoryMapByTourDate = pricing?.inventoryMapByTourDate;
	const tour = getTour(pricing, Number(tourId));
	const inventorySlot = inventoryMapByTourDate?.[tourId]?.[date]?.find(
		(inv: any) => inv?.startTime === time,
	);

	if (!inventorySlot) return null;
	const {
		priceProfile: { priceProfileType, groups, persons },
		paxAvailability,
		paxValidation,
	} = inventorySlot;
	if (priceProfileType === PROFILE_TYPE.PER_PERSON) {
		const personTypes = persons.map((person: any) => person?.type);
		const selections = fromEntries(
			Object.entries(pax)
				.map(([paxType, paxSelections]) => [
					paxType.toUpperCase(),
					Number(paxSelections),
				])
				.filter(([paxType]) => personTypes.includes(paxType)),
		);
		const totalPaxSelections = Object.values(selections).reduce(
			(totalPax, paxPerPerson) =>
				(totalPax as any) + Number(paxPerPerson),
			0,
		);
		const { isValid: isPaxSelectionValid } = validatePaxSelections({
			selectionMap: selections,
			paxAvailability,
			paxValidation,
			tour,
		});
		if (totalPaxSelections && isPaxSelectionValid) {
			return {
				groupSize: 0,
				selectionMap: selections,
			};
		}
	} else if (priceProfileType === PROFILE_TYPE.PER_GROUP) {
		const groupSizes = groups.map((group: any) => group?.people);
		const groupSizeSelection = Number(pax.groupSize);
		const { minPax, maxPax } = paxValidation?.[PAX_TYPES.GROUP] ?? {};
		if (
			groupSizeSelection &&
			groupSizes.includes(groupSizeSelection) &&
			groupSizeSelection >= minPax &&
			groupSizeSelection <= maxPax
		) {
			return {
				groupSize: groupSizeSelection,
				selectionMap: {},
			};
		}
	}
	return null;
};

export const getDefaultPaxType = (pricing: any) => {
	const { inventoryMapByDate } = pricing;
	const firstInventorySlot = (Object as any).values(
		inventoryMapByDate,
	)?.[0]?.[0];

	if (firstInventorySlot) {
		const {
			priceProfile: { priceProfileType, persons },
		} = firstInventorySlot;
		if (priceProfileType === PROFILE_TYPE.PER_PERSON) {
			return persons?.[0]?.type;
		}
	}

	return null;
};

export const getBookButtonText = () => strings.CPCB_CONFIRM_N_PAY;

export const isMultiTourVariant = (pricing: any, variantId: any) =>
	pricing?.variants?.find((variant: any) => variant?.id === Number(variantId))
		?.tours?.length > 1;

export const isComboVariant = (pricing: any, variantId: any) =>
	isMultiTourVariant(pricing, variantId);

export const isComboVariantFromProduct = (product: any, variantId: any) =>
	isMultiTourVariant(product, variantId);

// @ts-expect-error TS(2345): Argument of type '{ id: any; query: { [x: string]:... Remove this comment to see the full error message
export const getBookingFlowRoutes = ({ id, lang, query, stage, source }) => {
	const queryString = getQueryStringFromObject(query);
	const hrefSuffix = source === 'index' ? stage : '[...slug]';
	const href = lang
		? `/[lang]/book/[id]/${hrefSuffix}`
		: `/book/[id]/${hrefSuffix}`;
	const langParam = lang ? `/${lang}` : '';
	const path = `${langParam}/book/${id}/${stage}`;
	const querySuffix = queryString ? `?${queryString}` : '';
	const calculatedRoute = `${path}${querySuffix}`;
	const calculatedPagesRoute = `${href}${querySuffix}`;
	return {
		calculatedRoute,
		calculatedHref: href,
		calculatedPagesRoute,
	};
};

export const getFirstSlotForSelectedTour = ({
	pricing,
	selectedDate,
	tourId,
}: any) => {
	return pricing?.inventoryMapByTourDate?.[tourId]?.[selectedDate]?.[0] || {};
};

export const getUpdatedPaxDetails = (
	selectionMap: any,
	prevSelectionMap: any,
) => {
	const jsSelectionMap = selectionMap;
	const jsPrevSelectionMap = prevSelectionMap;
	if (!jsPrevSelectionMap) return {}; // on mount
	const [updatedPaxType, updatePaxCount] =
		Object.entries(jsSelectionMap).find(([paxType, updatePaxCount]) => {
			return updatePaxCount != jsPrevSelectionMap?.[paxType];
		}) || [];
	return { updatedPaxType, updatePaxCount };
};

export const getSelectedInventoryDiscount = ({
	pricing,
	date,
	tourId,
}: any) => {
	const { inventoryMapByTourDate } = pricing;
	const inventoryList = inventoryMapByTourDate?.[tourId]?.[date];
	const selectedInventory =
		inventoryList?.find?.((inv: any) => inv.tourId === tourId) ?? {};
	const { discount } = selectedInventory?.priceProfile?.persons?.[0] ?? {};
	return discount > 0 ? discount : null;
};

export const getTourPaxDescription = ({
	pricing,
	selectionState,
	tourId,
}: any) => {
	const {
		selectedTourDate,
		selectedTourTime,
		tourSelectionMap,
		tourGroupSize,
	} = selectionState || {};

	if (!selectedTourDate || !selectedTourTime) return '';

	const inventory = getAvailableInventory(
		{
			date: selectedTourDate,
			time: selectedTourTime,
			tourId,
		},
		pricing,
	);

	if (!inventory) return null;

	const { priceProfile, paxValidation } = inventory;

	if (!priceProfile || !tourSelectionMap) return '';

	const { priceProfileType, persons } = priceProfile;
	if (priceProfileType === PROFILE_TYPE.PER_PERSON) {
		return persons
			.map(({ type: paxType }: any) => {
				const paxNum = tourSelectionMap?.[paxType];
				const name =
					(Number(paxNum) > 1
						? strings.CP_PERSON_TYPES[paxType]?.plural
						: strings.CP_PERSON_TYPES[paxType]?.singular) ||
					paxValidation?.[paxType]?.displayName;

				if (!paxNum || !name) return '';

				return `${paxNum} ${name}`;
			})
			.filter((x: any) => !!x)
			.join(', ');
	}

	return Number(tourGroupSize) > 0
		? strings.formatString(strings.PPD_GROUP_SIZE, tourGroupSize)
		: '';
};

export const handleCachedDateExpiry = (id: any, date: any) => {
	removeFromCache(id, LSCACHE_PARAM.DATE);
	notify.show(strings.EARLIER_DATE_UNAVAILABLE, 'error', 4000);
	trackEvent({
		eventName: 'Date Expiry Message Displayed',
		Date: date,
	});
};

export const getSortedTourSlotsProps = ({
	pricing,
	date,
	onSlotChange,
	filteredTime,
	showScheduleInfo,
	highlightedTourId,
	availableSeatsMap,
}: any) => {
	const { availabilities, tours } = pricing;
	const toursWithSlotsProps = tours.map((tour: any) => {
		const tourId = tour?.id;
		const times = getDistinctTimes(availabilities, {
			date,
			tourId,
		});
		const timeSlots = times
			.map((time: any) =>
				getTimeSlot(pricing, { date, time, tourId }, availableSeatsMap),
			)
			.filter((timeSlot: any) => {
				const { priceTag } = timeSlot;
				return priceTag;
			});
		let scheduleInfo = '';
		if (times.length > 0) {
			const selectedTourId = tourId;
			const selectedDate = date;
			const selectedTime = times?.[0]; // for schedule info to get the end time
			scheduleInfo = getScheduleInfo(
				{ selectedDate, selectedTime, selectedTourId },
				pricing,
			);
		}

		return {
			tour,
			scheduleInfo,
			filteredTime,
			timeSlots,
			showTour: tours.length > 1,
			onSlotChange,
			showScheduleInfo,
			isHighlighted: tourId === highlightedTourId,
		};
	});

	return toursWithSlotsProps.sort((a: any, b: any) => {
		const getMinPriceForTourSlots = (tourSlots: any) =>
			tourSlots.timeSlots
				.map((timeSlot: any) => timeSlot?.priceTag?.price)
				.reduce(
					(minPrice: any, curPrice: any) =>
						minPrice > curPrice ? curPrice : minPrice,
					10000000,
				);

		if (
			a.timeSlots
				.map((timeSlot: any) => timeSlot?.time)
				.filter((time: any) => !filteredTime || filteredTime === time)
				.length === 0
		) {
			return 1;
		} else if (
			b.timeSlots
				.map((timeSlot: any) => timeSlot?.time)
				.filter((time: any) => !filteredTime || filteredTime === time)
				.length === 0
		) {
			return -1;
		}

		return getMinPriceForTourSlots(a) - getMinPriceForTourSlots(b);
	});
};

export const isTourAvailableAtFilteredTime = (tourDetails: any) => {
	const { filteredTime, timeSlots } = tourDetails;
	if (filteredTime) {
		// Not showing flexi if start time not selected
		return (
			timeSlots.filter((timeSlot: any) => timeSlot?.time === filteredTime)
				.length > 0
		);
	}
	return timeSlots.length > 0;
};

export const isTourGroupOpenDated = (product: any) =>
	product?.variants?.every(
		(variant: any) =>
			variant.openDated &&
			!isComboVariantFromProduct(product, variant.id),
	);

export const getVariantId = (pricing: any, tourId: any) => {
	return pricing.tourMap[String(tourId)][0].variantId;
};

export const getTourTimeFromInventory = ({
	pricing,
	tourId,
	selectedTourDate,
	selectedTourTime,
}: any) =>
	selectedTourTime === FLEXIBLE_START_TIME
		? pricing?.inventoryMapByTourDate?.[tourId]?.[selectedTourDate]?.[0]
				?.startTime
		: selectedTourTime;

export const getCheckoutMetadataRequestObject = ({ booking, pricing }: any) => {
	const {
		id,
		selectedTourId,
		selectedVariantId,
		selectedTime,
		selectedDate,
	} = booking;
	const comboSelections = booking?.comboSelections ?? {};
	const requestArray = [];
	if (isComboVariant(pricing, selectedVariantId)) {
		Object.keys(comboSelections).forEach(tourId => {
			const { selectedTourDate, selectedTourTime } =
				comboSelections?.[tourId] ?? {};
			requestArray.push({
				tourId: Number(tourId),
				date: selectedTourDate,
				time: getTourTimeFromInventory({
					pricing,
					tourId,
					selectedTourDate,
					selectedTourTime,
				}),
			});
		});
	} else {
		requestArray.push({
			tourId: Number(selectedTourId),
			date: selectedDate,
			time: getTourTimeFromInventory({
				pricing,
				tourId: selectedTourId,
				selectedTourDate: selectedDate,
				selectedTourTime: selectedTime,
			}),
		});
	}

	return { id, requestArray };
};

export const checkIfGrandPrixProduct = (id?: string | number) =>
	GRAND_PRIX.ALL_TGIDS.includes(String(id));

export const checkIfBahrainGrandPrixProduct = (id?: string | number) =>
	String(id) === GRAND_PRIX.BAHRAIN_GP.TGID;

export const checkIfSaudiGrandPrixProduct = (id?: string | number) =>
	String(id) === GRAND_PRIX.SAUDI_GP.TGID;

export const checkIfArabianGrandPrixProduct = (id?: string | number) =>
	checkIfBahrainGrandPrixProduct(id) || checkIfSaudiGrandPrixProduct(id);

export const getSeatNumbers = (
	savedSessions: any,
	id: any,
	selectedVariantId: any,
) => {
	const seatsList = savedSessions?.[id]?.[selectedVariantId]?.seats;

	return seatsList?.map(({ row, seatNumbers }: any) =>
		seatNumbers?.map((seatNum: any) =>
			row && seatNum ? ` ${row}${seatNum}` : '',
		),
	);
};

export const loadCheckoutLibrary = () => {
	const CHECKOUT_SCRIPT_ID = 'cko_script_tag';
	if (!document.getElementById(CHECKOUT_SCRIPT_ID)) {
		embedScript({
			id: CHECKOUT_SCRIPT_ID,
			type: 'text/javascript',
			scriptAsync: true,
			scriptSrc: 'https://cdn.checkout.com/js/framesv2.min.js',
			isServer: typeof window === 'undefined',
		});
	}
};

export const loadRecaptchaSdk = () => {
	const RECATCHA_SCRIPT_ID = 'recaptcha_script';
	if (!document.getElementById(RECATCHA_SCRIPT_ID)) {
		embedScript({
			id: RECATCHA_SCRIPT_ID,
			type: 'text/javascript',
			scriptAsync: true,
			scriptSrc: `https://www.google.com/recaptcha/enterprise.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}`,
			isServer: typeof window === 'undefined',
		});
	}
};

export const getEntradasTicketDescription = (booking: any) => {
	const { seatMapInfo } = booking;
	const seatsList: Array<string[]> = [];
	const groupedSeatSelections = groupBy(seatMapInfo, 'seatSection');
	const rowMap = {} as any;
	const sectionList = [] as any;

	Object.keys(groupedSeatSelections).forEach(seatSection => {
		const seatInfo = groupedSeatSelections[seatSection];
		seatInfo.forEach((item: any, idx: number) => {
			const { row, seat, area } = item;
			if (row && seat) {
				rowMap[String(row)] = rowMap[String(row)]
					? [...rowMap[String(row)], idx === 0 ? seat : ` ${seat}`]
					: [idx === 0 ? seat : ` ${seat}`];
			} else {
				!sectionList.includes(area) && sectionList.push(area);
			}
		});
	});

	if (Object.keys(rowMap)?.length) {
		Object.keys(rowMap).forEach((item: any) => {
			seatsList.push([`${strings.ROW} ${item}: `, `${rowMap[item]}`]);
		});
	}
	if (sectionList.length) {
		if (Object.keys(rowMap)?.length) {
			seatsList.push([`${strings.SECTION}: `, sectionList.join(', ')]);
		} else return sectionList.join(', ');
	}

	return seatsList;
};

export const getSeatMapTicketDescription = (booking: any) => {
	const { seatMapInfo } = booking;
	const seatsList: Array<string[]> = [];
	const groupedSeatSelections = groupBy(seatMapInfo, 'seatSection');
	Object.keys(groupedSeatSelections).forEach(seatSection => {
		const seatInfo = groupedSeatSelections[seatSection];
		const seatInfoString = seatInfo.map(
			(
				{
					seatRow,
					seatNumber,
				}: {
					seatRow: string;
					seatNumber: string;
				},
				idx: number,
			) =>
				` ${seatRow}${seatNumber}${
					idx !== seatInfo.length - 1 ? ',' : ''
				}`,
		);

		seatsList.push([`${seatSection}:`, seatInfoString]);
	});

	return seatsList;
};

const getPricesWithSelectionsfromSeatmapUtils = (booking: any) => {
	const { seatMapInfo } = booking;
	let pricesWithSelections: any[] = [];
	const groupedSelectionSeats = groupBy(seatMapInfo, 'seatSection');
	Object.keys(groupedSelectionSeats).forEach(selection => {
		const selectionMapInfo = groupedSelectionSeats[selection];
		const groupedPriceSelection = groupBy(selectionMapInfo, 'price');
		Object.values(groupedPriceSelection).forEach((section: any) => {
			pricesWithSelections.push({
				number: section.length,
				type: section?.[0]?.seatSection,
				price: section?.[0]?.price,
				originalPrice: section?.[0]?.price,
			});
		});
	});

	return pricesWithSelections;
};

export const getPricesWithSelection = ({
	booking,
	pricing,
	flowType,
}: {
	booking: any;
	pricing: any;
	flowType: string;
}): {
	number: number;
	type: string;
	price: number;
	originalPrice: number;
	displayName?: string;
}[] => {
	const priceProfile = getPriceProfile(pricing, booking);

	return flowType === BOOKING_FLOW_TYPE.SEATMAP
		? getPricesWithSelectionsfromSeatmapUtils(booking)
		: getPricesWithSelectionsfromBookingUtils(
				booking,
				pricing,
				priceProfile,
		  );
};

export const getPricePayableBreakup: any = (breakup: any, pricing: any) =>
	breakup?.map((tourBreakup: any) => {
		const { pricePayableBreakup, tourId } = tourBreakup;
		const extraCharges = pricePayableBreakup?.find(
			(item: any) => item?.type === 'EXTRA_CHARGES',
		);
		const tour = getTour(pricing, tourId);

		return {
			tourId,
			extraCharges,
			tourName: tour?.variantName,
		};
	});

export const getTourSlotDetails = (selectionState: any, delimeter?: string) => {
	let editSectionText = '';
	if (selectionState) {
		const { selectedTourDate, selectedTourTime } = selectionState;
		const dayjsDate = dayjs(selectedTourDate);
		const day = getDayName(dayjsDate);
		const formattedDate = dayjsDate.format('D MMMM, YYYY');

		if (selectedTourTime === FLEXIBLE_START_TIME) {
			editSectionText = `${day}, ${formattedDate}`;
		} else {
			editSectionText = `${day}, ${formattedDate} ${
				delimeter || strings.AT
			} ${formatTime(selectedTourTime)}`;
		}
	}
	return editSectionText;
};

export const hasReservedSessionExpired = ({ product, booking }: any) => {
	const savedSessions = JSON.parse(
		readFromSessionStore(RESERVATION_SESSION_STORAGE_KEY),
	);

	const { id } = product;
	const { selectedVariantId } = booking;
	if (
		!savedSessions ||
		!savedSessions[id] ||
		!savedSessions[id][selectedVariantId]
	)
		return true;
	const expiryTime = new Date(
			savedSessions[id][selectedVariantId].reserveSessionExpiry,
		),
		currentTime = new Date();
	return currentTime > expiryTime;
};

export const isExternalSeatmapProduct = (product: any) =>
	product?.flowType === 'SEATMAP_IFRAME';

export async function fetchExternalSeatmap(
	tourId: number,
	selectedDate: string,
	selectedTime: string,
) {
	try {
		const response = await fetchWrapper(
			`${getApiCDNBaseUrl({})}/api/v1/seatmap-iframe/fetch-iframe`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					tourId: tourId,
					date: selectedDate,
					time: selectedTime,
				}),
			},
		);
		const data = await response.json();
		return data.iframeLink;
	} catch (error) {
		notify.show('Failed to fetch seatmap', 'error', 3000);
	}
}

export const mapBroadwayInboundSectionName = (section: string) => {
	switch (true) {
		case isSubstring(section, 'ORCH', true):
			return broadwaySectionNames.ORCH;
		case isSubstring(section, 'FMEZ', true):
			return broadwaySectionNames.FMEZ;
		case isSubstring(section, 'RMEZ', true):
			return broadwaySectionNames.RMEZ;
		case isSubstring(section, 'MMEZ', true):
			return broadwaySectionNames.MMEZ;
		case isSubstring(section, 'MEZ', true):
			return broadwaySectionNames.MEZ;
		case isSubstring(section, 'BALC', true):
			return broadwaySectionNames.BALC;
		case isSubstring(section, 'DRESS', true):
			return broadwaySectionNames.DRESS;
		default:
			trackEvent({ eventName: 'Broadway missing section code', section });
			return '';
	}
};

export const mapBroadwayInboundSectionColors = (section: string) => {
	switch (section) {
		case broadwaySectionNames.ORCH:
			return '#bd005b';
		case broadwaySectionNames.DRESS:
			return '#0F43BD';
		case broadwaySectionNames.FMEZ:
		case broadwaySectionNames.RMEZ:
		case broadwaySectionNames.MEZ:
		case broadwaySectionNames.MMEZ:
			return '#a83f0b';
		case broadwaySectionNames.BALC:
			return '#f48c57';
		default:
			return '';
	}
};

export const mapBroadwayInboundSectionToDisplayName = (section: string) => {
	switch (section) {
		case broadwaySectionNames.ORCH:
			return 'Orchestra';
		case broadwaySectionNames.DRESS:
			return 'Dress Circle';
		case broadwaySectionNames.FMEZ:
			return 'Front Mezzanine';
		case broadwaySectionNames.RMEZ:
			return 'Rear Mezzanine';
		case broadwaySectionNames.MMEZ:
			return 'Mid Mezzanine';
		case broadwaySectionNames.MEZ:
			return 'Mezzanine';
		case broadwaySectionNames.BALC:
			return 'Balcony';
		default:
			return '';
	}
};

export const getAvailableToursCount = (inventoryData: any, date: any) => {
	const { availableTourIds } = inventoryData?.inventoryMap?.[date] ?? {};

	return Array.isArray(availableTourIds) ? availableTourIds.length : null;
};

export const getValidatedTourTime = ({
	pricing,
	tourId,
	selectedDate,
	selectedTime,
}: {
	pricing: any;
	tourId: string;
	selectedDate: string;
	selectedTime: string;
}) => {
	if (selectedTime === FLEXIBLE_START_TIME) {
		const { startTime } = getFirstSlotForSelectedTour({
			pricing,
			selectedDate,
			tourId,
		});

		return startTime;
	}

	return selectedTime;
};

export const getToursForLiveInventoryCheck = ({
	tourIds,
	booking,
	pricing,
}: {
	tourIds?: string[];
	booking: Record<string, any>;
	pricing: Record<string, any>;
}): LiveInventoryPropsType[] =>
	(tourIds ?? [])?.map((tourId: string) => {
		const { selectedTourDate, selectedTourTime, tourSelectionMap } =
			getTourSelectionState(booking, tourId) ?? {};

		const inventoryLocalTime = getValidatedTourTime({
			pricing,
			tourId,
			selectedDate: selectedTourDate,
			selectedTime: selectedTourTime,
		});

		return {
			tourId,
			inventoryLocalDate: selectedTourDate,
			inventoryLocalTime,
			bookingFlowType: 'VARIANT',
			paxToCountMap: tourSelectionMap,
		};
	});

export const validateLiveInventoryFromCache = (
	cookies: {
		[key: string]: string;
	},
	id: string,
) => {
	const failedLiveInventoryIdsCookie: string | undefined =
		cookies[COOKIE.LIVE_INVENTORY];
	const failedLiveInventoryIds = failedLiveInventoryIdsCookie
		? failedLiveInventoryIdsCookie.split(COOKIE_DELIMITER)
		: [];

	return failedLiveInventoryIds.includes(id);
};

export const getUserFieldQAMarkers = (identifier: string) => ({
	title: identifier,
	subtext: `${identifier}-subtext`,
	error: `${identifier}-error`,
	inputBox: `${identifier}-input-box`,
	label: `${identifier}-label`,
});

export const saveBroadwaySoldOutSectionSession = ({
	tgid,
	date,
	time,
	tourId,
}: {
	tgid?: string;
	date: string;
	time: string;
	tourId: string;
}) => {
	Cookies.set(
		null,
		`broadway-soldout-${tgid}-${date}-${time}-${tourId}`,
		'true',
		{
			httpOnly: false,
			path: '/',
			maxAge: TIME.SECONDS_IN_HOUR * 0.5,
		},
	);
};

export const isBroadwaySectionSoldOut = ({
	tgid,
	date,
	time,
	tourId,
}: {
	tgid?: string;
	date: string;
	time: string;
	tourId: string;
}) => Cookies.get()?.[`broadway-soldout-${tgid}-${date}-${time}-${tourId}`];

export const getSelectedTour = ({
	pricing,
	booking,
}: {
	pricing: any;
	booking: any;
}) => {
	const { selectedDate, selectedTime, selectedTourId } = booking;
	const { inventoryMapByDateTime } = pricing;

	return inventoryMapByDateTime?.[selectedDate]?.[selectedTime]?.find(
		(tour: any) => tour.tourId === selectedTourId,
	);
};

export const getBroadwayVariantPrice = ({
	pricing,
	booking,
	lang,
}: {
	pricing: any;
	booking: any;
	lang: string;
}) => {
	const { currency } = pricing;
	const { selectionMap } = booking;
	const numPax = getSelectedNumberFromMap(
		getDefaultPaxType(pricing),
		selectionMap,
	);
	const selectedTour = getSelectedTour({ pricing, booking });

	if (!selectedTour) return null;

	const price = getListingPriceFromPriceProfile(selectedTour.priceProfile);

	return getLocalisedPrice(Number(numPax * price), currency, lang);
};

export const getDefaultPriceProfileData = ({
	pricing,
	booking,
}: {
	pricing: any;
	booking: any;
}) => {
	const selectedTour = getSelectedTour({ pricing, booking });

	if (!selectedTour) return null;

	const { priceProfileType, persons, groups } = selectedTour.priceProfile;

	if (priceProfileType === PROFILE_TYPE.PER_PERSON) {
		return persons?.[0];
	}

	return groups?.[0];
};

export const getBroadwaySeatVariantName = ({
	product,
	selectedVariantId,
}: {
	product: any;
	selectedVariantId: string;
}) =>
	product.variants.filter(
		(variant: any) => variant.id === selectedVariantId,
	)[0]?.name;

export const getDefaultPaxTypeMap = (
	priceProfile: Record<string, any> | null,
) => {
	const profileType = priceProfile?.persons ?? [];

	const isGroupTypeProduct =
		priceProfile?.priceProfileType === PROFILE_TYPE.PER_GROUP;
	const primaryProfile = profileType[0] ?? {};

	const defaultPaxType = isGroupTypeProduct
		? PAX_TYPES.GROUP
		: PAX_TYPES.ADULT;
	const primaryPaxType = primaryProfile.type ?? defaultPaxType;

	return { [primaryPaxType]: 2 };
};

export const areAllBroadwaySectionsSoldOut = ({
	pricing,
	tgid,
	date,
	time,
}: {
	pricing: any;
	tgid: string;
	date: string;
	time: string;
}) => {
	const inventoryMap = pricing?.inventoryMapByDateTime?.[date]?.[time];
	return inventoryMap.reduce(
		(acc: any, tour: any) =>
			acc &&
			isBroadwaySectionSoldOut({
				tgid,
				date,
				time,
				tourId: tour.tourId,
			}),
		true,
	);
};

export const getClosestDatesAvailable = ({
	tourId,
	inventoryData,
	selectedDate,
}: {
	tourId: number;
	inventoryData: any;
	selectedDate: string;
}) => {
	const { sortedInventoryDates, inventoryMap } = inventoryData;
	let nextAvailableDates: any[] = [],
		prevAvailableDates: any[] = [];

	sortedInventoryDates.forEach((date: any) => {
		const isAvailable =
			inventoryMap?.[date]?.availableTourIds?.includes(tourId);

		if (!isAvailable) return;

		if (date >= selectedDate && inventoryMap?.[date]) {
			nextAvailableDates.push(date);
		} else {
			prevAvailableDates.unshift(date); //Check for previous available inventory in case no next available inventory is present
		}
	});

	let isFutureDate = !!nextAvailableDates.length;
	const [nextAvailableDate] = nextAvailableDates;
	const [prevAvailableDate] = prevAvailableDates;
	let closestAvailableDate = isFutureDate
		? nextAvailableDate
		: prevAvailableDate;

	if (nextAvailableDates.length && prevAvailableDates.length) {
		const selectedDateInMs = new Date(selectedDate).getTime();
		const nextAvailableDateInMs = new Date(nextAvailableDate).getTime();
		const prevAvailableDateInMs = new Date(prevAvailableDate).getTime();
		const selectedToPrevDelta = Math.abs(
			selectedDateInMs - prevAvailableDateInMs,
		);
		const selectedToNextDelta = Math.abs(
			selectedDateInMs - nextAvailableDateInMs,
		);

		if (selectedToPrevDelta > selectedToNextDelta) {
			isFutureDate = true;
			closestAvailableDate = nextAvailableDate;
		} else {
			isFutureDate = false;
			closestAvailableDate = prevAvailableDate;
		}
	}

	return {
		closestAvailableDate,
		isFutureDate,
	};
};

export const getClosestAvailableInventory = ({
	closestAvailableDate,
	tourId,
	pricing,
}: {
	closestAvailableDate: any;
	tourId: any;
	pricing: any;
}) => {
	const { inventoryMap } = pricing;
	return inventoryMap?.[closestAvailableDate]?.[tourId]?.[0];
};

const getClosestAvailableInfo = ({
	tourId,
	pricing,
	inventoryData,
	selectedDate,
}: {
	tourId: any;
	pricing: any;
	inventoryData: any;
	selectedDate: string;
}) => {
	const { closestAvailableDate, isFutureDate } = getClosestDatesAvailable({
		tourId: Number(tourId),
		inventoryData,
		selectedDate,
	});
	if (closestAvailableDate) {
		return {
			closestAvailableDate: dayjs(
				localDateToJsDate(closestAvailableDate),
			).format('Do MMM'),
			closestAvailableInventory: getClosestAvailableInventory({
				closestAvailableDate,
				tourId,
				pricing,
			}),
			closestAvailableStrDate: closestAvailableDate,
			isFutureDate,
		};
	}
	return null;
};

export const getAdditionalProperty = (tour: any, type: string) => {
	switch (type) {
		case TOUR_PROPERTIES.LANG:
			return getGuidedTourLanguageCodes(tour);
		default:
			const properties = tour?.additionalPropertiesV2
				?.map((item: any) => {
					if (item.type === type) {
						return item.value;
					}
					return null;
				})
				.filter((code: string | null) => code !== null);
			if (properties?.length) return properties;
	}
	return null;
};

export const getGuidedTourLanguageCodes = (tour: any) => {
	return tour?.additionalPropertiesV2
		?.map((item: any) => {
			if (item.type === TOUR_PROPERTIES.LANG) {
				return item.value;
			}
			return null;
		})
		.filter((code: string | null) => code !== null);
};

export const getFilterVsTourIdMapping = ({
	pricing,
	filterType,
}: {
	pricing: any;
	filterType: string;
}) => {
	const filterListVsTourIdMapping: Record<string, number[]> = {};
	Object.entries(generateTourIdPropertiesMap(pricing?.tours)).forEach(
		([tourId, obj]: any) => {
			const filterList = obj.map((item: any) => {
				if (item.type === filterType) {
					return item.value;
				}
			});

			filterList.forEach((item: any) => {
				if (!item) return;
				if (filterListVsTourIdMapping[item])
					filterListVsTourIdMapping[item].push(tourId);
				else filterListVsTourIdMapping[item] = [tourId];
			});
		},
	);

	return filterListVsTourIdMapping;
};

export const getFilteredVariantList = ({
	pricing,
	selectedDate,
	inventoryData,
	filterAdditionalProperty,
}: {
	pricing: any;
	selectedDate: any;
	inventoryData: any;
	filterAdditionalProperty?: string;
}) => {
	const { inventoryMap, tourMap } = pricing;
	const allKeys = tourMap ? takeOutKeys(tourMap) : [];
	const availableVariants: any[] = [];
	let unavailableVariants: any[] = [];
	const additionalProperties: string[] = [];
	const propertyVsTourIdMapping = getFilterVsTourIdMapping({
		pricing,
		filterType: filterAdditionalProperty ?? '',
	});
	if (selectedDate) {
		const inventoryByDate = inventoryMap?.[selectedDate];
		Object.keys(inventoryByDate || {}).forEach(tourIdStr => {
			const tourId = String(tourIdStr);
			const tour = tourMap?.[String(tourId)]?.[0];
			const variantId = tour?.variantId;
			const tourAdditionalProperties: string[] = getAdditionalProperty(
				tour,
				filterAdditionalProperty ?? '',
			);
			if (filterAdditionalProperty && !tourAdditionalProperties?.length)
				return;
			const inv = filterAdditionalProperty
				? propertyVsTourIdMapping[tourAdditionalProperties[0]].flatMap(
						(tourId: number) => inventoryByDate[tourId],
				  )
				: inventoryByDate[tourId];
			const priceOf = (inventory: any) => {
				if (!inventory?.priceProfile) return Number.MAX_SAFE_INTEGER;
				return getListingPriceFromPriceProfile(inventory?.priceProfile);
			};
			let minPriceInv = inv.reduce((current: any, prev: any) => {
				return priceOf(current) < priceOf(prev) ? current : prev;
			}, {});

			const hasOtherPrices = inv.some(
				(thisInv: any) => priceOf(thisInv) !== priceOf(minPriceInv),
			);
			const firstSlot = inv?.[0];

			availableVariants.push({
				tourId,
				variantId,
				isAvailable: true,
				closestAvailable: null,
				tour,
				inventory: minPriceInv,
				scheduleInventory: firstSlot,
				hasOtherPrices,
				hasDuplicateProperty: tourAdditionalProperties
					?.map(langProperty =>
						additionalProperties.includes(langProperty),
					)
					?.includes(false),
			});
			if (
				filterAdditionalProperty &&
				!tourAdditionalProperties
					?.map(langProperty => {
						return additionalProperties.includes(langProperty);
					})
					.includes(false)
			) {
				tourAdditionalProperties?.forEach(langProperty => {
					!additionalProperties.includes(langProperty) &&
						additionalProperties.push(langProperty);
				});
			}
		});

		const availableTourKeys = availableVariants.map(
			variant => variant.tourId,
		);
		const unavailableTourKeys = allKeys
			.filter(x => {
				let isTourValid = !availableTourKeys.includes(x);
				if (isTourValid && filterAdditionalProperty) {
					const tourFilterList = getAdditionalProperty(
						tourMap[x][0],
						filterAdditionalProperty ?? '',
					);
					isTourValid =
						isTourValid &&
						tourFilterList &&
						!tourFilterList.some((lang: string) =>
							additionalProperties.includes(lang),
						);

					tourFilterList?.forEach((lang: string) => {
						if (additionalProperties.includes(lang)) return;
						additionalProperties.push(lang);
					});
				}
				return isTourValid;
			})
			.sort((a: any, b: any) => Number(a.tourId) - Number(b.tourId));

		unavailableVariants = unavailableTourKeys.map(tourId => {
			const tour = tourMap?.[String(tourId)]?.[0];
			const variantId = tour?.variantId;
			const closestInfo = getClosestAvailableInfo({
				tourId,
				inventoryData,
				selectedDate,
				pricing,
			});

			return {
				tourId,
				variantId,
				closestAvailable: closestInfo,
				tour,
				inventory: closestInfo
					? closestInfo.closestAvailableInventory
					: null,
				scheduleInventory: closestInfo
					? closestInfo.closestAvailableInventory
					: null,
				offer: false,
			};
		});
	} else {
		return null;
	}
	return availableVariants.concat(unavailableVariants);
};

export const getVariantOrdering = ({
	variantList,
	pricing,
	lang,
	selectedDate,
	sortByLanguage = true,
}: {
	variantList: any;
	pricing: any;
	lang?: string;
	selectedDate: string;
	sortByLanguage?: boolean;
}) => {
	const { currency } = pricing;

	/* Sorting logic:
		1. Sort by Price (if unavailable sort by next available price)
		2. If next available not present, keep at last
	*/
	const variantSortingLogic = (a: any, b: any) => {
		const sortByProximity = (a: any, b: any) => {
			if (!a.closestAvailable && !b.closestAvailable) {
				return 0;
			} else if (!a.closestAvailable && b.closestAvailable) {
				return 1;
			} else if (a.closestAvailable && !b.closestAvailable) {
				return -1;
			} else {
				const aDate = localDateToJsDate(
					a.closestAvailable.closestAvailableStrDate,
				);
				const bDate = localDateToJsDate(
					b.closestAvailable.closestAvailableStrDate,
				);
				const currentDate = localDateToJsDate(selectedDate);
				const aDifference = Math.abs(
					aDate.getTime() - currentDate.getTime(),
				);
				const bDifference = Math.abs(
					bDate.getTime() - currentDate.getTime(),
				);

				return aDifference - bDifference;
			}
		};

		const sortByTourLanguage = () => {
			const tourALangList = getAdditionalProperty(
				a.tour,
				TOUR_PROPERTIES.LANG,
			);
			const tourBLangList = getAdditionalProperty(
				b.tour,
				TOUR_PROPERTIES.LANG,
			);

			if (lang && tourALangList && tourBLangList) {
				if (
					tourALangList.includes(lang.toUpperCase()) &&
					!tourBLangList.includes(lang.toUpperCase())
				) {
					return -1;
				} else if (
					tourBLangList.includes(lang.toUpperCase()) &&
					!tourALangList.includes(lang.toUpperCase())
				) {
					return 1;
				}
			}
			return null;
		};
		const tourLanguageSort = sortByTourLanguage();
		if (sortByLanguage && tourLanguageSort) return tourLanguageSort;
		if (a.isAvailable && !b.isAvailable) {
			return -1;
		} else if (!a.isAvailable && b.isAvailable) {
			return 1;
		} else if (a.isAvailable && b.isAvailable) {
			if (a.offer && !b.offer) {
				return -1; // a < b
			} else if (!a.offer && b.offer) {
				return 1; // a > b
			} else if ((a.offer && b.offer) || (!a.offer && !b.offer)) {
				const { price: priceOfA }: any = getPriceTag(
					a.inventory,
					currency,
				);
				const { price: priceOfB }: any = getPriceTag(
					b.inventory,
					currency,
				);
				// if same price then sort according to start time
				if (priceOfA === priceOfB) {
					const aStartTime = a.scheduleInventory?.startTime;
					const bStartTime = b.scheduleInventory?.startTime;

					if (aStartTime < bStartTime) {
						return -1;
					}

					return 1;
				}

				return priceOfA - priceOfB;
			}
		} else {
			if (!a.closestAvailable && !!b.closestAvailable) {
				return 1;
			} else if (!!a.closestAvailable && !b.closestAvailable) {
				return -1;
			} else {
				const proximityComparison = sortByProximity(a, b);

				if (proximityComparison !== 0) {
					return proximityComparison;
				} else {
					const { price: priceOfA = 0 }: any =
						getPriceTag(a.inventory, currency) ?? {};
					const { price: priceOfB = 0 }: any =
						getPriceTag(b.inventory, currency) ?? {};

					return priceOfA - priceOfB;
				}
			}
		}
		// both inventory unavailable case, assume equal, no sorting logic
		return 0;
	};
	return variantList.sort(variantSortingLogic);
};

export const getPropertyToursFiltered = ({
	variantsList,
	filterSelected,
	filterType,
}: {
	variantsList: any;
	filterSelected?: string;
	filterType: string;
}) => {
	return variantsList.filter((variant: any) => {
		const languageList = variant?.tour?.additionalPropertiesV2
			?.filter((item: any) => item.type === filterType)
			.map((item: any) => item.value);

		if (!languageList?.length || filterSelected === undefined) return true;

		return languageList.includes(filterSelected);
	});
};

export const checkNonFilterToursExist = ({
	pricing,
	filterType,
}: {
	pricing: any;
	filterType: string;
}) => {
	const { tourIds } = pricing;
	const filterVsTourIdMapping = getFilterVsTourIdMapping({
		pricing,
		filterType,
	});
	const tourIdsWithLanguage = Object.values(filterVsTourIdMapping).flatMap(
		(tourIds: number[]) => tourIds,
	);

	return new Set(tourIds)?.size !== new Set(tourIdsWithLanguage)?.size;
};

/**
 * Cleans up the variant information by removing empty lines and adding HTML tags if necessary.
 *
 * @param variantInfo The variant information to be cleaned up.
 * @returns The cleaned up variant information.
 */
export const cleanupVariantInfo = (variantInfo: string) => {
	const emptyLinesTrimmedVariantInfo = variantInfo?.replace(
		/^\s*[\r\n]/gm,
		'',
	);
	const finalVariantInfo = emptyLinesTrimmedVariantInfo
		?.trim()
		?.startsWith('<li>')
		? `<ul>${emptyLinesTrimmedVariantInfo}</ul>`
		: emptyLinesTrimmedVariantInfo;

	return finalVariantInfo;
};
export const setNewUserFieldsToStore = ({
	dispatch,
	booking,
	pricing,
	tourId,
	id,
}: {
	dispatch: any;
	pricing: any;
	booking: any;
	tourId: any;
	id: string;
}) => {
	const tourSelections = getTourSelectionState(booking, tourId);
	if (tourSelections) {
		const {
			tourUserFields: oldUserFields,
			tourSelectionMap,
			tourGroupSize,
		} = tourSelections;

		const userFields = getUserFieldsArray({
			pricing,
			booking,
			tourId,
			oldUserFields,
			selectionMap: tourSelectionMap,
			groupSize: tourGroupSize,
		});

		dispatch(setTourUserDetails(id, tourId, userFields));
	}
};

export const getSortedFilterList = ({
	sortedVariantList,
	priorityFilterType,
}: any) => {
	const hashMap: Record<string, number> = {};
	const uniqueFilters = sortedVariantList
		.map((variant: any) => {
			const tourFilters: string[] = getAdditionalProperty(
				variant.tour,
				priorityFilterType,
			);
			if (
				variant.hasDuplicateProperty ||
				!tourFilters ||
				tourFilters?.length === 0
			)
				return null;
			return { ...variant, tourFilters };
		})
		.filter((variant: any) => Boolean(variant));

	uniqueFilters.forEach((variant: any) => {
		variant.tourFilters.forEach((filter: string) => {
			if (hashMap[filter]) hashMap[filter] = hashMap[filter] + 1;
			else hashMap[filter] = 1;
		});
	});

	const finalFilters = uniqueFilters.map((variant: any) => {
		variant.tour.additionalPropertiesV2 =
			variant.tour.additionalPropertiesV2.sort(
				(filterA: any, filterB: any) =>
					hashMap[filterA.value] - hashMap[filterB.value],
			);
		return variant;
	});
	return finalFilters;
};

export const checkTourAvailability = (
	tourIds: string[],
	defaultSelectedDate: string,
	id: string,
	state: any,
) => {
	if (!tourIds?.length) return false;

	const inventoryMap = getInventoryMapByTourDate(state, id);
	return tourIds.some(
		tourId =>
			inventoryMap[tourId] &&
			Object.keys(inventoryMap[tourId]).includes(defaultSelectedDate),
	);
};

export const determineLanguageFilter = (
	languageCode: string | null,
	filterVsTourIdMapping: any,
	defaultSelectedDate: string,
	id: string,
	state: any,
): string | null => {
	const targetLanguage = languageCode?.toUpperCase() || 'EN';

	if (!Object.keys(filterVsTourIdMapping).includes(targetLanguage)) {
		return null;
	}

	const isAvailable = checkTourAvailability(
		filterVsTourIdMapping[targetLanguage],
		defaultSelectedDate,
		id,
		state,
	);

	return isAvailable ? targetLanguage : null;
};
