/*eslint sort-keys: ["error", "asc", {natural: true}]*/
import Experiment from 'Utils/experiments/experiment';

export const VARIANTS = {
	CITY_PAGE_OLD: 'Control',
	CITY_PAGE_REVAMPED: 'Treatment',
	CONTROL: 'Control',
	DISABLE_GOOGLE_PAY: 'Control',
	ENABLE_GOOGLE_PAY: 'Treatment',
	HIDE_AUDIO_GUIDE: 'HIDE',
	HIDE_COMBO_UPSELL_SCREEN: 'Control',
	HIDE_FULFILLED_BY_HEADOUT: 'Control',
	HIDE_HOHO_DWEB_REVAMP: 'Control',
	HIDE_HOHO_V2: 'Control',
	HIDE_MULTI_CITIES: 'Control',
	HIDE_NEW_EXPERIENCE_PAGE_DESCRIPTORS: 'Control',
	HIDE_REVAMP_SELECT_PAGE: 'Control',
	HIDE_REVIEW_MEDIA: 'Control',
	HIDE_SCARCITY_BOOSTERS: 'Control',
	LOAD_USER_RECORDING_SCRIPT: 'LOAD',
	NEGLECT_USER_RECORDING_SCRIPT: 'NEGLECT',
	PROXIMITY_CONTROL: 'CONTROL',
	PROXIMITY_TREATMENT: 'TREATMENT',
	SHOW_AUDIO_GUIDE: 'SHOW',
	SHOW_COMBO_UPSELL_SCREEN: 'Treatment',
	SHOW_FULFILLED_BY_HEADOUT: 'Treatment',
	SHOW_HOHO_DWEB_REVAMP: 'Treatment',
	SHOW_HOHO_V2: 'Treatment',
	SHOW_MULTI_CITIES: 'Treatment',
	SHOW_NEW_EXPERIENCE_PAGE_DESCRIPTORS: 'Treatment',
	SHOW_REVAMP_SELECT_PAGE: 'Treatment',
	SHOW_REVIEW_MEDIA: 'Treatment',
	SHOW_SCARCITY_BOOSTERS: 'Treatment',
	TREATMENT: 'Treatment',
	TREATMENT_A: 'Treatment_A',
	TREATMENT_B: 'Treatment_B',
};

const ADD_ON_EXPERIMENT = 'Add-On Experiment';
const HOLIDAY_BANNER_VISIBILITY = 'Holiday ProductBanner Visibility';
const MB_FULLFILLED_BY_HEADOUT = 'Fulfilled By Headout Banner Experiment';
const MULTI_CITIES_CONFIRMATION = 'Cross-City Recommendations Experiment';
const PROXIMITY_CONF_PAGE_EXPERIMENT = 'Confirmation Page Proximity Experiment';
const REVAMP_SELECT_PAGE = 'Revamped Select Page - 2';
const REVIEW_MEDIA_VISIBILITY = 'Review Media Experiment';
const SELECT_SCREEN_PAX = 'Dweb Pax Selection on Select Page 2024';
const SELECT_REVAMP = 'Select Page Revamp 2.6';
const HOHO_REVAMP = 'HOHO Select Page';
const CROSS_SELL_EXPERIMENT = 'Cross Sell Experiment';
const BROADWAY_SVG_EXPERIMENT = 'Broadway Disable Hifi Map Experiment';
const SEARCH_EXPERIMENT = 'Cached Search Results Experiment';
const DAY_TRIPS_COLLECTION_PAGE_REVAMP = 'Day Trips Collection Page Revamp';
const MIXPANEL_SESSION_REPLAY = 'Mixpanel Session Replay';
const HOME_CURRENCY_EXPERIMENT = 'Home Currency Experiment v2';
const TOFU_CITY_PAGE_M2 = 'Tofu City Page Revamp M2';
const TOFU_CITY_PAGE_M3 = 'Tofu City Page Revamp M3';
const SCARCITY_BOOSTERS = 'Scarcity Boosters Experiment';
const CANCELLATION_INSURANCE_EXPERIMENT = 'Cancellation Insurance v1';
const BRAND_LOADER_EXP = 'New Loader Experiment';
const HOP_ON_HOP_OFF_DWEB_REVAMP = 'HOHO Dweb Select Page Revamp 2025';

export const EXPERIMENT_NAMES = {
	ADD_ON_EXPERIMENT,
	BRAND_LOADER_EXP,
	BROADWAY_SVG_EXPERIMENT,
	CANCELLATION_INSURANCE_EXPERIMENT,
	CROSS_SELL_EXPERIMENT,
	DAY_TRIPS_COLLECTION_PAGE_REVAMP,
	HOHO_REVAMP,
	HOLIDAY_BANNER_VISIBILITY,
	HOME_CURRENCY_EXPERIMENT,
	HOP_ON_HOP_OFF_DWEB_REVAMP,
	MB_FULLFILLED_BY_HEADOUT,
	MIXPANEL_SESSION_REPLAY,
	MULTI_CITIES_CONFIRMATION,
	PROXIMITY_CONF_PAGE_EXPERIMENT,
	REVAMP_SELECT_PAGE,
	REVIEW_MEDIA_VISIBILITY,
	SCARCITY_BOOSTERS,
	SEARCH_EXPERIMENT,
	SELECT_REVAMP,
	SELECT_SCREEN_PAX,
	TOFU_CITY_PAGE_M2,
	TOFU_CITY_PAGE_M3,
};

export const EXPERIMENTS = {
	[ADD_ON_EXPERIMENT]: new Experiment(
		ADD_ON_EXPERIMENT,
		[VARIANTS.TREATMENT, VARIANTS.CONTROL],
		[50, 50],
	),
	[BRAND_LOADER_EXP]: new Experiment(
		BRAND_LOADER_EXP,
		[VARIANTS.CONTROL, VARIANTS.TREATMENT],
		[100, 0],
	),

	[BROADWAY_SVG_EXPERIMENT]: new Experiment(
		BROADWAY_SVG_EXPERIMENT,
		[VARIANTS.TREATMENT, VARIANTS.CONTROL],
		[0, 100],
	),
	[CANCELLATION_INSURANCE_EXPERIMENT]: new Experiment(
		CANCELLATION_INSURANCE_EXPERIMENT,
		[VARIANTS.TREATMENT, VARIANTS.CONTROL],
		[50, 50],
	),
	[CROSS_SELL_EXPERIMENT]: new Experiment(
		CROSS_SELL_EXPERIMENT,
		[VARIANTS.TREATMENT, VARIANTS.CONTROL],
		[0, 100],
	),
	[DAY_TRIPS_COLLECTION_PAGE_REVAMP]: new Experiment(
		EXPERIMENT_NAMES.DAY_TRIPS_COLLECTION_PAGE_REVAMP,
		[VARIANTS.CONTROL, VARIANTS.TREATMENT],
		[100, 0],
	),
	[HOME_CURRENCY_EXPERIMENT]: new Experiment(
		HOME_CURRENCY_EXPERIMENT,
		[VARIANTS.TREATMENT, VARIANTS.CONTROL],
		[0, 100],
	),
	[HOP_ON_HOP_OFF_DWEB_REVAMP]: new Experiment(
		HOP_ON_HOP_OFF_DWEB_REVAMP,
		[VARIANTS.HIDE_HOHO_DWEB_REVAMP, VARIANTS.SHOW_HOHO_DWEB_REVAMP],
		[50, 50],
	),
	[MB_FULLFILLED_BY_HEADOUT]: new Experiment(
		MB_FULLFILLED_BY_HEADOUT,
		[
			VARIANTS.HIDE_FULFILLED_BY_HEADOUT,
			VARIANTS.SHOW_FULFILLED_BY_HEADOUT,
		],
		[50, 50],
	),
	[MIXPANEL_SESSION_REPLAY]: new Experiment(
		MIXPANEL_SESSION_REPLAY,
		[VARIANTS.TREATMENT, VARIANTS.CONTROL],
		[5, 95],
	),
	[MULTI_CITIES_CONFIRMATION]: new Experiment(
		MULTI_CITIES_CONFIRMATION,
		[VARIANTS.SHOW_MULTI_CITIES, VARIANTS.HIDE_MULTI_CITIES],
		[50, 50],
	),
	[PROXIMITY_CONF_PAGE_EXPERIMENT]: new Experiment(
		PROXIMITY_CONF_PAGE_EXPERIMENT,
		[VARIANTS.PROXIMITY_TREATMENT, VARIANTS.PROXIMITY_CONTROL],
		[50, 50],
	),
	[REVAMP_SELECT_PAGE]: new Experiment(
		REVAMP_SELECT_PAGE,
		[VARIANTS.SHOW_REVAMP_SELECT_PAGE, VARIANTS.HIDE_REVAMP_SELECT_PAGE],
		[0, 100],
	),
	[REVIEW_MEDIA_VISIBILITY]: new Experiment(
		REVIEW_MEDIA_VISIBILITY,
		[VARIANTS.SHOW_REVIEW_MEDIA, VARIANTS.HIDE_REVIEW_MEDIA],
		[100, 0],
	),
	[SCARCITY_BOOSTERS]: new Experiment(
		SCARCITY_BOOSTERS,
		[VARIANTS.HIDE_SCARCITY_BOOSTERS, VARIANTS.SHOW_SCARCITY_BOOSTERS],
		[100, 0],
	),
	[SEARCH_EXPERIMENT]: new Experiment(
		EXPERIMENT_NAMES.SEARCH_EXPERIMENT,
		[VARIANTS.CONTROL, VARIANTS.TREATMENT],
		[50, 50],
	),
	[SELECT_REVAMP]: new Experiment(
		EXPERIMENT_NAMES.SELECT_REVAMP,
		[VARIANTS.CONTROL, VARIANTS.TREATMENT],
		[90, 10],
	),
	[SELECT_SCREEN_PAX]: new Experiment(
		SELECT_SCREEN_PAX,
		[VARIANTS.TREATMENT, VARIANTS.CONTROL],
		[0, 100],
	),
	[TOFU_CITY_PAGE_M2]: new Experiment(
		EXPERIMENT_NAMES.TOFU_CITY_PAGE_M2,
		[VARIANTS.CITY_PAGE_OLD, VARIANTS.CITY_PAGE_REVAMPED],
		[50, 50],
	),
	[TOFU_CITY_PAGE_M3]: new Experiment(
		EXPERIMENT_NAMES.TOFU_CITY_PAGE_M3,
		[VARIANTS.CITY_PAGE_OLD, VARIANTS.CITY_PAGE_REVAMPED],
		[50, 50],
	),
	USER_RECORDING_SCRIPT_RANDOMIZATION: new Experiment(
		'user_recording_script_randomization',
		[
			VARIANTS.NEGLECT_USER_RECORDING_SCRIPT,
			VARIANTS.LOAD_USER_RECORDING_SCRIPT,
		],
		[98, 2],
	),
};
