import { useState } from 'react';

import type { TSwiperSlideChangedCbProps } from '@headout/espeon/components';

export const useSwiperArrows = (): {
	showLeftArrow: boolean;
	showRightArrow: boolean;
	onSlideChanged: (args: Omit<TSwiperSlideChangedCbProps, 'index'>) => void;
} => {
	const [showLeftArrow, setShowLeftArrow] = useState(false);
	const [showRightArrow, setShowRightArrow] = useState(false);

	const onSlideChanged = ({
		isLeftArrowEnabled,
		isRightArrowEnabled,
	}: Omit<TSwiperSlideChangedCbProps, 'index'>) => {
		setShowLeftArrow(isLeftArrowEnabled);
		setShowRightArrow(isRightArrowEnabled);
	};

	return {
		showLeftArrow,
		showRightArrow,
		onSlideChanged,
	};
};
