import type { ComponentPropsWithRef } from 'react';
import React from 'react';

import { Text } from '@headout/eevee';

type Props = {
	id?: string;
	className?: string;
	onClick?: (...args: any[]) => any;
	children?: React.ReactNode;
	testId?: string;
};

/**
 * Localized h2 component
 * Disable google-translate by default
 * @deprecated Use `NewLH2` instead
 */
const LH2 = ({ id, className, onClick, children, testId }: Props) => (
	<h2
		id={id}
		className={`notranslate ${className || ''}`}
		onClick={onClick}
		{...(testId && { 'data-testid': testId })}
	>
		{children}
	</h2>
);

export default LH2;

export const NewLH2 = ({
	className,
	testId,
	...props
}: ComponentPropsWithRef<typeof Text> & {
	testId?: string;
}) => (
	<Text
		className={`notranslate ${className || ''}`}
		{...props}
		{...(testId && { 'data-testid': testId })}
		as='h2'
	/>
);
