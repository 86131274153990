/* eslint-disable-next-line no-restricted-imports */
import styled, { css, keyframes } from 'styled-components';

import { css as pandaCSS } from '@headout/pixie/css';

import { ArrowButton } from 'Components/desktop/carousel';

import colors from 'Static/typography/colors';

export const StyledArrowButton = styled.button`
	@media (max-width: 768px) {
		display: none;
	}
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	right: 0.75rem;

	background: ${colors.WHITE};
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);

	svg {
		width: 0.625rem;
		height: 0.625rem;
		stroke: ${colors.GREY_DS.G2};
		transition: all 0.2s ease-in-out 0s;
	}

	&.disabled {
		display: none;
	}

	&.prev-button {
		right: initial;
		left: 0.75rem;
		svg {
			transform: translateX(-50%);
			transform: rotate(180deg);
		}
	}

	&:hover {
		&:not(.flat-arrows) {
			box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
		}
	}
`;

const fadeIn = keyframes`
0%{
opacity: 0;
}
100%{
opacity: 1;
}`;

export const StyledContainer = styled.div`
	position: relative;
	height: 100%;
	user-select: none;

	${StyledArrowButton} {
		visibility: hidden;
		opacity: 0;
	}
	&:hover {
		${StyledArrowButton} {
			visibility: visible;
			animation-fill-mode: forwards;
			animation-duration: 0.3s;
			animation-name: ${fadeIn};
		}
	}
	.slider-container {
		height: 100%;
	}
	.slider-frame {
		height: 100% !important;
	}
	.slider-list {
		height: 100%;

		img {
			height: 100% !important;
			object-fit: cover;
		}
	}

	ul {
		list-style: none;
	}

	@media (min-width: 768px) {
		${ArrowButton} {
			justify-content: initial;

			svg {
				width: 0.75rem;
				height: 0.75rem;
			}

			.prev-button {
				margin: 0 0.3125rem 0 0.4375rem;
			}

			.next-button {
				margin: 0 0.4375rem 0 0.3125rem;
			}
		}
	}
`;

export const PagintorContainer = styled.div`
	position: absolute;
	bottom: 0;
	display: flex;
	width: 100%;
	justify-content: center;
`;

export const OpacityAnimation = keyframes`
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`;

const ShadowCss = css`
	position: absolute;
	z-index: 1;
	top: 0;
	width: 10%;
	height: 100%;
	background: linear-gradient(
		90deg,
		rgba(34, 34, 34, 0.2) 0%,
		rgba(34, 34, 34, 0) 100%
	);
	opacity: 0;
	animation: ${OpacityAnimation} 0.3s ease-in-out 0s forwards;
`;

export const LeftArrowShadow = styled.div`
	${ShadowCss};
	left: 0;
`;

export const RightArrowShadow = styled.div`
	${ShadowCss};
	right: 0;
	transform: rotate(180deg);
`;

export const productMediaSlidesWrapperOverrideStyles = pandaCSS.raw({
	pointerEvents: 'none',
});
