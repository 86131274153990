'use client';

import type { ReactNode } from 'react';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components';

import Conditional from 'Components/common/conditional';

import { CloseIcon } from 'Assets/svg/checkout/CloseIcon';

import { MODAL_CLOSE_REASON } from 'Constants/constants';

import colors from 'Static/typography/colors';

// Animation duration in ms
const ANIMATION_DURATION = 500;

const CoreModalContainer = styled.div`
	align-items: center;
	background: rgba(0, 0, 0, 0.5);
	box-sizing: border-box;
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999;

	&.modal-enter {
		background: rgba(0, 0, 0, 0);
	}

	&.modal-enter-active {
		background: rgba(0, 0, 0, 0.5);
		transition: background ${ANIMATION_DURATION}ms
			cubic-bezier(0.7, 0, 0.3, 1);
	}

	&.modal-exit {
		background: rgba(0, 0, 0, 0.5);
	}

	&.modal-exit-active {
		background: rgba(0, 0, 0, 0);
		transition: background ${ANIMATION_DURATION}ms
			cubic-bezier(0.7, 0, 0.3, 1);
	}

	.right-end-modal {
		align-items: flex-start !important;
		justify-content: flex-end !important;
	}
`;

const ModalContent = styled.div`
	align-items: center;
	background: ${colors.WHITE};
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 50%;
	min-width: 80%;
	overflow: auto;
	position: relative;

	&.content-enter {
		transform: translateY(100vh);
	}

	&.content-enter-active {
		transform: translateY(0);
		transition: all ${ANIMATION_DURATION}ms cubic-bezier(0.7, 0, 0.3, 1);
	}

	&.content-exit {
		transform: translateY(0);
	}

	&.content-exit-active {
		transform: translateY(100vh);
		transition: all ${ANIMATION_DURATION}ms cubic-bezier(0.7, 0, 0.3, 1);
	}

	/* Add appear classes */
	&.content-appear {
		transform: translateY(100vh);
	}

	&.content-appear-active {
		transform: translateY(0);
		transition: all ${ANIMATION_DURATION}ms cubic-bezier(0.7, 0, 0.3, 1);
	}

	/* Existing enter/exit classes */
	&.content-enter {
		transform: translateY(100vh);
	}

	&.content-enter-active {
		transform: translateY(0);
		transition: all ${ANIMATION_DURATION}ms cubic-bezier(0.7, 0, 0.3, 1);
	}

	&.display-block {
		display: block;
	}
	&.fixed-width {
		min-width: auto;
		width: 23.4375rem;
	}
	&.large-fixed-width {
		min-width: auto;
		width: 37.5rem;
	}
	&.full-width {
		min-width: auto;
		width: 100vw;
	}
	&.full-width-height {
		height: 100vh;
		min-width: auto;
		width: 100vw;
	}
`;

const CloseButton = styled.div`
	cursor: pointer;
	height: 1.5rem;
	margin: 0.5rem;
	position: absolute;
	right: 0.75rem;
	top: 1.5rem;
	width: 1.5rem;
	z-index: 2;
`;

type OwnProps = {
	containerClassName?: string;
	containerHideClassName?: string;
	contentClassName?: string;
	open?: boolean;
	showCloseButton?: boolean;
	onRequestClose?: (...args: any[]) => any;
	children?: ReactNode;
};

class Modal extends React.Component<OwnProps> {
	static defaultProps = {
		containerClassName: '',
		containerHideClassName: '',
		contentClassName: '',
	};

	componentDidMount() {
		this.toggleBodyOverflow(this.props.open);
		window.addEventListener('keydown', this.handleKeyDown);
	}

	UNSAFE_componentWillReceiveProps(nextProps: any) {
		this.toggleBodyOverflow(nextProps.open);
	}

	componentWillUnmount() {
		this.toggleBodyOverflow(false);
		window.removeEventListener('keydown', this.handleKeyDown);
	}

	bodyHasOverflowHidden() {
		return window.document.body.className.includes('overflow-hidden');
	}

	handleKeyDown = (event: KeyboardEvent) => {
		const { open, onRequestClose } = this.props;
		if (event.key === 'Escape' && open && onRequestClose) {
			onRequestClose();
		}
	};

	toggleBodyOverflow = (isOpen: any) => {
		if (isOpen === true && !this.bodyHasOverflowHidden()) {
			window.document.body.className +=
				window.document.body.className === ''
					? 'overflow-hidden'
					: ' overflow-hidden';
		} else if (!isOpen) {
			window.document.body.className =
				window.document.body.className.replace(
					/(?:\soverflow-hidden|overflow-hidden\s|^overflow-hidden)/,
					'',
				);
		}
	};

	render() {
		const {
			open,
			containerClassName,
			contentClassName,
			onRequestClose,
			children,
			// @ts-expect-error TS(2339): Property 'dimensionsStyle' does not exist on type ... Remove this comment to see the full error message
			dimensionsStyle,
			showCloseButton,
		} = this.props;

		return (
			<CSSTransition
				in={open}
				timeout={ANIMATION_DURATION}
				classNames='modal'
				unmountOnExit
			>
				<CoreModalContainer
					className={`core-modal-container ${containerClassName}`}
					onClick={e => {
						e.preventDefault();
						if (onRequestClose) {
							onRequestClose(MODAL_CLOSE_REASON.CLICK_OUTSIDE, e);
						}
					}}
				>
					<CSSTransition
						in={open}
						appear={true}
						timeout={ANIMATION_DURATION}
						classNames='content'
					>
						<ModalContent
							className={`core-modal-content ${contentClassName}`}
							onClick={e => {
								e.stopPropagation();
								e.preventDefault();
							}}
							style={dimensionsStyle}
						>
							<Conditional if={showCloseButton}>
								<CloseButton
									className='modal-close-button'
									onClick={() =>
										onRequestClose?.(
											MODAL_CLOSE_REASON.CLOSE_BUTTON,
										)
									}
								>
									<CloseIcon />
								</CloseButton>
							</Conditional>
							{children}
						</ModalContent>
					</CSSTransition>
				</CoreModalContainer>
			</CSSTransition>
		);
	}
}

export default Modal;
