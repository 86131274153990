import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import dynamic from 'next/dynamic';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'ouib... Remove this comment to see the full error message
import exitIntentDetector from 'ouibounce';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import type { TSwiperRefActions } from '@headout/espeon/components';

import withCookies from 'HOC/withCookies';

import { SectionHeaderRightContainer } from 'Containers/desktop/citiesFeedPage';

import CityCardList from 'Components/common/cityCardListV2';
import Conditional from 'Components/common/conditional';
import FeedSectionHeader from 'Components/common/feedSectionHeader';
import WhyHeadoutSection from 'Components/common/globalHeadoutPerksSection';
import ProductCardList from 'Components/common/productCardList';
import StaticBanner from 'Components/common/staticBanner';
import ThingsToDoSection from 'Components/common/thingsToDoSection';
import { TrackScrollIntoView } from 'Components/common/trackInViewWrapper';
import DownloadAppSection from 'Components/desktop/downloadAppSection';
import NavigationButtons from 'Components/desktop/navigationButtons';
import RecentlyViewedProductList from 'Components/desktop/recentlyViewedListWrapper';
import TopAttractionSection from 'Components/desktop/topAttractionSection';
import ViewAllLink from 'Components/desktop/viewAllLink';

import { useSwiperArrows } from 'Hooks/useSwiperArrows';
import {
	getExitIntentConfiguration,
	trackEvent,
	trackSectionViews,
} from 'Utils/analytics';
import { createSanitisedIdentifer } from 'Utils/gen';
import HelmetUtilities from 'Utils/helmetUtils';
import {
	mapDispatchToProps,
	mapStateToProps,
	sendHomePageViewEvent,
} from 'Utils/homePage';

import { ANALYTICS_EVENTS, HOME_PAGE } from 'Constants/analytics';
import {
	BANNER_HOMEPAGE,
	GLOBAL_CITY_CODE,
	HOME_PAGE_CONTENT_START_ID,
	SEARCH_LOCATION,
} from 'Constants/constants';
import { strings } from 'Constants/strings';

const ImagePromoBanner = dynamic(
	() =>
		import(
			/* webpackChunkName: 'ImagePromoBanner' */ 'Components/common/imagePromoBanner'
		),
	{ ssr: false },
);
const ReviewsSection = dynamic(
	() => import('Components/desktop/globalReviewSection'),
);

const PartnersSection = dynamic(
	() => import('Components/common/partnersSection'),
	{
		ssr: false,
	},
);

/* Styled Components */
export const SpaceBlock = styled.div<{ space: number }>`
	height: ${props => props.space}rem;
`;

type HomePageProps = {
	citiesMap?: any;
	bannerImages?: any[];
	bannerImageClickUrls?: any[];
	inTransition?: boolean;
	user?: any;
	cookies?: any;
	host?: string;
	countryCode?: string;
	headoutPicksProductIds: number[];
	location?: any;
	helmetFunctions?: any;
	languageCode: string;
};

const HomePage = ({
	inTransition,
	headoutPicksProductIds,
	location,
	helmetFunctions,
	languageCode,
}: HomePageProps) => {
	const headoutPicksCarouselRef = useRef<TSwiperRefActions>(null);
	const citiesCarouselRef = useRef<TSwiperRefActions>(null);

	const headoutPicksSwiperProps = useSwiperArrows();
	const citiesSwiperProps = useSwiperArrows();

	useEffect(() => {
		sendHomePageViewEvent();
		exitIntentDetector(false, getExitIntentConfiguration());
	}, []);

	if (inTransition) return null;

	const { query } = location;
	const { lang } = query;

	return (
		<div className='feed-page-wrapper'>
			<HelmetUtilities {...helmetFunctions} />
			<StaticBanner
				banner={BANNER_HOMEPAGE}
				searchBarProps={{
					searchLocation: SEARCH_LOCATION.LEFT,
					location,
					autoScrollOnFocus: true,
				}}
			/>
			<SpaceBlock id={HOME_PAGE_CONTENT_START_ID} space={4} />
			<WhyHeadoutSection />
			<SpaceBlock space={4} />
			<RecentlyViewedProductList
				lang={lang}
				className='home-page'
				onScrollIntoView={trackSectionViews(
					ANALYTICS_EVENTS.HOME_PAGE.SECTION_VIEWED,
					HOME_PAGE.RECENTLY_VIEWED,
				)}
				title={strings.CTP_CFP_RECENTLY_CHECKED}
			/>
			<div className='core-column-container'>
				<FeedSectionHeader
					title={strings.HOME_PAGE.CITIES_HEADING}
					onScrollIntoView={trackSectionViews(
						ANALYTICS_EVENTS.HOME_PAGE.SECTION_VIEWED,
						HOME_PAGE.TOP_DESTINATIONS,
					)}
				>
					<SectionHeaderRightContainer>
						<ViewAllLink
							to={`${
								lang && lang !== 'en' ? `/${lang}` : ''
							}/cities/`}
						/>
						<NavigationButtons
							showLeftArrow={citiesSwiperProps.showLeftArrow}
							showRightArrow={citiesSwiperProps.showRightArrow}
							prevSlide={citiesCarouselRef.current?.prevSlide}
							nextSlide={citiesCarouselRef.current?.nextSlide}
						/>
					</SectionHeaderRightContainer>
				</FeedSectionHeader>
				<CityCardList
					renderImagesInServer
					isHomePage
					paramLang={lang}
					citiesCarouselRef={citiesCarouselRef}
					onSlideChanged={citiesSwiperProps.onSlideChanged}
				/>
			</div>

			<Conditional if={!lang || lang === 'en'}>
				<TrackScrollIntoView
					callback={trackSectionViews(
						ANALYTICS_EVENTS.HOME_PAGE.SECTION_VIEWED,
						HOME_PAGE.PROMO_BANNER.DISNEY_PARIS,
					)}
				>
					<div className='core-column-container'>
						<ImagePromoBanner
							onBannerClick={_event => {
								trackEvent({
									eventName:
										ANALYTICS_EVENTS.HOME_PAGE.CTA_CLICKED,
									'CTA Type': 'Banner',
									Label: 'Book now',
									Section:
										HOME_PAGE.PROMO_BANNER.DISNEY_PARIS,
								});
							}}
						/>
					</div>
				</TrackScrollIntoView>
			</Conditional>

			<div className='core-column-container'>
				<FeedSectionHeader
					onScrollIntoView={trackSectionViews(
						ANALYTICS_EVENTS.HOME_PAGE.SECTION_VIEWED,
						HOME_PAGE.TOP_RECOMMENDATIONS,
					)}
					title={strings.CTP_CFP_HEADOUT_PICKS.title}
				>
					<NavigationButtons
						showLeftArrow={headoutPicksSwiperProps.showLeftArrow}
						showRightArrow={headoutPicksSwiperProps.showRightArrow}
						prevSlide={headoutPicksCarouselRef.current?.prevSlide}
						nextSlide={headoutPicksCarouselRef.current?.nextSlide}
					/>
				</FeedSectionHeader>
				<ProductCardList
					productIds={headoutPicksProductIds}
					showCityName
					productCarouselRef={headoutPicksCarouselRef}
					onSlideChanged={headoutPicksSwiperProps.onSlideChanged}
					parentIdentifier={createSanitisedIdentifer(
						strings.CTP_CFP_HEADOUT_PICKS.title,
					)}
				/>
			</div>
			<TopAttractionSection
				lang={lang}
				isGlobalHomePage
				cityCode={GLOBAL_CITY_CODE}
				onScrollIntoView={trackSectionViews(
					ANALYTICS_EVENTS.HOME_PAGE.SECTION_VIEWED,
					HOME_PAGE.TOP_THINGS_TO_DO_WORLDWIDE,
				)}
			/>
			<Conditional if={!lang || lang === 'en'}>
				<ReviewsSection
					onScrollIntoView={trackSectionViews(
						ANALYTICS_EVENTS.HOME_PAGE.SECTION_VIEWED,
						HOME_PAGE.REVIEWS,
					)}
				/>
			</Conditional>
			<PartnersSection
				onScrollIntoView={trackSectionViews(
					ANALYTICS_EVENTS.HOME_PAGE.SECTION_VIEWED,
					HOME_PAGE.BEST_PARTNERS,
				)}
			/>
			<ThingsToDoSection
				// @ts-expect-error TS(2322): Type '{ isGlobalHomePage: true; cityCode: string; ... Remove this comment to see the full error message
				isGlobalHomePage
				cityCode={GLOBAL_CITY_CODE}
				lang={lang}
				onScrollIntoView={trackSectionViews(
					ANALYTICS_EVENTS.HOME_PAGE.SECTION_VIEWED,
					HOME_PAGE.BROWSE_BY_THEMES,
				)}
			/>
			<SpaceBlock space={6} />
			<DownloadAppSection isMid={true} languageCode={languageCode} />
		</div>
	);
};

const connectPage = connect(mapStateToProps, mapDispatchToProps);

const createHomePage = (comp: any) => connectPage(comp);

export default createHomePage(withCookies(HomePage));
