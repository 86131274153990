import React from 'react';

const HeadoutLogoBold = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='256'
			height='37'
			viewBox='0 0 256 37'
			fill='none'
			{...props}
		>
			<path
				d='M60.4613 12.9521C58.892 3.04054 46.1002 -1.47482 34.1487 0.418394C27.9527 1.40043 19.818 5.53107 13.5461 10.1173C13.3436 9.83382 13.0095 9.4896 12.4881 9.277C11.5061 8.87709 1.32626 8.16334 0.561893 9.81864C-0.197416 11.4638 3.07774 17.8471 4.50018 19.3505C4.04459 20.292 3.85223 21.1576 3.96866 21.9018C4.09015 22.6611 4.54067 23.4254 5.25442 24.1797C4.36856 26.0729 3.23466 33.1193 4.46474 34.4456C5.70495 35.7819 15.1659 31.9601 15.9759 31.2767C16.4719 30.8566 16.6795 30.3453 16.7655 30.0061C19.8534 30.9477 23.2247 31.6918 26.5404 32.1423L29.1372 34.7037C29.9978 35.5541 31.1519 36.0502 32.3618 36.0705C33.6475 36.0958 35.5205 36.03 37.8288 35.6655C40.4509 35.2504 42.8352 34.5772 44.4044 34.071C45.599 33.6862 46.5912 32.8459 47.1733 31.7323L48.535 29.1051C56.0319 25.6224 61.5547 19.8668 60.4613 12.9521ZM35.9254 4.57941C45.9179 2.99498 54.159 6.69535 56.7761 11.2259C53.2124 10.0515 47.6391 8.71004 36.8923 10.4109C26.4847 12.0611 17.13 15.4021 11.2682 17.9888C15.5608 13.6202 25.4369 6.23976 35.9254 4.57941ZM38.4514 27.7839C27.7502 29.4797 14.1282 25.425 9.19777 22.7775C15.5102 23.2685 28.0286 23.5318 38.6995 21.841C47.7504 20.4085 54.2197 17.7509 57.4594 15.5742C56.2142 20.5806 48.53 26.1893 38.4514 27.7839Z'
				fill='#8000FF'
			/>
			<path
				d='M255.571 15.1726H248.705V26.6628C248.705 29.428 248.753 29.6664 251.327 29.6664H255.571V35.0062H250.04C244.986 35.0062 242.602 33.3375 242.602 27.2826V15.1726H238.025V9.83278H242.602V2.96729H248.705V9.83278H255.571V15.1726Z'
				fill='#8000FF'
			/>
			<path
				d='M220.44 35.384C215.005 35.384 211.382 31.8559 211.382 24.7043V9.8291H217.485V22.6065C217.485 28.2801 218.962 29.8534 222.157 29.8534C225.685 29.8534 228.927 25.8963 228.927 20.0797V9.8291H235.03V35.0026H228.927V33.4292C228.927 31.9036 229.261 30.9977 229.69 29.9965C229.881 29.5674 230.167 28.8999 229.547 28.7569C229.165 28.6615 229.022 28.9476 228.832 29.329C227.592 31.8082 224.827 35.384 220.44 35.384Z'
				fill='#8000FF'
			/>
			<path
				d='M195.41 9.45166C203.277 9.45166 207.997 15.2683 207.997 22.4198C207.997 29.5714 203.277 35.388 195.41 35.388C187.543 35.388 182.823 29.5714 182.823 22.4198C182.823 15.2683 187.543 9.45166 195.41 9.45166ZM195.41 29.8574C199.558 29.8574 201.894 26.52 201.894 22.4198C201.894 18.3196 199.558 14.9822 195.41 14.9822C191.262 14.9822 188.926 18.3196 188.926 22.4198C188.926 26.52 191.262 29.8574 195.41 29.8574Z'
				fill='#8000FF'
			/>
			<path
				d='M173.3 0.677734H179.403V35.0052H173.3V33.9086C173.3 32.9551 173.539 32.1922 173.968 31.3341C174.206 30.8573 174.397 30.3805 173.872 30.1898C173.443 30.0468 173.253 30.5236 173.157 30.6666C171.393 33.2888 169.009 35.3866 165.195 35.3866C158.663 35.3866 154.229 29.6654 154.229 22.4185C154.229 15.1715 158.663 9.45031 165.195 9.45031C169.105 9.45031 171.775 11.3574 173.157 14.1227C173.253 14.3134 173.539 14.9332 174.015 14.6948C174.492 14.4564 174.206 13.8843 173.968 13.4075C173.539 12.5493 173.3 11.7865 173.3 10.7853V0.677734ZM166.626 29.8561C170.63 29.8561 173.3 26.4233 173.3 22.4185C173.3 18.4136 170.63 14.9808 166.626 14.9808C162.335 14.9808 160.332 18.4136 160.332 22.4185C160.332 26.4233 162.335 29.8561 166.626 29.8561Z'
				fill='#8000FF'
			/>
			<path
				d='M126.807 28.0934C126.807 22.5152 130.907 20.465 135.961 20.179C140.442 19.9406 144.399 20.3697 144.352 18.0335C144.304 16.3172 143.637 14.4101 139.298 14.4101C135.007 14.4101 133.911 16.1741 133.577 18.2242H127.569C128.428 11.8832 133.863 9.45166 139.489 9.45166C145.258 9.45166 150.454 11.3587 150.454 21.0849V35.0065H144.352V33.2425C144.352 32.0982 144.638 31.2877 145.067 30.4772C145.258 30.0481 145.448 29.5714 145.019 29.3807C144.542 29.1899 144.352 29.6667 144.256 29.9051C142.826 33.3855 140.061 35.388 135.579 35.388C130.859 35.388 126.807 32.3366 126.807 28.0934ZM144.352 22.0384C142.921 23.6117 140.633 23.8501 137.01 24.2315C134.387 24.5176 132.909 25.5188 132.909 27.7596C132.909 29.8574 134.912 31.1924 137.725 30.811C140.967 30.3819 144.304 28.2841 144.352 22.0384Z'
				fill='#8000FF'
			/>
			<path
				d='M124.187 22.0861C124.187 22.7535 124.139 23.2303 124.044 23.9455H105.736C106.165 27.7596 108.644 30.0481 112.458 30.0481C115.367 30.0481 116.845 28.4748 117.56 26.234H123.758C122.661 31.4308 119.133 35.388 112.411 35.388C104.258 35.388 99.7764 29.9051 99.7764 22.4198C99.7764 14.5531 104.639 9.45166 112.315 9.45166C118.132 9.45166 124.187 12.9321 124.187 22.0861ZM105.974 19.7499H118.084C117.846 16.6986 116.034 14.4101 112.506 14.4101C108.358 14.4101 106.594 16.7462 105.974 19.7499Z'
				fill='#8000FF'
			/>
			<path
				d='M87.3558 9.4503C92.7909 9.4503 96.4144 12.9784 96.4144 20.13V35.0052H90.3117V22.2277C90.3117 16.5542 88.8338 14.9808 85.6394 14.9808C82.1113 14.9808 78.8693 18.938 78.8693 24.7546V35.0052H72.7666V0.677734H78.8693V11.4051C78.8693 12.9307 78.5832 13.8366 78.1064 14.8378C77.9157 15.2669 77.6297 15.9344 78.2495 16.0774C78.6309 16.1728 78.7739 15.8867 78.9646 15.5053C80.1089 12.9784 82.9695 9.4503 87.3558 9.4503Z'
				fill='#8000FF'
			/>
		</svg>
	);
};

export default HeadoutLogoBold;
