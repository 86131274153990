import { connect, useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import { withRouter } from 'next/router';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import LP from 'Components/common/localizedTags/localizedParagraph';

import { CustomerSupportLogoSvg } from 'Assets/svg/trust/CustomerSupportLogoSvg';
import { FeatureMediaSvg } from 'Assets/svg/trust/FeatureMediaSvg';
import { HappyCustomersSvg } from 'Assets/svg/trust/HappyCustomersSvg';

import PlatformUtils from 'Utils/platformUtils';
import {
	getCurrentLanguageCode,
	getDomainConfig,
	getGuestCount,
	getHost,
} from 'Utils/stateUtils';
import { doesCurrentRouteIncludeString } from 'Utils/urlUtils';

import { FALLBACK_GUEST_COUNT } from 'Constants/constants';
import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

import LH2 from '../common/localizedTags/localizedHeading2';
import LSpan from '../common/localizedTags/localizedSpan';

const TrustpilotWidget = dynamic(
	() =>
		import(
			/* webpackChunkName: "TrustpilotWidget" */ 'Containers/common/TrustpilotWidget'
		),
	{
		ssr: false,
	},
);

const StripContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	background: ${colors.WHITE};
	padding: 2.5rem 0;
	margin-top: 3.5rem;
	min-width: 70rem;
	max-width: 75rem;
	margin: 3.5rem auto;

	.title {
		${getFontDetailsByLabel(TYPE_LABELS.DISPLAY_SMALL)};
	}

	.content {
		display: flex;
		justify-content: space-between;
		margin: 4rem 0 0;

		.why-block {
			display: flex;
			flex-direction: column;
			width: 17.625rem;

			.block-svg {
				margin-bottom: 0.75rem;
			}

			.block-title {
				margin-bottom: 0.25rem;
				${getFontDetailsByLabel(TYPE_LABELS.HEADING_REGULAR)};
			}

			.block-description {
				color: ${colors.GREY_DS.G3};
				display: inline-block;
				word-wrap: break-word;
				white-space: normal;
				${getFontDetailsByLabel(TYPE_LABELS.PARAGRAPH_MEDIUM)};
				font-size: 0.9375rem;
				line-height: 1.5rem;
			}
		}

		.trustpilot-container {
			height: 100%;
			display: flex;
			justify-content: start;
			align-items: center;
			align-self: flex-start;
			width: 17.625rem;
		}
	}
`;

type WhyHeadoutStripProps = {
	footerMode?: boolean;
	host?: string;
	location?: any;
	showWhyHeadoutBlock?: boolean;
};

const WhyHeadoutStrip = (props: WhyHeadoutStripProps) => {
	const guestCount = useSelector(state => {
		return getGuestCount(state);
	});
	const lang = useSelector(state => {
		return getCurrentLanguageCode(state);
	});
	const isDesktop = PlatformUtils.isDesktop();

	const totalServed =
		guestCount === FALLBACK_GUEST_COUNT
			? FALLBACK_GUEST_COUNT
			: Math.floor(guestCount / 1e6);

	const getSvg = (symbol: any) => {
		switch (symbol) {
			case 'happy-customers':
				return (
					<HappyCustomersSvg
						className='block-svg'
						aria-label='Happy customers icon'
						role='img'
					/>
				);
			case 'feature-media':
				return (
					<FeatureMediaSvg
						className='block-svg'
						aria-label='TechCrunch and Apple logo'
						role='img'
					/>
				);
			default:
				return (
					<CustomerSupportLogoSvg
						className='block-svg'
						aria-label='24x7 Help Center icon'
						role='img'
					/>
				);
		}
	};

	const getWhyBlock = (symbol: any, title: any, description: any) => {
		return (
			<div className='why-block'>
				{getSvg(symbol)}
				<LP className='block-title'>{title}</LP>
				<LSpan className='block-description'>{description}</LSpan>
			</div>
		);
	};

	const shouldHideStrip = () => {
		// @ts-expect-error TS(2339): Property 'router' does not exist on type 'WhyHeado... Remove this comment to see the full error message
		const { router: location, host, showWhyHeadoutBlock } = props;

		const isConfirmationPage = doesCurrentRouteIncludeString(
			'confirmation',
			location,
		);

		if (isConfirmationPage) {
			return false;
		}

		return (
			(host?.includes('headout.com') && !showWhyHeadoutBlock) ||
			doesCurrentRouteIncludeString('svg-tours', location) ||
			doesCurrentRouteIncludeString('seatmap-tours', location) ||
			doesCurrentRouteIncludeString('help', location) ||
			doesCurrentRouteIncludeString('post-covid', location) ||
			doesCurrentRouteIncludeString(
				'global-travel-reopening-tracker',
				location,
			) ||
			doesCurrentRouteIncludeString('supply-partner', location) ||
			doesCurrentRouteIncludeString('bilink-generator', location)
		);
	};

	if (!isDesktop || shouldHideStrip()) {
		return null;
	}

	return (
		<StripContainerStyled>
			<LH2 className='title'>
				{strings.formatString(strings.GWHS_TITLE_DESKTOP, totalServed)}
			</LH2>
			<div className='content'>
				<div className='trustpilot-container'>
					<TrustpilotWidget
						height='115px'
						lang={lang}
						isDesktop={isDesktop}
					/>
				</div>
				{getWhyBlock(
					'happy-customers',
					strings.formatString(
						strings.GWHS_ITEMS_DESKTOP[0].title,
						totalServed,
					),
					strings.GWHS_ITEMS_MOBILE[0].description,
				)}

				{getWhyBlock(
					'feature-media',
					strings.GWHS_ITEMS_DESKTOP[2].title,
					strings.GWHS_ITEMS_DESKTOP[2].description,
				)}
				{getWhyBlock(
					'customer-support-logo',
					strings.GWHS_ITEMS_DESKTOP[3].title,
					strings.GWHS_ITEMS_DESKTOP[3].description,
				)}
			</div>
		</StripContainerStyled>
	);
};

const mapStateToProps = (state: any) => ({
	host: getHost(state),
	showWhyHeadoutBlock: getDomainConfig(state)?.['showWhyHeadoutBlock'],
});

export default withRouter(connect(mapStateToProps, null)(WhyHeadoutStrip));
