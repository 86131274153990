import React from 'react';

const HeadoutBlimpBold = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width='61'
			height='37'
			viewBox='0 0 61 37'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M60.5329 13.0383C58.9636 3.12693 46.172 -1.38837 34.2206 0.504815C28.0247 1.48684 19.89 5.61744 13.6182 10.2036C13.4157 9.92014 13.0816 9.57592 12.5602 9.36332C11.5782 8.96342 1.39852 8.24968 0.634157 9.90495C-0.125144 11.5501 3.14997 17.9333 4.5724 19.4367C4.11682 20.3782 3.92446 21.2438 4.04089 21.988C4.16237 22.7473 4.61289 23.5116 5.32664 24.2659C4.44078 26.159 3.3069 33.2054 4.53696 34.5316C5.77715 35.868 15.238 32.0462 16.048 31.3628C16.544 30.9426 16.7516 30.4314 16.8376 30.0922C19.9255 31.0338 23.2968 31.7779 26.6124 32.2284L29.2092 34.7898C30.0697 35.6402 31.2239 36.1363 32.4337 36.1565C33.7194 36.1818 35.5924 36.116 37.9006 35.7515C40.5228 35.3365 42.907 34.6632 44.4762 34.157C45.6708 33.7723 46.663 32.932 47.2451 31.8184L48.6068 29.1912C56.1036 25.7085 61.6263 19.953 60.5329 13.0383ZM35.9973 4.66578C45.9897 3.08137 54.2307 6.7817 56.8477 11.3122C53.2841 10.1378 47.7108 8.79638 36.9642 10.4972C26.5567 12.1474 17.2021 15.4883 11.3403 18.075C15.6329 13.7065 25.5089 6.32612 35.9973 4.66578ZM38.5233 27.87C27.8222 29.5658 14.2003 25.5111 9.26994 22.8637C15.5823 23.3547 28.1006 23.6179 38.7713 21.9272C47.8222 20.4947 54.2914 17.8371 57.5311 15.6605C56.2858 20.6668 48.6017 26.2755 38.5233 27.87Z'
				fill='#8000FF'
			/>
		</svg>
	);
};

export default HeadoutBlimpBold;
