import { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';

import CategoriesAndSubCategories from 'Components/common/categoriesAndSubCategories';
import FeedSectionHeader from 'Components/common/feedSectionHeader';
import RenderOneOf from 'Components/common/renderOneOf';
import StarredCategories from 'Components/common/starredCategories';

import useOnScreen from 'Hooks/useOnScreen';
import { trackEvent } from 'Utils/analytics';
import { thingsToDoProps } from 'Utils/collectionsUtils';

import { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES } from 'Constants/analytics';
import { strings } from 'Constants/strings';

import type {
	IThingsToDoSectionProps,
	TCategoriesAndSubCategoriesMap,
	TStarredCategoriesAndSubCategoriesList,
} from './interface';
import { ThingsToDoSectionWrapper } from './style';

const ThingsToDoSection = ({
	cityDisplayName,
	categoriesAndSubCategoriesInfo,
	starredCategoriesInfo,
	categoryId,
	onScrollIntoView,
	isMobile,
	showStarredCategories: showStarredCategoriesProp = false,
}: IThingsToDoSectionProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const isOnScreen = useOnScreen({ ref });
	const [isViewedEventTracked, setIsViewedEventTracked] = useState(false);
	const showStarredCategories = Boolean(
		showStarredCategoriesProp && starredCategoriesInfo?.length,
	);

	// Transform the data immediately instead of in useEffect
	const filteredEntities = categoryId
		? categoriesAndSubCategoriesInfo.filter(entity =>
				entity.isCategory
					? entity.id !== categoryId
					: entity.categoryId !== categoryId,
		  )
		: categoriesAndSubCategoriesInfo;

	const categoriesAndSubCategoriesMap = useMemo(() => {
		if (!filteredEntities.length) return new Map();

		const catAndSubCatMap: TCategoriesAndSubCategoriesMap = new Map();

		filteredEntities.forEach(category => {
			const { id, isCategory, categoryId } = category;
			if (isCategory) {
				catAndSubCatMap.set(+id, {
					entityDetails: category,
					subCategories: [],
				});
			} else {
				if (showStarredCategories) {
					catAndSubCatMap.set(+id, {
						entityDetails: category,
					});
				} else {
					const parentCategory = catAndSubCatMap.get(categoryId!);
					if (parentCategory?.subCategories) {
						parentCategory.subCategories.push(category);
					}
				}
			}
		});

		return catAndSubCatMap;
	}, [filteredEntities, showStarredCategories]);

	const starredCategoriesAndSubCategoriesList = useMemo(() => {
		if (!showStarredCategories || !starredCategoriesInfo) return [];

		return starredCategoriesInfo
			.map(({ id, rank }) => {
				const entityData = categoriesAndSubCategoriesMap.get(id);
				if (!entityData) return null;

				return {
					entityDetails: entityData.entityDetails,
					rank,
				};
			})
			.filter(Boolean) as TStarredCategoriesAndSubCategoriesList;
	}, [
		showStarredCategories,
		starredCategoriesInfo,
		categoriesAndSubCategoriesMap,
	]);

	// Track viewed event
	useEffect(() => {
		if (
			!isViewedEventTracked &&
			isOnScreen &&
			(starredCategoriesAndSubCategoriesList.length ||
				categoriesAndSubCategoriesMap.size)
		) {
			trackEvent({ eventName: ANALYTICS_EVENTS.BROWSE_BY_THEMES.VIEWED });
			setIsViewedEventTracked(true);
		}
	}, [
		isOnScreen,
		categoriesAndSubCategoriesMap,
		starredCategoriesAndSubCategoriesList,
		isViewedEventTracked,
	]);

	const trackThemeSelected = ({
		name,
		rank,
	}: {
		name: string;
		rank?: number;
	}) => {
		trackEvent({
			eventName: ANALYTICS_EVENTS.BROWSE_BY_THEMES.THEME_SELECTED,
			[ANALYTICS_PROPERTIES.BROWSE_BY_THEMES.LABEL]: name,
			...(typeof rank === 'number' && {
				[ANALYTICS_PROPERTIES.BROWSE_BY_THEMES.RANK]: rank,
			}),
		});
	};

	if (!(showStarredCategories || categoriesAndSubCategoriesInfo.length))
		return null;

	return (
		<ThingsToDoSectionWrapper
			$showStarredCategories={showStarredCategories}
		>
			<FeedSectionHeader
				title={strings.THINGS_TO_DO.CITY}
				headingType={'h2'}
				newDesignSystem
				onScrollIntoView={onScrollIntoView}
			/>
			<RenderOneOf
				positionalConditions={[
					showStarredCategories,
					!showStarredCategories,
				]}
				noDefault
			>
				<StarredCategories
					starredCategoriesAndSubcategories={
						starredCategoriesAndSubCategoriesList
					}
					city={cityDisplayName}
					trackClick={trackThemeSelected}
					ref={ref}
				/>
				<CategoriesAndSubCategories
					className='horizontalTabSection'
					categoriesAndSubcategories={categoriesAndSubCategoriesMap}
					city={cityDisplayName}
					ref={ref}
					trackClick={trackThemeSelected}
					isMobile={isMobile}
				/>
			</RenderOneOf>
		</ThingsToDoSectionWrapper>
	);
};

export default connect(thingsToDoProps)(ThingsToDoSection);
