import React from 'react';

import Image from '@headout/aer/src/atoms/Image';

import { getImgixUrl } from 'Utils/imageUtils';

export const Chevron = ({
	orientation = 'down',
	strokeColor = '#444',
	height = '8',
	width = '16',
}) => {
	let transform = '';
	switch (orientation) {
		case 'down':
			break;
		case 'up':
			transform = `transform: rotate(180deg);`;
			break;
		case 'right':
			transform = `transform: rotate(-90deg);`;
			break;
		case 'left':
			transform = `transform: rotate(90deg);`;
			break;
		default:
			break;
	}

	return (
		<svg
			css={transform}
			width={width}
			height={height}
			viewBox='0 0 16 8'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className='chevron'
		>
			<path
				d='M1.33341 0.666686L8.00008 7.33335L14.6667 0.666687'
				stroke={strokeColor}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
export const CLOSE = (
	<svg
		width='12'
		height='12'
		viewBox='0 0 12 12'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M11.3333 0.666687L0.666626 11.3334'
			stroke='#545454'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M0.666626 0.666687L11.3333 11.3334'
			stroke='#545454'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
export const HAMBURGER = (
	<svg
		width='28'
		height='18'
		viewBox='0 0 28 18'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<line
			x1='1'
			y1='1'
			x2='27'
			y2='1'
			stroke='#545454'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<line
			x1='1'
			y1='9'
			x2='27'
			y2='9'
			stroke='#545454'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<line
			x1='1'
			y1='17'
			x2='27'
			y2='17'
			stroke='#545454'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
export const CHEVRON_BUTTON = (
	<svg
		width='56'
		height='56'
		viewBox='0 0 56 56'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<g filter='url(#filter0_d)'>
			<circle cx='28' cy='24' r='22' fill='white' />
			<path
				d='M24 15L33 23.5L24 32'
				stroke='#757575'
				strokeWidth='2.5'
				strokeLinejoin='round'
			/>
		</g>
		<defs>
			<filter
				id='filter0_d'
				x='0'
				y='0'
				width='56'
				height='56'
				filterUnits='userSpaceOnUse'
				colorInterpolationFilters='sRGB'
			>
				<feFlood floodOpacity='0' result='BackgroundImageFix' />
				<feColorMatrix
					in='SourceAlpha'
					type='matrix'
					values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
				/>
				<feOffset dy='4' />
				<feGaussianBlur stdDeviation='3' />
				<feColorMatrix
					type='matrix'
					values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0'
				/>
				<feBlend
					mode='normal'
					in2='BackgroundImageFix'
					result='effect1_dropShadow'
				/>
				<feBlend
					mode='normal'
					in='SourceGraphic'
					in2='effect1_dropShadow'
					result='shape'
				/>
			</filter>
		</defs>
	</svg>
);
export const TWITTER = (
	<svg
		width='34'
		height='34'
		viewBox='0 0 34 34'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<circle cx='17' cy='17' r='16' fill='white' stroke='#666666' />
		<path
			d='M24 13.3061C23.5129 13.5352 22.9924 13.6778 22.4601 13.7279C23.0176 13.3896 23.4361 12.8498 23.6359 12.2112C23.1124 12.5403 22.5361 12.7685 21.9348 12.8848C21.6957 12.606 21.4025 12.3829 21.0746 12.2304C20.7467 12.0779 20.3915 11.9994 20.0324 12C19.6812 11.9998 19.3335 12.0716 19.009 12.2113C18.6846 12.3511 18.3898 12.556 18.1415 12.8144C17.8932 13.0728 17.6963 13.3795 17.562 13.7172C17.4277 14.0548 17.3587 14.4167 17.3589 14.7821C17.3659 14.995 17.3932 15.2066 17.4403 15.4139C16.3781 15.3552 15.3394 15.0661 14.3908 14.5654C13.4422 14.0646 12.6046 13.3631 11.9317 12.5059C11.6892 12.9243 11.5626 13.4043 11.566 13.8928C11.5689 14.3495 11.6774 14.7987 11.8825 15.2025C12.0875 15.6062 12.383 15.9527 12.7439 16.2124C12.3176 16.2101 11.899 16.0937 11.5285 15.8742V15.9166C11.5311 16.555 11.7463 17.1728 12.1376 17.6646C12.5288 18.1565 13.0717 18.492 13.674 18.614C13.4478 18.6629 13.2179 18.6912 12.987 18.6987C12.8242 18.7058 12.6612 18.6915 12.5019 18.6563C12.6736 19.2037 13.0026 19.6831 13.4443 20.0298C13.8861 20.3766 14.4194 20.5739 14.9724 20.5953C14.0292 21.3752 12.8562 21.7925 11.6516 21.7766C11.4336 21.7839 11.2154 21.7697 11 21.7342C12.1417 22.5049 13.4622 22.9402 14.822 22.9943C16.1818 23.0483 17.5304 22.7191 18.7252 22.0414C19.92 21.3637 20.9167 20.3626 21.61 19.1441C22.3032 17.9255 22.6672 16.5347 22.6635 15.1186V14.7804C23.1698 14.3527 23.6192 13.8569 24 13.3061Z'
			fill='#545454'
		/>
	</svg>
);
export const FACEBOOK = (
	<svg
		width='34'
		height='34'
		viewBox='0 0 34 34'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<circle cx='17' cy='17' r='16' fill='white' stroke='#666666' />
		<path
			d='M17.4444 24.5H14.9278V16.9996H13.25V14.4217H14.9278V12.9217C14.9278 10.7653 15.4311 9.5 17.6545 9.5H19.5V12.0779H18.3256C17.4451 12.0779 17.4028 12.4529 17.4028 13.1092V14.4217H19.5L19.2899 16.9996H17.4444V24.5Z'
			fill='#545454'
		/>
	</svg>
);
export const LINKEDIN = (
	<svg
		width='34'
		height='34'
		viewBox='0 0 34 34'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<circle cx='17' cy='17' r='16' fill='white' stroke='#666666' />
		<path
			d='M12.3112 8.8125C11.2113 8.8125 10.4922 9.53476 10.4922 10.4841C10.4922 11.4124 11.1899 12.1553 12.269 12.1553H12.2899C13.4113 12.1553 14.1093 11.4124 14.1093 10.4841C14.0883 9.53476 13.4113 8.8125 12.3112 8.8125Z'
			fill='#545454'
		/>
		<path
			d='M10.6821 13.4761H13.8976V23.1501H10.6821V13.4761Z'
			fill='#545454'
		/>
		<path
			d='M21.7902 13.249C20.0555 13.249 18.8924 14.879 18.8924 14.879V13.4761H15.6768V23.1501H18.8922V17.7478C18.8922 17.4585 18.9131 17.1698 18.9981 16.963C19.2306 16.3854 19.7596 15.7871 20.6479 15.7871C21.8114 15.7871 22.2768 16.6743 22.2768 17.9748V23.1501H25.492V17.6032C25.492 14.6317 23.9056 13.249 21.7902 13.249Z'
			fill='#545454'
		/>
	</svg>
);
export const CODE = (
	<svg
		width='22'
		height='14'
		viewBox='0 0 22 14'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M15 13L21 7L15 1'
			stroke='#444444'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M7 1L1 7L7 13'
			stroke='#444444'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
export const SHARE = (
	<svg
		width='14'
		height='17'
		viewBox='0 0 14 17'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M1 8.5V14.5C1 14.8978 1.15804 15.2794 1.43934 15.5607C1.72064 15.842 2.10218 16 2.5 16H11.5C11.8978 16 12.2794 15.842 12.5607 15.5607C12.842 15.2794 13 14.8978 13 14.5V8.5'
			stroke='black'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M10.0002 4L7.00024 1L4.00024 4'
			stroke='black'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M7.00049 1V10.75'
			stroke='black'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export const ChevronBack = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width='20'
		height='20'
		viewBox='0 0 20 20'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M14.1666 1.66671L5.83329 10L14.1666 18.3334'
			stroke='#444444'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export const ChevronBordered = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width='40'
		height='40'
		viewBox='0 0 40 40'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<circle cx='20' cy='20' r='19.5' fill='white' stroke='#E2E2E2' />
		<path
			d='M16.6665 28.3333L24.9998 20L16.6665 11.6666'
			stroke='#444444'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export const Info = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='20'
		height='20'
		fill='none'
		viewBox='0 0 20 20'
		{...props}
		aria-hidden='true'
	>
		<path
			d='M10.0002 1.66659C5.39779 1.66658 1.66683 5.39754 1.66683 9.99992C1.66683 14.6023 5.39779 18.3333 10.0002 18.3333C14.6025 18.3333 18.3335 14.6023 18.3335 9.99992C18.3335 5.39755 14.6025 1.66659 10.0002 1.66659Z'
			stroke={props.color || '#A4563B'}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M10 13.3333L10 9.99992'
			stroke={props.color || '#A4563B'}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M10 6.66675L9.99167 6.66675'
			stroke={props.color || '#A4563B'}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
export const TickSvg = () => {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M14 3.3335L5.75 12.0002L2 8.06077'
				stroke='#666666'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
export const PlaySvg = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='24'
		height='24'
		viewBox='0 0 24 24'
	>
		<path d='M8 6.82v10.36c0 .79.87 1.27 1.54.84l8.14-5.18a1 1 0 000-1.69L9.54 5.98A.998.998 0 008 6.82z'></path>
	</svg>
);

export const ImageSvg = (props: Record<string, any>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='24'
		height='24'
		fill='none'
		viewBox='0 0 16 16'
		{...props}
	>
		<path
			stroke='#444'
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M12.667 2H3.333C2.597 2 2 2.597 2 3.333v9.334C2 13.403 2.597 14 3.333 14h9.334c.736 0 1.333-.597 1.333-1.333V3.333C14 2.597 13.403 2 12.667 2z'
		></path>
		<path
			stroke='#444'
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M5.667 6.667a1 1 0 100-2 1 1 0 000 2zM14 10l-3.333-3.333L3.333 14'
		></path>
	</svg>
);
