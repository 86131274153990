import { generateFilterKey } from 'Utils/gen';
import { evaluateReviewStoreKey } from 'Utils/reviewUtils';

import { ActionTypes } from 'Actions/actions';
import type {
	TReviewActionCityParam,
	TReviewActionCollectionParam,
	TReviewActionPersonaParam,
	TReviewActionTGIDParam,
	TReviewCityCategoryParam,
	TReviewCitySubCategoryParam,
	TReviewState,
} from 'ReduxTypes/review';

const initialState: TReviewState = {
	byTgIdAndFilter: {},
	byCollectionId: {},
	byCity: {},
	byPersonaAndCity: {},
	byCityAndCategory: {},
	byCityAndSubCategory: {},
	status: {
		isFetching: {
			byTgIdAndFilter: {},
			byCollectionId: {},
			byCity: {},
			byPersonaAndCity: {},
			byCityAndCategory: {},
			byCityAndSubCategory: {},
		},
	},
};

export const reviewStore = (
	state = initialState,
	action: {
		payload:
			| TReviewActionTGIDParam
			| TReviewActionCollectionParam
			| TReviewActionCityParam;
		type: string;
	},
) => {
	switch (action.type) {
		case ActionTypes.REQUEST_REVIEWS_BY_TGID: {
			const { tgId, filterType } = <TReviewActionTGIDParam>action.payload;
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byTgIdAndFilter: {
							...state.status.isFetching.byTgIdAndFilter,
							[evaluateReviewStoreKey(tgId, filterType)]: true,
						},
					},
				},
			};
		}

		case ActionTypes.RECEIVE_REVIEWS_BY_TGID: {
			const { reviews, tgId, filterType } = <TReviewActionTGIDParam>(
				action.payload
			);
			if (!reviews) return;
			const { items } = reviews;
			const reviewsMap = state?.byTgIdAndFilter;
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byTgIdAndFilter: {
							...state.status.isFetching.byTgIdAndFilter,
							[evaluateReviewStoreKey(tgId, filterType)]: false,
						},
					},
				},
				byTgIdAndFilter: {
					...reviewsMap,
					[evaluateReviewStoreKey(tgId, filterType)]: items,
				},
			};
		}

		case ActionTypes.REQUEST_REVIEWS_BY_COLLECTION: {
			const { collectionId } = <TReviewActionCollectionParam>(
				action.payload
			);
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byCollectionId: {
							...state.status.isFetching.byCollectionId,
							[collectionId]: true,
						},
					},
				},
			};
		}

		case ActionTypes.RECEIVE_REVIEWS_BY_COLLECTION: {
			const { reviewsData, collectionId } = <
				TReviewActionCollectionParam
			>action.payload;
			if (!reviewsData) return;
			const { result } = reviewsData;
			const { items, total } = result.reviews ?? {};
			const reviewsMap = state?.byCollectionId;
			const key = collectionId;
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byCollectionId: {
							...state.status.isFetching.byCollectionId,
							[key]: false,
						},
					},
				},
				byCollectionId: {
					...reviewsMap,
					[key]: {
						reviews: items,
						total,
					},
				},
			};
		}

		case ActionTypes.APPEND_REVIEWS_BY_COLLECTION: {
			const { reviewsData, collectionId } = <
				TReviewActionCollectionParam
			>action.payload;
			if (!reviewsData) return;

			const { result } = reviewsData;
			const { items, total } = result.reviews ?? {};

			/**
			 * PAGINATION LOGIC
			 * not using this right now because we
			 * are only showing 8 reviews which are
			 * fetched together. In case in future we
			 * plan to show more reviews, pagination
			 * can be used.
			 */
			const reviewsMap = state?.byCollectionId;
			const key = collectionId;
			let { reviews: storedReviews = [], nextOffset: currentOffset = 0 } =
				state.byCollectionId[key];
			const currentReviews = [...storedReviews];
			currentOffset ??= 0;
			if (total > currentOffset) {
				currentReviews.push(...items);
			}
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byCollectionId: {
							...state.status.isFetching.byCollectionId,
							[key]: false,
						},
					},
				},
				byCollectionId: {
					...reviewsMap,
					[key]: {
						reviews: currentReviews,
						total,
						nextOffset: Math.max(total, currentOffset),
					},
				},
			};
		}

		case ActionTypes.REQUEST_REVIEWS_BY_CITY: {
			const { city } = <TReviewActionCityParam>action.payload;
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byCity: {
							...state.status.isFetching.byCity,
							[city]: true,
						},
					},
				},
			};
		}

		case ActionTypes.RECEIVE_REVIEWS_BY_CITY: {
			const { reviewsData, city } = <TReviewActionCityParam>(
				action.payload
			);

			if (!reviewsData) return;

			const { result } = reviewsData;
			const { items, total } = result?.reviews ?? {};
			const reviewsMap = state?.byCity;
			const key = city;

			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byCity: {
							...state.status.isFetching.byCity,
							[key]: false,
						},
					},
				},
				byCity: {
					...reviewsMap,
					[key]: {
						reviews: items,
						total,
					},
				},
			};
		}

		case ActionTypes.APPEND_REVIEWS_BY_CITY: {
			const { reviewsData, city } = <TReviewActionCityParam>(
				action.payload
			);
			if (!reviewsData) return;

			const { result } = reviewsData;
			const { items, total } = result?.reviews ?? {};
			/**
			 * PAGINATION LOGIC
			 * not using this right now because we
			 * are only showing 8 reviews which are
			 * fetched together. In case in future we
			 * plan to show more reviews, pagination
			 * can be used.
			 */
			const reviewsMap = state?.byCity;
			const key = city;
			let { reviews: storedReviews = [], nextOffset: currentOffset = 0 } =
				state.byCity[key];
			const currentReviews = [...storedReviews];
			currentOffset ??= 0;
			if (total > currentOffset) {
				currentReviews.push(...items);
			}
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byCity: {
							...state.status.isFetching.byCity,
							[key]: false,
						},
					},
				},
				byCity: {
					...reviewsMap,
					[key]: {
						reviews: currentReviews,
						total,
					},
				},
			};
		}

		case ActionTypes.REQUEST_REVIEWS_BY_PERSONA: {
			const { personaAffinityId, city } = <TReviewActionPersonaParam>(
				action.payload
			);
			const key = generateFilterKey(personaAffinityId, city);
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byPersonaAndCity: {
							...state.status.isFetching.byPersonaAndCity,
							[key]: true,
						},
					},
				},
			};
		}

		case ActionTypes.RECEIVE_REVIEWS_BY_PERSONA: {
			const { reviewsData, personaAffinityId, city } = <
				TReviewActionPersonaParam
			>action.payload;
			if (!reviewsData) return;
			const { result } = reviewsData;
			const { items, total } = result?.reviews ?? {};
			const reviewsMap = state?.byPersonaAndCity;
			const key = generateFilterKey(personaAffinityId, city);
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byPersonaAndCity: {
							...state.status.isFetching.byPersonaAndCity,
							[key]: false,
						},
					},
				},
				byPersonaAndCity: {
					...reviewsMap,
					[key]: {
						reviews: items,
						total,
					},
				},
			};
		}

		case ActionTypes.REQUEST_REVIEWS_BY_CITY_AND_CATEGORY: {
			const { categoryId, city } = <TReviewCityCategoryParam>(
				action.payload
			);
			const key = generateFilterKey(city, categoryId);
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byCityAndCategory: {
							...state.status.isFetching.byCityAndCategory,
							[key]: true,
						},
					},
				},
			};
		}

		case ActionTypes.RECEIVE_REVIEWS_BY_CITY_AND_CATEGORY: {
			const { reviewsData, categoryId, city } = <
				TReviewCityCategoryParam
			>action.payload;

			if (!reviewsData) return;

			let { result } = reviewsData;
			const { items, total } = result?.reviews ?? {};

			const key = generateFilterKey(city, categoryId);

			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...(state.status.isFetching ?? {}),
						byCityAndCategory: {
							...(state.status.isFetching.byCityAndCategory ??
								{}),
							[key]: false,
						},
					},
				},
				byCityAndCategory: {
					...(state.byCityAndCategory ?? {}),
					[key]: {
						reviews: items,
						total,
					},
				},
			};
		}

		case ActionTypes.REQUEST_REVIEWS_BY_CITY_AND_SUBCATEGORY: {
			const { subCategoryId, city } = <TReviewCitySubCategoryParam>(
				action.payload
			);

			const key = generateFilterKey(city, subCategoryId);

			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byCityAndSubCategory: {
							...(state.status.isFetching.byCityAndSubCategory ??
								{}),
							[key]: true,
						},
					},
				},
			};
		}

		case ActionTypes.RECEIVE_REVIEWS_BY_CITY_AND_SUBCATEGORY: {
			const { reviewsData, subCategoryId, city } = <
				TReviewCitySubCategoryParam
			>action.payload;

			if (!reviewsData) return;

			let { result } = reviewsData;
			const { items, total } = result?.reviews ?? {};

			const key = generateFilterKey(city, subCategoryId);

			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...(state.status.isFetching ?? {}),
						byCityAndSubCategory: {
							...(state.status.isFetching.byCityAndSubCategory ??
								{}),
							[key]: false,
						},
					},
				},
				byCityAndSubCategory: {
					...(state.byCityAndSubCategory ?? {}),
					[key]: {
						reviews: items,
						total,
					},
				},
			};
		}

		default:
			return state;
	}
};
