import React from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';
import { COLORS } from '@headout/fresh-aer';

import { truncateWithEllipsis } from 'Utils/gen';
import { getCountryFlagUrl } from 'Utils/imageUtils';
import { getBaseHost } from 'Utils/urlUtils';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

import LP from './localizedTags/localizedParagraph';
import Avatar from './avatar';
import Image from './image';
import Rating from './rating';

const StyledCardContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	width: 384px;
	height: 640px;
	color: ${colors.WHITE};
	margin: 1px 24px 1px 1px;
	background: rgba(28, 27, 27, 0.5);
	backdrop-filter: blur(6px);
	overflow: hidden;
	border-radius: 8px;

	@media (max-width: 768px) {
		width: 316px;
		height: 588px;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		border-radius: 8px;
		border: 1px solid transparent;
		background: linear-gradient(
				18.03deg,
				rgba(255, 255, 255, 0.184) 1.11%,
				rgba(255, 255, 255, 0) 38.19%
			)
			border-box;
		-webkit-mask: linear-gradient(#fff 0 0) padding-box,
			linear-gradient(#fff 0 0);
		-webkit-mask-composite: destination-out;
		mask-composite: exclude;
	}
`;
const StyledCardImg = styled.div`
	margin-bottom: 1rem;
	width: 100%;
	height: auto;
`;
const StyledCardContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
	padding: 0rem 1rem;
	margin-bottom: 1rem;
	height: 100%;
`;
const StyledUserReviewRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 0.75rem;
`;
const StyledUser = styled.div`
	display: grid;
	grid-template-columns: 48px 1fr;
	grid-template-rows: 1fr auto;
	grid-column-gap: 8px;
	grid-template-areas: 'user-img user-name' 'user-img user-country';
	width: 100%;
	min-width: 0;
	height: auto;
`;
const StyledUserName = styled(LP)`
	grid-area: user-name;
	${getFontDetailsByLabel(TYPE_LABELS.HEADING_REGULAR)}
	color: ${colors.WHITE};
	align-self: center;
	white-space: nowrap;
	overflow-x: hidden;
	text-overflow: ellipsis;

	@media (max-width: 768px) {
		${getFontDetailsByLabel(TYPE_LABELS.HEADING_SMALL)}
	}
`;
const StyledUserCountry = styled(LP)`
	grid-area: user-country;
	display: flex;
	align-items: center;
	color: ${colors.GREY_DS.G5};
	span {
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR)}
		font-size: 14px !important;
		white-space: nowrap;
	}
`;
const StyledRatingContainer = styled.div`
	display: flex;
	align-self: center;
	@media (min-width: 768px) {
		svg {
			width: 20px;
			height: 20px;
		}
	}
`;
const StyledContent = styled.p`
	${getFontDetailsByLabel(TYPE_LABELS.PARAGRAPH_SMALL)}
	font-size: 16px !important;
	line-height: 24px !important;
	color: ${colors.GREY_DS.G8};
	overflow: hidden;
	white-space: break-spaces;
	@media (max-width: 768px) {
		${getFontDetailsByLabel(TYPE_LABELS.PARAGRAPH_MEDIUM)}
		font-size: 15px !important;
		line-height: 24px !important;
	}
`;
const StyledExpName = styled.a`
	${getFontDetailsByLabel(TYPE_LABELS.PARAGRAPH_XS)}
	color: ${colors.GREY_DS.G5};
	font-size: 12px !important;
	line-height: 20px !important;
	text-decoration: underline !important;
	height: 2.5rem;
	white-space: break-spaces;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;

	&::before {
		content: '';
		display: block;
		position: absolute;
		left: 1rem;
		right: 1rem;
		bottom: 62px;
		border-bottom: 1px solid ${colors.GREY_DS.G2};
		@media (min-width: 768px) {
			bottom: 4.25rem;
		}
	}
`;
const StyledFlagSvg = styled.span<{ url: string }>`
	display: inline-block;
	width: 16px;
	height: 16px;
	margin-top: 2px;
	margin-right: 5px;
	background-image: url(${({ url }) => url});
	background-size: contain;
	background-position: 50%;
	background-repeat: no-repeat;
`;
const ReviewCard = ({
	isMobile,
	imageUrl,
	customerName,
	customerCountry,
	customerCountryCode,
	customerProfile,
	rating,
	content,
	experienceName,
	tourGroupId,
	directUrl,
}: any) => {
	const tgidUrl = directUrl ? `${directUrl}` : `/tour/${tourGroupId}`;
	const flagUrl = getCountryFlagUrl(customerCountryCode);
	return (
		<StyledCardContainer>
			<StyledCardImg>
				<Image
					src={imageUrl}
					key={imageUrl}
					width={384}
					height={364}
					alt={experienceName}
					title={experienceName}
					layout={'responsive'}
					enhance
				/>
			</StyledCardImg>
			<StyledCardContent>
				<div>
					<StyledUserReviewRow>
						<StyledUser>
							<Avatar
								width={isMobile ? 44 : 48}
								height={isMobile ? 44 : 48}
								url={customerProfile}
								name={customerName.toUpperCase()}
								bgColor={colors.DARK_PURPLE_AVATAR_BG}
								style={{ gridArea: 'user-img' }}
							/>
							<StyledUserName className='block'>
								{customerName}
							</StyledUserName>
							<StyledUserCountry>
								{customerCountryCode && (
									<StyledFlagSvg url={flagUrl} />
								)}
								<span>{customerCountry}</span>
							</StyledUserCountry>
						</StyledUser>
						<StyledRatingContainer>
							<Rating
								value={rating}
								fillColor={COLORS.TEXT.HEADOUT_CANDY_1}
							/>
						</StyledRatingContainer>
					</StyledUserReviewRow>
					<StyledContent>
						{truncateWithEllipsis({
							text: content,
							limit: isMobile ? 230 : 230,
						})}
					</StyledContent>
				</div>
				<StyledExpName href={tgidUrl} target='_blank'>
					{experienceName}
				</StyledExpName>
			</StyledCardContent>
		</StyledCardContainer>
	);
};

export default React.memo(ReviewCard);
