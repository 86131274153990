import type {
	IRedemptionInstruction,
	VoucherDeliveryType,
} from '@headout/aqua';

export enum BOOKING_STATUS {
	PENDING = 'PENDING',
	COMPLETED = 'COMPLETED',
	CANCELLED = 'CANCELLED',
	REFUNDED = 'REFUNDED',
	UNCAPTURED = 'UNCAPTURED',
}

export enum InventoryType {
	FIXED_START_FIXED_DURATION = 'FIXED_START_FIXED_DURATION',
	FIXED_START_FLEXIBLE_DURATION = 'FIXED_START_FLEXIBLE_DURATION',
	FLEXIBLE_START_FIXED_DURATION = 'FLEXIBLE_START_FIXED_DURATION',
	FLEXIBLE_START_FLEXIBLE_DURATION = 'FLEXIBLE_START_FLEXIBLE_DURATION',
}

export enum COUNTRY_CODES {
	USA = 'US',
	AUS = 'AU',
	UAE = 'AE',
	FRANCE = 'FR',
	UK = 'GB',
	SPAIN = 'ES',
	SINGAPORE = 'SG',
	MALAYSIA = 'MY',
	POLAND = 'PL',
	ITALY = 'IT',
	PORTUGAL = 'PT',
}

export enum TicketUIType {
	IMAGE = 'IMAGE',
	TICKET_NUMBER = 'TICKET_NUMBER',
	PDF = 'PDF',
	WALLET_PASS = 'WALLET_PASS',
	NONE = 'NONE',
}

export enum TicketType {
	QRCODE = 'QRCODE',
	BARCODE = 'BARCODE',
	TEXT = 'TEXT',
	PDF = 'PDF_URL',
	HTML = 'HTML_URL',
}

export enum TicketRedemptionMethods {
	DIRECT_ENTRY = 'DIRECT_ENTRY',
	ESCORTED_ENTRY = 'ESCORTED_ENTRY',
	EXCHANGE_FOR_TICKET = 'EXCHANGE_FOR_TICKET',
}

export enum TicketVisibilityOverride {
	HIDE = 'HIDE',
	SHOW_DEFAULT = 'SHOW_DEFAULT',
}

export module MVoucher {
	export interface IPaxDetail {
		guestType: string;
		displayName: string;
		pluralDisplayName: string;
		paxCount: number;
		paxPrice: number;
	}

	export interface ITour {
		parentTourGroupName: string;
		tourName: string;
		variantName: string;
		tourGroupName: string;
		tourGroupId: string;
		meetingPointAddress?: any;
		tourAddress: string;
		meetingPointLatitude?: any;
		meetingPointLongitude?: any;
		tourProperties: Record<string, string>;
		tourPropertiesV2: Record<string, string[]>;
	}

	export interface IVendor {
		name: string;
		imageUrl: string;
		vendorContactInfo: string;
	}

	export interface ISeatDetails {
		section: string;
		row: string;
		seatNumber: string;
		seatCode: string;
	}

	export interface ISeatmapDetails {
		sectionName: string;
		seatDetails: ISeatDetails[];
	}

	export interface ITicket {
		url?: string;
		id?: string;
		ticketUIType: TicketUIType;
		ticketType: TicketType;
		ticketLocalCacheURL?: string;
		expiryDate?: string;
		name?: string;
	}
}

export type TLanguage = 'EN' | 'DE' | 'PT' | 'ES' | 'FR' | 'NL' | 'IT';

export enum ETourType {
	AIRPORT_TRANSFER = 'AIRPORT_TRANSFER',
	TOUR = 'TOUR',
	EVENT = 'EVENT',
	ATTRACTION = 'ATTRACTION',
	ACTIVITY = 'ACTIVITY',
	TRANSFER = 'TRANSFER ',
}

export enum EAdditionalVoucherFields {
	PICKUP_LOCATION = 'PICKUP_LOCATION',
}

export enum EAdditionalVoucherFieldTypes {
	TEXT = 'TEXT',
	URL = 'URL',
}

export interface IAdditionalVoucherField {
	heading: string;
	value: string | number;
	url?: string;
	additionalVoucherFieldName: EAdditionalVoucherFields;
	additionalVoucherFieldType: EAdditionalVoucherFieldTypes;
}

export interface IRedemptionAddress {
	type: 'MEETING_POINT' | 'REDEMPTION_POINT' | 'BOARDING_POINT';
	addressHash: string;
	startingPoint?: boolean;
	addressLine1: string;
	addressLine2: string;
	googleMapLink: string;
	city: string;
	country: string;
	zip: string;
	landmark: string;
	mediaLink: string[];
	guideDetails: string;
	linkedAddress: string[];
}

export interface TVoucherData {
	bookingDetails: {
		status: BOOKING_STATUS;
		extraInformationHtml: string;
		redemptionInstructionV2?: IRedemptionInstruction;
		calloutInstructionsHtml: string;
		bookingID: string;
		comboProduct?: boolean;
		bookingCreationDate: string;
		customerName: string;
		paxDetails: MVoucher.IPaxDetail[];
		tour: MVoucher.ITour;
		tourDuration: number;
		tourStartTime: string;
		tourEndTime: string;
		tourInventoryType: InventoryType;
		tourType: ETourType;
		partnerReferenceNumber: string;
		validity: string;
		tourDate: string;
		seatmapDetails: MVoucher.ISeatmapDetails[];
		vendor: MVoucher.IVendor;
		tickets: MVoucher.ITicket[];
		calloutVisibilityOverride: TicketVisibilityOverride;
		ticketRedemptionMethod: TicketRedemptionMethods;
		deliveryType: VoucherDeliveryType;
		cancellationPolicy: string;
		reschedulePolicy: string;
		experienceCountry: string;
		cardCountry: string;
		isOpenDated: boolean;
		isWanderfestBooking: boolean;
		slotSubmissionLink: string;
		availableSlotsLink: string;
		bookingAdvanceHour: string;
		metaData: {
			isDisneyVoucher: boolean;
			disneyBookingId?: string;
			isChristmasRockettesVoucher?: boolean;
		};
		multiplePage: boolean;
		showPaxPriceForMultiPageVoucher: boolean;
		paxIndividualDetails: {
			paxString: string;
			paxPrice: number;
		};
		pickupLocation?: string;
		bookingCurrencyCode: string;
		language: TLanguage;
		bookingInsurance?: {
			bookingId: string;
			cancellationDeadline: number;
			coverageAmount: {
				currency: string;
				value: number;
			};
			id: number;
			insuranceConfigId: number;
			premiumAmount: {
				currency: string;
				value: number;
			};
			status: string;
		};
	};
	isDummy: boolean;
	pages?: number;
	currentPage?: number;
}

export interface TVoucherGridDetailProps {
	customerName: string;
	meetingPointAddress?: any;
	meetingPointLatitude?: any;
	meetingPointLongitude?: any;
	tourDuration?: string;
	tourStartTime?: string;
	tourEndTime?: string;
	guidedTourLanguageCode?: string[] | string;
	validity: string;
	pickupLocation?: string;
	tourDate?: string;
	seatmapDetails: string | null;
	isOpenDated: boolean;
	paxString?: string;
	paxPrice: string | null;
	inventoryType: InventoryType;
	hasStructuredRedemptionInstruction?: boolean;
}

export interface TVendorPartnerDetails {
	vendorName: string;
	vendorContactInfo: string;
	partnerReferenceNumber: string;
}
