import React, {
	forwardRef,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import isEqual from 'lodash.isequal';

import Conditional from 'Components/common/conditional';
import L1Booster from 'Components/common/l1Booster';
import LSpan from 'Components/common/localizedTags/localizedSpan';
import PriceBlock from 'Components/common/priceBlock';
import ProductCardMediaCarousel from 'Components/common/productMediaCarousel';

import { StarSvg } from 'Assets/svg/feedPage';
import { InstantConfirmationSvg } from 'Assets/svg/product';

import useOnScreen from 'Hooks/useOnScreen';
import { useProductCardContext } from 'Contexts/productCardContext';
import { trackEvent } from 'Utils/analytics';
import { truncateNumber } from 'Utils/gen';
import PlatformUtils from 'Utils/platformUtils';
import {
	getDescriptorByType,
	getProductBoosters,
	getProductCardClickUrl,
	getProductCashback,
	productHasSpecialOffer,
} from 'Utils/productUtils';
import {
	getCity,
	getCurrentCityCode,
	getCurrentPage,
	getProductCard,
	getProductCardV2,
} from 'Utils/stateUtils';
import { getHumanReadableStringFromSanitizedIdentifier } from 'Utils/stringUtils';
import { isCityPageUrl, isListPageUrl } from 'Utils/urlUtils';

import {
	setActiveProductCard,
	updateActiveProductCardQueue,
} from 'Actions/productCard';
import { getElementFromUniqueId } from 'Reducers/productCard';

import {
	ANALYTICS_EVENTS,
	ANALYTICS_PROPERTIES,
	CATEGORY_SUBCATEGORY_PAGE,
} from 'Constants/analytics';
import {
	DESCRIPTORS,
	GLOBAL_CITY_CODE,
	PRODUCT_CARD_TYPE,
} from 'Constants/constants';
import { strings } from 'Constants/strings';

import RenderOneOf from '../renderOneOf';

import {
	CardContainer,
	Descriptor,
	Descriptors,
	L2Booster,
	PriceContainer,
	ProductCard,
	ProductContent,
	ProductImage,
	ProductRatingContainer,
	ProductTitle,
	Ratings,
	Reviews,
	StyledYourPickContainer,
} from './styles';
import type { Props } from './types';

const YourPickBadge = dynamic(
	() => import(/* webchunkName: 'YourPickBadgeSVG' */ 'Assets/yourPickBadge'),
);
const YourPickStar = dynamic(
	() => import(/* webchunkName: 'YourPickStarSVG' */ 'Assets/yourPickStar'),
);

const isDesktop = PlatformUtils.isDesktop();

const ProductCardComponent = ({
	id,
	cardData,
	cardIndex,
	isComparisonTable,
	isPinnedCard,
	isRecentlyViewed,
	priority = false,
	className = '',
	fullWidthCards,
	isTopPicks,
	verticalGapBetweenCard,
	isProductPage,
	isOnProductCardGrid,
	video,
	carousel = false,
	productCardRef,
	isProductCardActive = false,
	isCardInCarousel = false,
	parentIdentifier = 'UNIQUE_ID',
	updateActiveProductCard,
	updateProductCardQueue,
	highlightedCard = false,
	onCardVisible,
	sectionDataPayload: sectionDataPayloadFromProps,
	showUpdatedProductCard = false,
}: Props) => {
	const router = useRouter();
	const isOnScreen = useOnScreen({
		ref: productCardRef!,
		options: {
			threshold: 0.5,
		},
	});
	const { pathname, query } = router;
	const { lang: paramLang } = query as { lang: string };

	const [renderAllImages, setRenderAllImages] = useState(false);
	const {
		productCard,
		productCityCode,
		productCity,
		pageType,
		currentCityCode,
	} = useSelector(state => {
		const productCard = cardData ? cardData : getProductCard(state, id);
		const productCityCode = productCard?.cityCode;
		const productCity = getCity(state, productCityCode);
		const pageType = getCurrentPage(state);
		const currentCityCode = getCurrentCityCode(state);
		const currentPage = getCurrentPage(state);

		return {
			productCityCode,
			productCity,
			pageType,
			productCard,
			currentCityCode,
			currentPage,
		};
	});

	const productCardUniqueIdentifier = `${parentIdentifier}-${id}`;

	const handleIsActiveProductCard = (isActive: boolean) => {
		updateActiveProductCard?.(
			isActive ? productCardUniqueIdentifier : null,
		);
	};

	useEffect(() => {
		if (!isProductCardActive || renderAllImages) return;
		setRenderAllImages(true);
	}, [isProductCardActive, renderAllImages]);

	useEffect(() => {
		if (isDesktop || !productCardRef?.current) return;

		let observer: IntersectionObserver;
		let debouneTimeoutId: NodeJS.Timeout;
		const shouldAttachObserver = isCardInCarousel ? isOnScreen : true;

		if (shouldAttachObserver) {
			observer = new IntersectionObserver(
				entries => {
					if (entries[0].intersectionRatio === 1) {
						debouneTimeoutId = setTimeout(() => {
							handleIsActiveProductCard(
								entries[0].isIntersecting,
							);
						}, 200);
					} else {
						clearTimeout(debouneTimeoutId);
						updateProductCardQueue?.(productCardUniqueIdentifier);
						if (isProductCardActive) {
							handleIsActiveProductCard(false);
						}
					}
				},
				{
					threshold: 1,
				},
			);

			observer.observe(productCardRef.current);
		}

		return () => {
			observer?.disconnect();
		};
	}, [
		isOnScreen,
		isCardInCarousel,
		isProductCardActive,
		productCardRef,
		handleIsActiveProductCard,
		updateProductCardQueue,
		productCardUniqueIdentifier,
	]);

	useEffect(() => {
		if (productCard && id && isOnScreen) {
			onCardVisible?.({
				id,
				cardIndex: cardIndex!,
				cardData: productCard,
			});
		}
	}, [isOnScreen, productCard, id]);

	const handleClick = (e: any) => {
		e.stopPropagation();
		e.preventDefault();

		const { productURL, clickEvent } = productCard;

		clickEvent?.();
		sendAnalyticsEvent();

		const clickUrl =
			productURL ||
			getProductCardClickUrl({
				productCard,
				paramLang,
				paramCity:
					currentCityCode !== productCityCode &&
					currentCityCode !== GLOBAL_CITY_CODE
						? currentCityCode
						: null,
			});

		!isDesktop ? router.push(clickUrl) : window.open(clickUrl, '_blank');
	};

	const sendAnalyticsEvent = () => {
		const {
			id,
			name,
			primaryCollection,
			primaryCategory,
			primarySubCategory,
			primaryCity,
		} = productCard;
		const sectionDataPayload = sectionDataPayloadFromProps
			? sectionDataPayloadFromProps
			: isOnProductCardGrid
			? {
					[ANALYTICS_PROPERTIES.SECTION]:
						CATEGORY_SUBCATEGORY_PAGE.TOP_EXPERIENCES,
			  }
			: {};

		trackEvent({
			eventName: ANALYTICS_EVENTS.EXPERIENCE_CARD_CLICKED,
			[ANALYTICS_PROPERTIES.TGID]: id,
			[ANALYTICS_PROPERTIES.EXPERIENCE_NAME]: name,
			[ANALYTICS_PROPERTIES.COLLECTION_ID]: primaryCollection?.id,
			[ANALYTICS_PROPERTIES.COLLECTION_NAME]:
				primaryCollection?.name || primaryCollection?.displayName,
			[ANALYTICS_PROPERTIES.CATEGORY_ID]: primaryCategory?.id,
			[ANALYTICS_PROPERTIES.CATEGORY_NAME]:
				primaryCategory?.name || primaryCategory?.displayName,
			[ANALYTICS_PROPERTIES.SUB_CATEGORY_ID]: primarySubCategory?.id,
			[ANALYTICS_PROPERTIES.SUB_CATEGORY_NAME]:
				primarySubCategory?.name || primarySubCategory?.displayName,
			[ANALYTICS_PROPERTIES.POSITION]: cardIndex,
			[ANALYTICS_PROPERTIES.SECTION]:
				getHumanReadableStringFromSanitizedIdentifier(parentIdentifier),
			[ANALYTICS_PROPERTIES.RANKING]: cardIndex! + 1,
			[ANALYTICS_PROPERTIES.CITY]:
				productCity?.cityCode || primaryCity?.code,
			'Card Type': isComparisonTable
				? PRODUCT_CARD_TYPE.COMPARISON
				: highlightedCard
				? PRODUCT_CARD_TYPE.GOOGLE_TTD_PINNED
				: isPinnedCard
				? PRODUCT_CARD_TYPE.PINNED
				: isRecentlyViewed
				? PRODUCT_CARD_TYPE.RECENTLY_VIEWED
				: PRODUCT_CARD_TYPE.STANDARD,
			...sectionDataPayload,
		});
	};

	const {
		name,
		listingPrice,
		averageRating,
		primaryCollection,
		productURL,
		descriptors,
		ratingCount,
		media: { productImages },
		microBrandsHighlight,
		showRatings = true,
		cityDisplayName,
	} = productCard;
	const { l1Booster, l2Booster } = getProductBoosters({
		productCard,
		productCityDisplayName: cityDisplayName,
		pageType,
	});

	let productUrl =
		productURL ||
		getProductCardClickUrl({
			productCard,
			paramLang,
		});
	const hasInstantConfirmation =
		getDescriptorByType({
			descriptorType: DESCRIPTORS.INSTANT_CONFIRMATION,
			descriptors,
		}) || false;
	const hasFreeCancellationBooster =
		(showUpdatedProductCard &&
			getDescriptorByType({
				descriptorType: DESCRIPTORS.FREE_CANCELLATION,
				descriptors,
			})) ||
		false;

	const cashback = getProductCashback(productCard);
	const hasDescriptors = !!listingPrice && hasInstantConfirmation;
	const ratingsPresent = averageRating > 0 && ratingCount > 0 && showRatings;
	const hasSpecialOffer =
		!!listingPrice &&
		productHasSpecialOffer({
			microBrandsHighlight,
		});
	const isLttProduct = primaryCollection?.id === 167;

	return (
		<ProductCard
			ref={productCardRef}
			className={classNames('product-card-wrap', className)}
			highlighted={highlightedCard}
			onClick={handleClick}
			isComparisonTable={isComparisonTable}
			fullWidthCards={fullWidthCards}
			isTopPicks={isTopPicks}
			verticalGapBetweenCard={verticalGapBetweenCard}
			isProductPage={isProductPage}
			data-unique-id={productCardUniqueIdentifier}
			onMouseEnter={
				isDesktop ? () => handleIsActiveProductCard(true) : undefined
			}
			onMouseLeave={
				isDesktop ? () => handleIsActiveProductCard(false) : undefined
			}
		>
			<Conditional if={highlightedCard}>
				<StyledYourPickContainer>
					<YourPickBadge />
					<div className='your-pick-text'>
						<YourPickStar />
						<span className='block'>
							{strings.PRODUCT_CARD.YOUR_PICK}
						</span>
					</div>
				</StyledYourPickContainer>
			</Conditional>
			<CardContainer
				className='product-card'
				fullWidthCards={fullWidthCards}
				isComparisonTable={isComparisonTable}
			>
				<RenderOneOf
					positionalConditions={[
						Boolean(l1Booster),
						hasFreeCancellationBooster,
					]}
					noDefault
				>
					<L1Booster boosterText={l1Booster} />
					<L1Booster boosterText={strings.PPD_FREE_CANCEL} />
				</RenderOneOf>
				<Conditional if={hasSpecialOffer && isLttProduct}>
					<L1Booster boosterText={'🤑 ' + strings.SPECIAL_OFFER} />
				</Conditional>
				<ProductImage
					$fullWidthCards={fullWidthCards}
					$carousel={carousel}
					$isPinnedCard={isPinnedCard}
					isTopPicks={isTopPicks}
					isComparisonTable={isComparisonTable}
					className={'product-card-image'}
				>
					<ProductCardMediaCarousel
						isCardActive={isProductCardActive}
						shouldDisablePointerEvents={!fullWidthCards}
						images={
							renderAllImages
								? productImages
								: productImages.slice(0, 1)
						}
						video={video}
						width={327}
						height={204}
						id={id}
						priority={priority}
						sectionDataPayload={sectionDataPayloadFromProps}
					/>
				</ProductImage>
				<ProductContent
					hasDescriptors={hasDescriptors}
					isTopPicks={isTopPicks}
				>
					<Conditional if={l2Booster}>
						<L2Booster>
							<LSpan className='block l2-booster-label'>
								{l2Booster}
							</LSpan>
						</L2Booster>
					</Conditional>
					<Link
						prefetch={false}
						href={productUrl}
						passHref
						legacyBehavior
					>
						<ProductTitle
							onClick={handleClick}
							target='_blank'
							rel='noopener noreferrer'
							className='product-title block'
							as={'a'}
						>
							{name}
						</ProductTitle>
					</Link>

					<Conditional if={hasDescriptors && !isTopPicks}>
						<Descriptors>
							<Conditional if={hasInstantConfirmation}>
								<Descriptor key={'instant-confirmation'}>
									<InstantConfirmationSvg />
									<LSpan className='descriptor-text'>
										{strings.PPD_INSTANT_CONFIRMATION}
									</LSpan>
								</Descriptor>
							</Conditional>
						</Descriptors>
					</Conditional>
					<ProductRatingContainer className='product-review-wrapper'>
						<Ratings>
							<div className='product-rating'>
								<Conditional if={showRatings}>
									<StarSvg className='star-svg' />
								</Conditional>
								<LSpan className={'rating-count'}>
									{showRatings
										? averageRating.toFixed(1)
										: strings.CMN_NEW}
								</LSpan>
							</div>

							<Conditional if={ratingsPresent}>
								<Reviews
									className={'review-count'}
									aria-hidden='true'
								>
									({truncateNumber(ratingCount)})
								</Reviews>

								<span className='sr-only'>
									Rated {averageRating} out of 5.0 from{' '}
									{ratingCount} {strings.RATINGS.plural}.
								</span>
							</Conditional>
						</Ratings>
					</ProductRatingContainer>
					<PriceContainer>
						<PriceBlock
							listingPrice={listingPrice}
							showSavings={true}
							showBestDiscount
							cashback={cashback}
							addOfferMetaData={
								!(isListPageUrl(pathname) || isCityPageUrl)
							}
							newDiscountTag={showUpdatedProductCard}
						/>
					</PriceContainer>
				</ProductContent>
			</CardContainer>
		</ProductCard>
	);
};

const MemoizedProductCard = React.memo(
	ProductCardComponent,
	(prevProps, nextProps) => isEqual(prevProps, nextProps),
);

// This Wrapper re-renders every time `activeProductCard` changes, it saves ProudctCardComponent from re-rendering unnecessarily
const ProductCardWithRef = forwardRef<JSX.Element, Props>(function Product(
	props,
) {
	const productCardRef = useRef<HTMLDivElement>(null);

	const { state, dispatch } = useProductCardContext();

	const { activeProductCard: activeProductCardUniqueId } = state;
	const activeProductCard = getElementFromUniqueId(activeProductCardUniqueId);
	const isProductCardActive =
		!!activeProductCard && productCardRef.current === activeProductCard;

	const updateActiveProductCard = useCallback(
		(activeCardIdentifier: string | null) =>
			dispatch(setActiveProductCard(activeCardIdentifier)),
		[dispatch],
	);

	const updateProductCardQueue = useCallback(
		(productCardUniqueIdentifier: string | null) =>
			dispatch(updateActiveProductCardQueue(productCardUniqueIdentifier)),
		[dispatch],
	);

	return (
		<MemoizedProductCard
			productCardRef={productCardRef}
			isProductCardActive={isProductCardActive}
			updateActiveProductCard={updateActiveProductCard}
			updateProductCardQueue={updateProductCardQueue}
			{...props}
		/>
	);
});

export const ProductCardComponentV2 = forwardRef<JSX.Element, Props>(
	function ProductV2(props, ref) {
		const productData = useSelector(state =>
			getProductCardV2(state, props.id),
		);
		const {
			primaryCollection,
			primarySubCategory,
			primaryCategory,
			listingPrice,
			descriptors,
			displayName,
			ratings,
			urlSlug,
			primaryCity,
		} = productData;
		const productImages = productData?.medias.filter((mediaObj: any) => {
			const {
				type,
				url,
				metadata: { altText },
			} = mediaObj;
			return type === 'IMAGE' && { url, altText };
		});

		const cardData = {
			productURL: urlSlug,
			primaryCollection,
			primarySubCategory,
			primaryCategory,
			media: { productImages },
			listingPrice,
			name: displayName,
			descriptors,
			primaryCity,
			ratingCount: ratings?.count,
			averageRating: ratings?.value,
			showRatings: ratings?.count > 10,
			id: props.id,
		};
		return <ProductCardWithRef {...props} cardData={cardData} ref={ref} />;
	},
);

export default ProductCardWithRef;
