import React from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

import Image from '../image';
import RenderOneOf from '../renderOneOf';

function getInitials(name: string) {
	const [firstName, lastName] = name.split(' ');
	return firstName && lastName
		? `${firstName.charAt(0)}${lastName.charAt(0)}`
		: firstName.charAt(0);
}

const StyledAvatarContainer = styled.div<{
	width: number;
	height: number;
	bgColor?: string;
	fontColor?: string;
	fontToken?: string;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	${({ fontToken }) =>
		getFontDetailsByLabel(fontToken || TYPE_LABELS.HEADING_LARGE)}
	border-radius: 50%;
	margin-right: 0.625rem;
	width: ${({ width }) => width}px;
	height: ${({ height }) => height}px;
	overflow: hidden;
	color: ${({ color }) => color || colors.WHITE};
	background-color: ${({ bgColor }) => bgColor || colors.PURPS_DARK};
`;

const TextAvatar = ({ name }: { name: string }) => {
	return (
		<div css={{ display: 'flex' }} role='img' aria-label={name}>
			{name ? getInitials(name) : null}
		</div>
	);
};

type AvatarProps = {
	url?: string;
	color?: string;
	name: string;
	width?: number;
	height?: number;
	bgColor?: string;
	fontColor?: string;
	fontToken?: string;
	style: any;
};

const Avatar = ({
	url,
	name,
	bgColor,
	fontColor,
	fontToken,
	width = 40,
	height = 40,
	style,
}: AvatarProps) => {
	return (
		<StyledAvatarContainer
			width={width}
			height={height}
			bgColor={bgColor}
			fontColor={fontColor}
			fontToken={fontToken}
			style={style}
		>
			<RenderOneOf positionalConditions={[Boolean(url), !url]}>
				<Image
					src={url || ''}
					width={width}
					height={height}
					alt={name}
					layout={'responsive'}
				/>
				<TextAvatar name={name} />
			</RenderOneOf>
		</StyledAvatarContainer>
	);
};

export default Avatar;
