import * as React from 'react';

export const StarSvg = ({
	stroke = '#e5006e',
	fill = '#e5006e',
	...props
}: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12'
			height='12'
			fill='none'
			viewBox='0 0 12 12'
			role='img'
			aria-hidden='true'
			{...props}
		>
			<path
				fill={fill}
				stroke={stroke}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M6.125 1.275l1.545 3.13 3.455.505-2.5 2.435.59 3.44-3.09-1.625-3.09 1.625.59-3.44-2.5-2.435 3.455-.505 1.545-3.13z'
			></path>
		</svg>
	);
};

export const SearchIconMobileSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 17.69 17.69' {...props}>
			<g transform='translate(-2.3 -2.3)'>
				<circle
					id='Ellipse_4'
					cx={7.097}
					cy={7.097}
					r={7.097}
					className='cls-1'
					strokeLinecap='round'
					fill='none'
					strokeWidth={1}
					stroke='#535353'
					transform='translate(3 3)'
				/>
				<path
					id='Line_12'
					d='M3.93 3.93L0 0'
					className='cls-1'
					strokeLinecap='round'
					fill='none'
					strokeWidth={1}
					stroke='#535353'
					transform='translate(15.07 15.07)'
				/>
			</g>
		</svg>
	);
};

export const HelpIconMobileSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			fill='none'
			viewBox='0 0 20 20'
			{...props}
		>
			<path
				fill='#444'
				fillRule='evenodd'
				d='M10 2.292a7.708 7.708 0 100 15.416 7.708 7.708 0 000-15.416zM1.042 10a8.958 8.958 0 1117.916 0 8.958 8.958 0 01-17.916 0z'
				clipRule='evenodd'
			></path>
			<path
				stroke='#444'
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M7.5 7.504a2.5 2.5 0 014.858.833c0 1.667-2.5 2.5-2.5 2.5'
			></path>
			<mask id='path-3-inside-1' fill='#fff'>
				<ellipse cx='10' cy='14.167' rx='0.833' ry='0.833'></ellipse>
			</mask>
			<ellipse
				cx='10'
				cy='14.167'
				fill='#444'
				rx='0.833'
				ry='0.833'
			></ellipse>
			<path
				fill='#444'
				d='M9.833 14.167c0-.092.075-.167.167-.167v2c1.012 0 1.833-.82 1.833-1.833h-2zM10 14c.092 0 .166.075.166.167h-2C8.166 15.179 8.987 16 10 16v-2zm.166.167a.167.167 0 01-.166.166v-2c-1.013 0-1.834.821-1.834 1.834h2zm-.166.166a.167.167 0 01-.167-.166h2c0-1.013-.82-1.834-1.833-1.834v2z'
				mask='url(#path-3-inside-1)'
			></path>
		</svg>
	);
};

export const ChevronRightSvgNew = ({
	stroke = '#444',
	...props
}: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12'
			height='12'
			fill='none'
			viewBox='0 0 12 12'
			{...props}
		>
			<path
				stroke={stroke}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M3.5 11l5-5-5-5'
			></path>
		</svg>
	);
};

export const CloseSVGIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M13.3334 2.66663L2.66669 13.3333'
			stroke='#444444'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M2.66669 2.66663L13.3334 13.3333'
			stroke='#444444'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export const ChevronRightCitiesPage = ({
	strokeColor = '#0F43BD',
	...props
}: React.SVGProps<SVGSVGElement> & { strokeColor: string }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='16'
		height='16'
		fill='none'
		viewBox='0 0 16 16'
		{...props}
	>
		<path
			stroke={strokeColor}
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M4.667 14.667L11.333 8 4.667 1.333'
		></path>
	</svg>
);

export const DividerDotSvg = (props: any) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='3'
		height='3'
		fill='none'
		viewBox='0 0 3 3'
		{...props}
	>
		<path
			fill='#888'
			d='M.152 2.804H2.06c.036 0 .06-.024.06-.072V.788c0-.048-.024-.084-.06-.084H.152C.116.704.08.74.08.788v1.944c0 .048.024.072.072.072z'
		></path>
	</svg>
);

export const ChevronRight = () => {
	return (
		<svg
			width='12'
			height='12'
			viewBox='0 0 12 12'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M3.5 11L8.5 6L3.5 1'
				stroke='#444444'
				stroke-width='1.2'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</svg>
	);
};
