import * as React from 'react';

export const ShieldSvg = (props: any) => {
	return (
		<svg
			width={24}
			height={24}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M12 22C12 22 20 18 20 12V5L12 2L4 5V12C4 18 12 22 12 22Z'
				stroke='#1A4D57'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export const CrossSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width={24}
			height={24}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<rect width={24} height={24} rx={2} fill='black' />
			<path
				d='M17.3334 6.66663L6.66669 17.3333'
				stroke='white'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M6.66669 6.66663L17.3334 17.3333'
				stroke='white'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export const InfoIconSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 14 14' {...props}>
			<path
				fill='#545454'
				fillRule='nonzero'
				d='M7 0C3.137 0 0 3.137 0 7s3.137 7 7 7 7-3.137 7-7-3.137-7-7-7zm0 .56c3.56 0 6.44 2.88 6.44 6.44 0 3.56-2.88 6.44-6.44 6.44C3.44 13.44.56 10.56.56 7 .56 3.44 3.44.56 7 .56zm-.341 3.054c-.06 0-.096.036-.096.096v.735c0 .06.036.105.096.105h.682c.06 0 .096-.045.096-.105V3.71c0-.06-.036-.096-.096-.096H6.66zM6.64 5.635a.101.101 0 0 0-.061.096v4.611c0 .06.036.097.096.097h.648c.06 0 .096-.036.096-.097v-4.61c0-.06-.036-.097-.096-.097h-.648c-.015 0-.023-.004-.035 0z'
			/>
		</svg>
	);
};

export const DropdownArrowSolidSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 11 6' {...props}>
			<g
				id='Final'
				stroke='none'
				strokeWidth={1}
				fill='none'
				fillRule='evenodd'
			>
				<g
					id='ProductDetailsPage_MinorChanges-Copy'
					transform='translate(-1204.000000, -357.000000)'
					fill='#545454'
				>
					<g
						id='Group-20'
						transform='translate(948.000000, 138.000000)'
					>
						<g id='Side-Bar'>
							<g id='Module-1'>
								<g
									id='Group-2'
									transform='translate(8.015625, 23.023438)'
								>
									<g
										id='Group-4'
										transform='translate(10.984375, 173.976562)'
									>
										<g
											id='date'
											transform='translate(0.984375, 0.376562)'
										>
											<polygon
												id='Shape'
												points='236 22 241.4 27.4000001 246.8 22'
											/>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export const LeftArrowSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12'
			height='12'
			fill='none'
			viewBox='0 0 12 12'
			{...props}
		>
			<path
				stroke='#444'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.2'
				d='M8.5 11l-5-5 5-5'
			></path>
		</svg>
	);
};

export const RightArrowSvgThicker = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width='16'
		height='16'
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<g clipPath='url(#clipRightArrow)'>
			<path
				d='M4.66666 14.6666L11.3333 7.99998L4.66666 1.33331'
				stroke='#444444'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</g>
		<defs>
			<clipPath id='clipRightArrow'>
				<rect width='16' height='16' fill='white' />
			</clipPath>
		</defs>
	</svg>
);

export const RightArrowSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12'
			height='12'
			fill='none'
			viewBox='0 0 12 12'
			{...props}
		>
			<path
				stroke='#444'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.2'
				d='M3.5 1l5 5-5 5'
			></path>
		</svg>
	);
};

export const SeatIconSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 12 16' {...props}>
			<g fill='none' fillRule='nonzero'>
				<path
					fill='#B4D7EE'
					d='M11.995 7.695c.053.535-.336.972-.864.972H1.535c-.527 0-.916-.438-.864-.972L1.2 2.307c.053-.535.527-.974 1.055-.974h8.156c.528 0 1.003.438 1.055.974l.53 5.388'
				/>
				<g fill='#3E4347'>
					<path d='M.215 7.457h1.477v1.981H.215zM10.124 7.457h1.477v1.981h-1.477zM1.63 12.483h1.477v2.476H1.631zM8.706 12.473h1.477v2.497H8.706z' />
				</g>
				<path
					fill='#428BC1'
					d='M8.234 6.147c-.076.652-.557 1.186-1.07 1.186H4.836c-.513 0-.994-.534-1.07-1.186L3.343 2.52c-.075-.652.282-1.187.794-1.187h3.725c.513 0 .87.535.795 1.187l-.423 3.627'
				/>
				<g fill='#94989B'>
					<path d='M3.855 14.472a.367.367 0 0 0-.324-.235H1.557a.366.366 0 0 0-.325.235l-.338 1.016c-.042.13.033.235.168.235h2.961c.136 0 .212-.105.17-.235l-.339-1.016M11.271 15.49l-.338-1.017a.368.368 0 0 0-.324-.235H8.634a.367.367 0 0 0-.325.235l-.337 1.016c-.043.13.033.235.17.235h2.96c.135 0 .212-.105.17-.235' />
				</g>
				<g fill='#29ABE2'>
					<path d='M9.415 1.487c0 .545-.42.992-.935.992H3.336c-.514 0-.936-.446-.936-.992V.992c0-.545.422-.99.936-.99H8.48c.515 0 .935.446.935.99v.495M1.85 7.183l-.074-.509c-.04-.269-.27-.489-.512-.489h-.66c-.242 0-.472.22-.512.489l-.076.509c-.04.269.127.488.368.488h1.1c.241 0 .407-.22.367-.488M11.759 7.183l-.075-.509c-.04-.269-.27-.489-.513-.489h-.66c-.24 0-.47.22-.511.489l-.076.509c-.04.269.126.488.368.488h1.1c.241 0 .407-.22.367-.488M10.703 8.871h-9.59c-.753 0-1.241.748-1.083 1.66l.11.643c.159.913.904 1.66 1.658 1.66h8.22c.753 0 1.499-.747 1.657-1.66l.11-.642c.159-.913-.329-1.66-1.082-1.66' />
				</g>
			</g>
		</svg>
	);
};

export const ChevronDownSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg width={16} height={16} viewBox='0 0 16 16' fill='none' {...props}>
			<path
				d='M1.33317 4.66699L7.99984 11.3337L14.6665 4.66699'
				stroke='#444444'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export const ChevronUpSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg width={16} height={8} viewBox='0 0 16 8' fill='none' {...props}>
			<path
				d='M14.6666 7.33337L7.99998 0.666707L1.33331 7.33337'
				stroke='#444444'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export const PlusIconSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg width={15} height={15} viewBox='0 0 24 24' fill='none' {...props}>
			<path
				d='M12 19L12 5'
				stroke={props.stroke ?? '#444444'}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M19 12L5 12'
				stroke={props.stroke ?? '#444444'}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export const GreenTickSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 26 26' {...props}>
			<path
				d='m.3,14c-0.2-0.2-0.3-0.5-0.3-0.7s0.1-0.5 0.3-0.7l1.4-1.4c0.4-0.4 1-0.4 1.4,0l.1,.1 5.5,5.9c0.2,0.2 0.5,0.2 0.7,0l13.4-13.9h0.1v-8.88178e-16c0.4-0.4 1-0.4 1.4,0l1.4,1.4c0.4,0.4 0.4,1 0,1.4l0,0-16,16.6c-0.2,0.2-0.4,0.3-0.7,0.3-0.3,0-0.5-0.1-0.7-0.3l-7.8-8.4-.2-.3z'
				fill='#32c75e'
			/>
		</svg>
	);
};

export const TickSvg = (props: React.SVGProps<SVGSVGElement>) => (
	<svg viewBox='0 0 17 13' fill='none' {...props}>
		<path
			d='M16 1L5.6875 11.8333L1 6.90909'
			stroke='#8000ff'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export const Chevron = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width='16'
		height='11'
		viewBox='0 0 20 11'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path d='M1 1L10.3333 9L19 1' stroke='#444' strokeWidth='2' />
	</svg>
);

export const ChevronWithBoundingBox = (
	props: React.SVGProps<SVGSVGElement>,
) => (
	<svg
		width='16'
		height='16'
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M1.33366 4.6665L8.00032 11.3332L14.667 4.6665'
			stroke='#666666'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
