/* eslint-disable */
import React from 'react';
import Script from 'next/script';

const initializeGTM = (key: any, auth: any, preview: any) =>
	auth ? (
		<Script
			id='gtm-script'
			dangerouslySetInnerHTML={{
				__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?gtm_auth=${auth}&gtm_preview=${preview}&gtm_cookies_win=x&id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${key}');`,
			}}
		/>
	) : (
		<Script
			id='gtm-script-auth'
			dangerouslySetInnerHTML={{
				__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${key}');`,
			}}
		/>
	);

const initializeNoScriptGTM = (
	key: string,
	auth: string | null,
	preview: string | null,
) =>
	auth ? (
		<noscript>
			<iframe
				title='authenticated tracking Script'
				src={`https://www.googletagmanager.com/ns.html?id=${key}&gtm_auth=${auth}&gtm_preview=${preview}&gtm_cookies_win=x`}
				height='0'
				width='0'
				style={{ display: 'none', visibility: 'hidden' }}
			/>
		</noscript>
	) : (
		<noscript>
			<iframe
				title='non-authenticated tracking Script'
				src={`https://www.googletagmanager.com/ns.html?id=${key}`}
				height='0'
				width='0'
				style={{ display: 'none', visibility: 'hidden' }}
			/>
		</noscript>
	);

const initializeAmplitude = (key: any) => (
	<Script
		id='amplitude-script'
		type='text/javascript'
		dangerouslySetInnerHTML={{
			__html: ` (function(e,t){var n=e.amplitude||{_q:[],_iq:{}};var r=t.createElement("script")
    ;r.type="text/javascript"
    ;r.integrity="sha384-a+mq7tiLwde/00Oc7avFHLn/ttGfdAq1rtZc7u97SEzIiyYoT2IsOKWCkAThwdEu"
    ;r.crossOrigin="anonymous";r.async=true
    ;r.src="https://cdn.amplitude.com/libs/amplitude-5.3.0-min.gz.js"
    ;r.onload=function(){if(!e.amplitude.runQueuedFunctions){}}
    ;var i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)
    ;function s(e,t){e.prototype[t]=function(){
    this._q.push([t].concat(Array.prototype.slice.call(arguments,0)));return this}}
    var o=function(){this._q=[];return this}
    ;var a=["add","append","clearAll","prepend","set","setOnce","unset"]
    ;for(var u=0;u<a.length;u++){s(o,a[u])}n.Identify=o;var c=function(){this._q=[]
    ;return this}
    ;var l=["setProductId","setQuantity","setPrice","setRevenueType","setEventProperties"]
    ;for(var p=0;p<l.length;p++){s(c,l[p])}n.Revenue=c
    ;var d=["init","logEvent","logRevenue","setUserId","setUserProperties","setOptOut","setVersionName","setDomain","setDeviceId","setGlobalUserProperties","identify","clearUserProperties","setGroup","logRevenueV2","regenerateDeviceId","groupIdentify","onInit","logEventWithTimestamp","logEventWithGroups","setSessionId","resetSessionId"]
    ;function v(e){function t(t){e[t]=function(){
    e._q.push([t].concat(Array.prototype.slice.call(arguments,0)))}}
    for(var n=0;n<d.length;n++){t(d[n])}}v(n);n.getInstance=function(e){
    e=(!e||e.length===0?"$default_instance":e).toLowerCase()
    ;if(!n._iq.hasOwnProperty(e)){n._iq[e]={_q:[]};v(n._iq[e])}return n._iq[e]}
    ;e.amplitude=n})(window,document);
    amplitude.getInstance().init('${key}', null, {
    	includeGclid : true,
		includeReferrer: true,
		includeUtm : true,
		saveParamsReferrerOncePerSession: false,
		unsetParamsReferrerOnNewSession: true,
    })`,
		}}
	/>
);

const initializeClarity = (key: string) => {
	import('clarity-js').then(({ clarity }) => {
		clarity.start({
			projectId: key,
			upload: 'https://m.clarity.ms/collect',
			track: true,
			content: true,
		});
	});
};

export {
	initializeGTM,
	initializeNoScriptGTM,
	initializeAmplitude,
	initializeClarity,
};
