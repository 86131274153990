import type { ComponentPropsWithoutRef, ComponentPropsWithRef } from 'react';
import React from 'react';

import { Text } from '@headout/eevee';

interface Props extends ComponentPropsWithoutRef<'p'> {
	id?: string;
	className?: string;
	onClick?: (...args: any[]) => any;
	children?: React.ReactNode;
	testId?: string;
}

/**
 * Localized paragraph component
 * Disable google-translate by default
 * @deprecated Use `NewLP` instead
 */
const LP = ({ id, className, onClick, children, testId }: Props) => (
	<p
		{...(testId && { 'data-testid': testId })}
		id={id}
		className={`notranslate ${className || ''}`}
		onClick={onClick}
	>
		{children}
	</p>
);

export default LP;

export const NewLP = ({
	className,
	testId,
	...props
}: ComponentPropsWithRef<typeof Text> & {
	testId?: string;
}) => (
	<Text
		className={`notranslate ${className || ''}`}
		{...props}
		{...(testId && { 'data-testid': testId })}
	/>
);
