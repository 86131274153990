const PortalLoaderStroked = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			className='ripple-svg'
			xmlns='http://www.w3.org/2000/svg'
			width='83'
			height='97'
			viewBox='0 0 83 97'
			fill='none'
			{...props}
		>
			<path
				d='M40.4588 96C56.0665 96 66.6387 87.9332 71.4 82.2794C72.6065 80.8468 74.3243 79.8688 76.1973 79.8688C79.2144 79.8688 81.6602 77.423 81.6602 74.4059L81.6602 23.9401C81.6602 20.7154 79.0238 18.1013 75.7991 18.1013C74.1253 18.1013 72.578 17.3706 71.4618 16.1233C67.0864 11.2337 56.6619 1.00001 40.4588 1C26.9756 1 15.9728 9.9634 10.7768 15.9687C9.65332 17.2671 8.05936 18.1013 6.34235 18.1013C3.3862 18.1013 0.989768 20.4977 0.989768 23.4539L0.989763 74.7887C0.989763 77.5943 3.26418 79.8687 6.06983 79.8687C7.92384 79.8687 9.60464 80.8967 10.7317 82.3688C15.0569 88.0178 24.9331 96 40.4588 96Z'
				stroke='#EAD6FF'
				strokeWidth='0.811966'
				vector-effect='non-scaling-stroke'
			/>
		</svg>
	);
};

export default PortalLoaderStroked;
