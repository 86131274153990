import type { SVGProps } from 'react';

export const Arrow = (
	props: JSX.IntrinsicAttributes &
		SVGProps<SVGSVGElement> & { pathStroke?: string },
) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='16'
		height='16'
		fill='none'
		viewBox='0 0 16 16'
		{...props}
	>
		<path
			stroke={props?.pathStroke ?? '#888'}
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M2 8h12M8 2l6 6-6 6'
		/>
	</svg>
);
